import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { StyledCreatable, StyledLabel } from "../../UI/Elements";
import { FlexContainer } from "../../UI/Layout";
import { get, uniqBy } from "lodash";

import ValidationError from "../../UI/Elements/ValidationError";

import { getCountries,getBussinessCategory } from "../../../Containers/Auth/AuthAction";
import { getCurrency } from "../../../Containers/Manage/ManageAction";
import { getProductCategory } from "../../../Containers/Products/ProductsAction";

class SearchSelect extends Component {
  componentDidMount() {
    const { selectType, getProductCategory, getCurrency,getBussinessCategory } = this.props;

    if (selectType === "categoryName") {
      getProductCategory();
    }
    if (selectType === "country" || "currency" || "dialCode") {
      getCountries();
    }

    if (selectType === "currencyName") {
      getCurrency();
    }
    if (selectType === "bussinessName") {
      getBussinessCategory();
    }
  }
  handleChange = (
    option,
    field,
    setFieldValue,
    fillAnother,
    setFieldTouched
  ) => {
    if (this.props.selectType === "product") {
     
      this.props.changeCallback(option);
    
      // setTimeout(() => {
      //     setFieldValue(fillAnother[0], option.category)
      //     setFieldValue(fillAnother[1], option.baseCost)
      //     setFieldValue(fillAnother[2], option.currency)
      //     setFieldValue(fillAnother[3], option.subCategory)
      // }, 1)
      // setFieldTouched(fillAnother[0], true)
    }
    if (this.props.isMulti) {
      const arr = [];
      option.map((item) => {
        arr.push(item.value);
      });
      setFieldValue(field.name, arr);
    } else {
      setFieldValue(field.name, option.value);
      if (Array.isArray(fillAnother)) {
        setFieldValue(fillAnother[0], option.latitude);
        setFieldValue(fillAnother[1], option.longitude);
      } else {
        setFieldValue(fillAnother, option.countryCurrencyCode);
      }
    }
  };

  handleBlur = () => {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.name, true);
  };
  handleCreate = (option) => {};

  setDefaultValue = (options) => {
    const { defaultValue } = this.props;
    if (defaultValue) {
      if (Array.isArray(defaultValue)) {
     
        return defaultValue;
      } else {
    
        if (!defaultValue.hasOwnProperty("label")) {
          const newDefaultValue =
            options && options.find((l) => l.value === defaultValue.value);
          return newDefaultValue;
        } else {
          
          return [defaultValue];
        }
      }
    } else {
      return [];
    }
  };
  render() {
   
    const {
      isColumnWithoutNoCreate,
      selectType,
      productsCategory,
      fetchingCountries,
      defaultValue,
      placeholder,
      label,
      isColumn,
      isRequired,
      countries,
      isDisabled,
      fillAnother,
      filterOption,
      notLinked,
      noLabel,
      menuPlacement,
      margintop,
      inlineLabel,
      width,
      isShadow,
      Left,
      currencies,
      country_name,
      fetchingProductsCategory,
      bussinessCategory,
      form: { touched, errors, setFieldValue, setFieldTouched },
      field,
      ...rest
    } = this.props;
    let options = null;

    if (selectType === "categoryName") {
      options = productsCategory.map((item, i) => ({
        label: item.categoryName,
        value: item.categoryId,
        color: "#FF8B00",
      }));
    }

    if (selectType === "dialCode") {
      options = countries.map((item, i) => ({
        label: `+${item.country_dial_code}`,
        value: `+${item.country_dial_code}`,
      }));
      // options.filter((item, i) => options.indexOf())
      options = uniqBy(options, "value");
      // const customOption = ({ label, value }) => <h3>{`${label}-----${value}`}</h3>
    }
    if (selectType === "currencyName") {
      options = currencies.map((item, i) => ({
        value: item.currencyId,
        label: item.currencyName, 
      }));
     
      // const customOption = ({ label, value }) => <h3>{`${label}-----${value}`}</h3>
    }
    if (selectType === "country_name") {
      options = countries.map((item, i) => ({
        value: item.country_id,
        label: item.country_name, 
      }));
     
      // const customOption = ({ label, value }) => <h3>{`${label}-----${value}`}</h3>
    }
    if (selectType === "bussinessName") {
      options = bussinessCategory.map((item, i) => ({
        value: item.businessCategoryId,
        label: item.businessCategoryName, 
      }));
     
      // const customOption = ({ label, value }) => <h3>{`${label}-----${value}`}</h3>
    }
    if (isColumnWithoutNoCreate) {
      // The searchselect without create component will work on calling ( isColumnWithoutNoCreate)
      return (
        <>
          {!noLabel && (
            <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
          )}
          <StyledCreatable
            classNamePrefix="sales"
            label={placeholder}
            formatCreateLabel={() => undefined}
            isRequired={isRequired}
            placeholder={placeholder}
            options={options}
            menuPlacement={menuPlacement}
            width={width}
            isShadow={isShadow}
            Left={Left}
            margintop={margintop}
            name={field.name}
            isMulti={this.props.isMulti || false}
            {...field}
            {...rest}
            onChange={(option) =>
              this.handleChange(
                option,
                field,
                setFieldValue,
                fillAnother,
                setFieldTouched
              )
            }
            isCreatable={false}
            onCreateOption={this.handleCreate}
            defaultValue={this.setDefaultValue(options)}
            isDisabled={isDisabled}
            // isLoading={}
            // defaultValue={defaultValue ? [{
            //     value: defaultValue.value,
            //     label: options.find(option => {
            //        
            //         if (option.value === defaultValue.value) {
            //            
            //             return option.label
            //         }else{
            //             return 'asdads'
            //         }

            //     })
            // }] : ''}
            // defaultValue={defaultValue ? Array.isArray(defaultValue) && defaultValue.find(option => option.value === field.value) : ''}
            value={
              options
                ? options.find((option) => option.value === field.value)
                : ""
            }
            onBlur={() => setFieldTouched(field.name, true)}
          />

          {get(touched, field.name) && get(errors, field.name) && (
            <ValidationError>{get(errors, field.name)}</ValidationError>
          )}
        </>
      );
    }
    return (
      <>
        <FlexContainer>
          <FlexContainer alignItems="center" flexWrap={inlineLabel && "nowrap"}>
            {!noLabel && (
              <StyledLabel style={{ flexBasis: "20%" }}>{label}</StyledLabel>
            )}
            <StyledCreatable
              classNamePrefix="sales"
              label={placeholder}
              isRequired={isRequired}
              menuPlacement={menuPlacement} // menuPlacement={"top"}
              placeholder={placeholder}
              options={options}
              isShadow={isShadow}
              width={width}
              Left={Left}
              margintop={margintop}
              name={field.name}
              isMulti={this.props.isMulti || false}
              {...field}
              {...rest}
              onChange={(option) =>
                this.handleChange(
                  option,
                  field,
                  setFieldValue,
                  fillAnother,
                  setFieldTouched
                )
              }
              isCreatable={false}
              onCreateOption={this.handleCreate}
              defaultValue={this.setDefaultValue(options)}
              isDisabled={isDisabled}
              // isLoading={}
              // defaultValue={defaultValue ? [{
              //     value: defaultValue.value,
              //     label: options.find(option => {
              //      
              //         if (option.value === defaultValue.value) {
              //       
              //             return option.label
              //         }else{
              //             return 'asdads'
              //         }

              //     })
              // }] : ''}
              // defaultValue={defaultValue ? Array.isArray(defaultValue) && defaultValue.find(option => option.value === field.value) : ''}
              value={
                options
                  ? options.find((option) => option.value === field.value)
                  : ""
              }
              onBlur={() => setFieldTouched(field.name, true)}
            />
          </FlexContainer>
        </FlexContainer>
        {/* <AddUserModal addUserModal={this.props.addUserModal} handleUserModal={this.props.handleUserModal}/> */}

        {get(touched, field.name) && get(errors, field.name) && (
          <ValidationError>{get(errors, field.name)}</ValidationError>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, products, manage }) => ({
  userId: auth.userDetails.userId,
  countries: auth.countries,
  fetchingCountries: auth.fetchingCountries,
  productsCategory: products.productsCategory,
  fetchingProductsCategory: products.fetchingProductsCategory,
  currencies: manage.currencies,
  countries:auth.countries,
  bussinessCategory:auth.bussinessCategory,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getProductCategory,
      getCurrency,
      getCountries,
      getBussinessCategory
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(SearchSelect);
