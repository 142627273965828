import React from "react";
import { ViewEditCard } from "../../Components/UI/Elements";
import ProductsProfileView from "./ProductsProfileView";

function ProductsProfileCard(props) {
  return (
    <div style={{width:"95%",padding:"0 0 0 1em"}}>
      <ViewEditCard >
        {({ viewType }, toggleViewType) =>
          viewType === "view" ? (
            <ProductsProfileView
              product={props.product}
              handleProductClick={props.handleProductClick}
              toggleViewType={toggleViewType}
              current={props.currentProductsOpen}
              //   subscriptionType={props.subscriptionType}
            />
          ) : null
        }
      </ViewEditCard>
    </div>
  );
}

export default ProductsProfileCard;
