import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RollbackOutlined } from "@ant-design/icons";
import { Formik, Form, FastField } from "formik";
import { InputComponent } from "../../Components/Forms/Formik/InputComponent";
import { StyledLabel } from "../../Components/UI/Elements";
import { FlexContainer, MainWrapper } from "../../Components/UI/Layout";
import { addTrackOrder } from "../Customer/CustomerAction";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import styled from "styled-components";
import SHLogo from "../../Assets/Images/logo-shopper.PNG";
import "./ShopprHome.scss";
function TrackOrder(props) {
  let history = useHistory();
  const shopName = props.shopName.name;
  const str = shopName && shopName.replace(/ +/g, "");

  function handleCallBack(data, orderId) {
    history.push(`/orderTrackdetails/${orderId}`);
  }
  return (
    <>
      <Formik
        initialValues={{
          orderId: "",
        }}
        onSubmit={(values, { resetForm }) => {
          props.addTrackOrder(
            {
              ...values,
            },
            handleCallBack
          );
          resetForm();
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          ...rest
        }) => (
          <Form>
            <FlexContainer justifyContent="center">
              <MainWrapper width="65%">
               <img src={SHLogo} alt="img" className="shopprLogoPng"/>
                <h2 class="flex justify-center">Track Order</h2>
                <hr />
                <div style={{ display: "flex", justifyContent: "center",height:"60vh",alignItems:"center" }}>
                  <InputContainer>
                    <div style={{ width: "100%" }}>
                      <FastField
                        component={InputComponent}
                        name="orderId"
                        label="Order Id"
                        isColumn
                      />
                    </div>
                  </InputContainer>
                  <p style={{ marginRight: "10px" }}></p>
                  <ButtonContainer>
                    <div style={{ marginTop: "20px", marginRight: "10px" }}>
                      <Button
                        className="adhBtN"
                        htmlType="submit"
                        loading={props.trackingOrder}
                      >
                      <label class="text-white font-bold">Submit</label>  
                      </Button>
                    </div>
                  </ButtonContainer>
                </div>
              </MainWrapper>
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = ({ customer }) => ({
  trackingOrder: customer.trackingOrder,
  shopName: customer.shopName,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addTrackOrder,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(TrackOrder);
const ButtonContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content:flex-end @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;
