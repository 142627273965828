import React, { Component } from "react";
import CustomerContentHeaderActionLeft from "./CustomerContentHeaderActionLeft";
import CustomerContentHeaderActionRight from "./CustomerContentHeaderActionRight";
import { CustomerHeader } from "../../../Components/Utils";

class CustomerContentHeader extends Component {
    render() {

        return (
            <div>
                <CustomerHeader

                    flexdirection={"column"}
                    leftComponent={
                        <CustomerContentHeaderActionLeft
                            currentData={this.props.currentData}
                            handleClear={this.props.handleclear}
                            setCurrentData={this.props.setCurrentData}
                            visbleNewUI={this.props.visbleNewUI}
                            setVisibleNewUI={this.props.setVisibleNewUI}  
                        />
                    }
                    rightComponent={
                        <CustomerContentHeaderActionRight

                        />
                    }

                />
            </div>
        );
    }
}

export default CustomerContentHeader;
