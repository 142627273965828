import styled from "styled-components";
const AuthContainer = styled.div`
  // width: 50%;
  width:${(props) => props.width || "50%"}
  min-height: 0vh;
  margin-top:3em;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  @media only screen and (max-width: 600px) {
    min-height: 0vh;
   width:100%;
   margin-top:0;
  
  
  }
`;

export default AuthContainer;
