import React, { lazy, Suspense, Component } from "react";
import { BundleLoader } from "../../Components/Placeholder";
import { StyledModal } from "../../Components/UI/Antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageForm from "./ManageForm";

class SetDeliveryChargeModal extends Component {
    render(){
        return (
            <>
            <StyledModal 
title="Set Delivery Charge"
width="55%"
visible={this.props.setDeliveryCharge}
destroyOnClose
maskClosable={false}
maskStyle={{backgroundColor:"rgba(1, 30, 71,0.7)"}}
style={{top:40}}
onCancel={()=>this.props.handleDeliveryChargeModal(false)}
footer={null}
            >
                <Suspense fallback={<BundleLoader/>}>
                    <ManageForm/>
                </Suspense>
                </StyledModal>
            </>
        );
    }
}
const mapStateToProps=({})=>({});
const mapDispatchToProps=(dispatch)=>
bindActionCreators ({},dispatch);
export default connect (mapStateToProps,mapDispatchToProps)(SetDeliveryChargeModal);