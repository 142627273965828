import React from "react";
import Button from "antd/lib/button";
import { FlexContainer } from "../../../Components/UI/Layout";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import cart1 from "../../../Assets/Images/cart1.png";
import { bindActionCreators } from "redux";

class CustomerContentActionRight extends React.Component {
  render() {
    const cartData = this.props.productInfo.storeCart;
    const cartItemData = cartData && cartData.cartItems;
    const cartItemCount = cartData && cartData.itemCount;
    const cartSummaryData = cartItemData && cartItemData.cartSummary;
    const cartItems = cartSummaryData && cartSummaryData.itemCount;

    const shopName = this.props.shopName.name;
    const str = shopName && shopName.replace(/ +/g, "");

    return (
      <>
        <div class="flex flex-row items-center max-sm:mt-4 ">
        <div >
            <Link to={`/${str}/track`}>
            <Button
className="adHBtn">
                          <label class=" text-white font-bold">
                Track Order
                </label>
              </Button>
            </Link>
          </div>

          {cartItemCount === 0 ? (
            <div className="cart-icon">
              <img src={cart1} alt="cart" />
              <p>{cartItemCount}</p>
            </div>
          ) : (
            <Link to={`/${str}/customercart`}>
              <div className="cart-icon ml-2">
                <img src={cart1} alt="cart" />
                <p>{cartItemCount}</p>
              </div>
            </Link>
          )}
         
          {/* <Button
            type="primary"
            ghost
            // onClick={() => handleSuppliersModal(true)}
            style={{backgroundColor:"white", color: "black", borderColor:"black", borderRadius:"10px"}}
            icon={<i class="far fa-user"></i>}
          >
           &nbsp;
            Account
          </Button> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ customer, auth }) => ({
  // customer: customer.customer,
  // linkingProductInfo: customer.linkingProductInfo,
  // productInfo:customer.productInfo,
  //  cartId:customer.productInfo.cartId,
  shopName: customer.shopName,
  productInfo: customer.productInfo,

  // cartId: customer.dispatch.length && customer.dispatch[0].cartId || "",

  // userId: auth.userDetails.userId,
  // productId:products.products.productId
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      // getCustomerProductList,
      // LinkProductInfo,
      // getShopName
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContentActionRight);

// export default CustomerContentActionRight;
