import { combineReducers } from "redux";
import { authReducer } from "../Containers/Auth/AuthReducer";
import {productsReducer} from "../Containers/Products/ProductsReducer";
import { manageReducer } from "../Containers/Manage/ManageReducer";
import { accountReducer } from "../Containers/Account/AccountReducer";
import { customerReducer } from "../Containers/Customer/CustomerReducer";
import { homeReducer } from "../Containers/Home/HomeRducer";
import { orderReducer } from "../Containers/Order/OrderReducer";
import { shopReducer } from "../Containers/Shop/ShopReducer";
import { goodsReducer } from "../Containers/Goods/GoodsReducer";
import { transactionReducer } from "../Containers/Transaction/TransactionReducer";
import { paymentsReducer } from "../Containers/Payments/PaymentsReducer";
import { dashboardReducer } from "../Containers/Dashboard/DashboardReducer";
import { ordainReducer } from "../Containers/Ordain/OrdainReducer";
import { profileReducer } from "../Containers/Profile/ProfileReducer";
import {EmailSettingReducer} from "../Containers/EmailSetting/EmailSettingReducer";
import {newsLetterReducer} from "../Containers/NewsLetter/NewsLetterReducer";
const appReducer = combineReducers({
  auth: authReducer,
  products:productsReducer, 
  manage:manageReducer,
  account:accountReducer,
  customer:customerReducer,
  home:homeReducer,
  order:orderReducer,
  shop:shopReducer,
  goods:goodsReducer,
  transaction:transactionReducer,
  payments:paymentsReducer,
  dashboard:dashboardReducer,
  ordain:ordainReducer,
  profile:profileReducer,
  emailSetting:EmailSettingReducer,
  newsletter:newsLetterReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    sessionStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
