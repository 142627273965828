import * as types from "./ManageActionType";
import { base_url } from "../../Config/Auth";
import axios from "axios";

export const AddDeliveryCharges = (data,merchantId) => (dispatch) => {
    dispatch({
      type: types.ADD_DELIVERY_CHARGES_REQUEST,
    });
    axios
      .post(`${base_url}/delivery/charges`, data)
      .then((res) => {
        dispatch(getDeliveryCharges(merchantId))
        //console.log(res);
        dispatch({
          type: types.ADD_DELIVERY_CHARGES_SUCCESS,
          payload: res.data,
        });
      
      })
      .catch((err) => {
        //console.log(err);
        dispatch({
          type: types.ADD_DELIVERY_CHARGES_FAILURE,
          payload: err,
        });
      });
  };

  export const getCurrency = () => (dispatch) => {
    dispatch({
      type: types.GET_CURRENCY_REQUEST,
    });
    axios
      .get(`${base_url}/currency/currency`)
      .then((res) => {
        //console.log(res);
        dispatch({
          type: types.GET_CURRENCY_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        //console.log(err);
        dispatch({
          type: types.GET_CURRENCY_FAILURE,
          payload: err,
        });
      });
  };

  export const getDeliveryCharges = (merchantId) => (dispatch) => {
    dispatch({
      type: types.GET_DELIVERY_CHARGES_REQUEST,
    });
    axios
      .get(`${base_url}/delivery/${merchantId}`)
      .then((res) => {
        //console.log(res);
        dispatch({
          type: types.GET_DELIVERY_CHARGES_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        //console.log(err);
        dispatch({
          type: types.GET_DELIVERY_CHARGES_FAILURE,
          payload: err,
        });
      });
  };

  export const handleDeliveryChargeModal=(modalProps)=>(dispatch) =>{
    dispatch({
      type:types.HANDLE_DELIVERY_CHARGE_MODAL,
      payload:modalProps,
    })
  }