import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { FlexContainer } from "../../Components/UI/Layout";
import { Button } from "antd";
import "./OrderTemplate.scss";
import {
   
  getShopName
   
  } from '../Customer/CustomerAction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { base_url } from "../../Config/Auth";
class StripeOrderSuccessFull extends React.Component {
  componentDidMount() {
    this.props.getShopName(`/${this.props.match.params.shopName}/home`)
   
  }
   
  // //console.log(props.placedOrderData )
  render() {
    console.log("nnm",this.props.match.params.redirect_status)
    //console.log(this.props.shopName.name)
    const shopName= this.props.shopName.name
    const str = shopName&&shopName.replace(/ +/g, "");
     //console.log(str)
//   if(this.props.placedOrderData === null){
//     return(
//       <p>....Loading</p>
//     )
//   }
  return (
    <div className="oderContainer">
    <div className="box center">
      <CheckCircleFilled style={{ fontSize: "6.6875em", color: "#3066BE" }} />
      <div class="mt-2 items-center flex max-sm:flex-col md:flex-col">
      <h1>Your Order is Successfull <br/>{this.props.match.params.orderId|| ""}</h1>
     
      <h1 class="text-center">
       For More Details click on "Track Order" to check order status.
      </h1>
      </div>
      <FlexContainer justifyContent="center" style={{ width: "100%" }}>
        <div className="btnTrck">
        
        <Link to={`/${this.props.match.params.shopName}/track`}>
          <Button
          className="btnTrack"
          >
            Track Order
          </Button>
          </Link>
        </div>
        &nbsp;&nbsp;
        <div>
        
    
        <Link to="/">
          <Button 
          className="btnShping"
          >
            Continue Shopping
          </Button>
          </Link>
          </div>
      
      
      </FlexContainer>
    </div>
  </div>
  );
}
}

const mapStateToProps = ({ customer, auth }) => ({
  paymentId: customer.paymentDetails.stripePaymentId,
  shopName:customer.shopName,
  confirmedPayment:customer.confirmedPayment

});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
      {
        getShopName
        //  addPlaceOrder
      },
      dispatch
  );




export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeOrderSuccessFull));
