import * as types from "./AuthTypes";
import { base_url, login_url } from "../../Config/Auth";
import axios from "axios";
import { message } from "antd";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

export const login = ({ email, password }, history,userId, cb) => (dispatch) => {

  dispatch({
    type: types.LOGIN_REQUEST,
  });
  axios
    .post(`${login_url}/user/login`, {
      email: email,
      password: password,
    })
    .then((res) => {
 
      dispatch(getUserDetails(res.data.userId));
      if (res.data.userType==="Admin"){ history.push("/dashboard")}
      else {
      history.push("/home");
      }
      dispatch({
        type: types.LOGIN_SUCCESS,
        payload: res.data,
      });
      cb && cb("success");
    })
    .catch((err) => {
     
      cb && cb("failure");

      if (
        err &&
        err.response &&
        err.response.data ===
          "You have entered an invalid username or password "
      ) {
        message.error("You have entered an invalid username or password ");
      } else {
        message.error("Login credentials do not match");
      
        history.push({
          pathname: "/login",
        });
      }
      dispatch({
        type: types.LOGIN_FAILURE,
        payload: err,
      });
    });
};

/**

 * get user details after login
 */
export const getUserDetails = (userId) => (dispatch) => {
  dispatch({
    type: types.GET_USER_DETAILS_REQUEST,
  });
  axios
    .get(`${base_url}/user/${userId}`)
    .then((res) => {
     
      sessionStorage.setItem("userDetails", JSON.stringify(res.data));
      dispatch({
        type: types.GET_USER_DETAILS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
    
      history.push({
        pathname: "/",
      });
      dispatch({
        type: types.GET_USER_DETAILS_FAILURE,
        payload: err,
      });
    });
};

export const getCountries = () => (dispatch) => {
  dispatch({
    type: types.GET_COUNTRIES_REQUEST,
  });
  axios
    .get(`${base_url}/country/all-country`)
    .then((res) => {
     
      dispatch({
        type: types.GET_COUNTRIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
    
      dispatch({
        type: types.GET_COUNTRIES_FAILURE,
        payload: err,
      });
    });
};

/**
 * logout the user
 * clear token from sessionStorage
 * redirect to login
 */
export const logout = (history) => (dispatch) => {
  window.sessionStorage.clear();
  history.push("/login");
  dispatch({ type: types.LOGOUT });
  message.success("You have successfully logged out. See you soon.");
};


export const doSignUp = (data,cb) => (dispatch) => {
  dispatch({
    type: types.DO_SIGN_UP_REQUEST,
  });
  axios
    .post(`${base_url}/api/v2/merchant/register`, data, {
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("token") || "",
    },
  })
    .then((res) => {
     dispatch(getUserDetails(res.data.users && res.data.users[0].userId));
       history.push("/home")
      dispatch({
        type: types.DO_SIGN_UP_SUCCESS,
        payload: res.data,
      });

      cb &&cb("success")
      message.success("Registration Successful");
    })
    .catch((err) => {
      dispatch({
        type: types.DO_SIGN_UP_FAILURE,
        payload: err,
      });
      cb&&cb("failure")
      message.error("Store can not be created as another store already exists with same store name or emailId !");
    });
};

export const verifyEmailurL = (data) => (dispatch) => {
  dispatch({
    type: types.VERIFY_EMAIL_REQUEST,
  });
  axios
    .post(`${base_url}/api/otp/generateOTP`,data,
    {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    }
    )
    .then((res) => {
    
    
      dispatch({
       
        type: types.VERIFY_EMAIL_SUCCESS,
        payload: res.data,
      });
      // cb &&cb("success")
      message.success("OTP sent successfully to the provided Email account");
    })
    .catch((err) => {
     
      // history.push(
      //   "/signup"
      // );
      dispatch({
        type: types.VERIFY_EMAIL_FAILURE,
        payload: err,
      });
      // cb&&cb("failure")
    
    });
};
export const validateOtpurL = (data,cb) => (dispatch) => {
  dispatch({
    type: types.VALIDATE_OTP_REQUEST,
  });
  axios
    .post(`${base_url}/api/otp/validateOtp`, data)
    .then((res) => {
     
    
      dispatch({
       
        type: types.VALIDATE_OTP_SUCCESS,
        payload: res.data,
      });
      cb &&cb("success")
      message.success(res.data.status);
    })
    .catch((err) => {
  
      dispatch({
        type: types.VALIDATE_OTP_FAILURE,
        payload: err,
      });
      cb&&cb("failure")
      message.error("OTP is not matching with input");
    });
};

export const changePassword = (data, cb) => (dispatch) => {

  dispatch({ type: types.CHANGE_PASSWORD_REQUEST });
  axios
    .put(`${base_url}/merchant/changePassword`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
    
      if (res.data.userType==="Admin"){ history.push("/dashboard")}
      else {
      history.push("/home");
      }
      dispatch({ type: types.CHANGE_PASSWORD_SUCCESS });
      cb && cb("success");
      message.success("You have successfully changed your password");
    })
    .catch((err) => {
   
      dispatch({ type: types.CHANGE_PASSWORD_FAILURE });
      cb && cb("failure");
    });
};
//
export const forgotPassword = (data,cb) => (dispatch) => {

  dispatch({ type: types.FORGOT_PASSWORD_REQUEST });
  axios
    .put(`${base_url}/user/forgotPassword`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
    

      dispatch({ 
        type: types.FORGOT_PASSWORD_SUCCESS,

      });
      cb &&cb("success")
      message.success("You have successfully reset your password");
    })
    .catch((err) => {

      dispatch({ type: types.FORGOT_PASSWORD_FAILURE });
      cb && cb("failure");
    });
};

export const getBussinessCategory = () => (dispatch) => {
  dispatch({
    type: types.GET_BUSSINESS_CATEGORY_REQUEST,
  });
  axios
    .get(`${base_url}/business_category/business/category`)
    .then((res) => {
      console.log(res);
      dispatch({
        type: types.GET_BUSSINESS_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: types.GET_BUSSINESS_CATEGORY_FAILURE,
        payload: err,
      });
    });
};