import React,{useEffect} from "react";
import { FlexContainer, MainWrapper } from "../../Components/UI/Layout";
import { Formik, Form, FastField } from "formik";
import { StyledLabel } from "../../Components/UI/Elements";
import { InputComponent } from "../../Components/Forms/Formik/InputComponent";
import { Checkbox, Button } from "antd";
import {AddDeliveryCharges,getCurrency,getDeliveryCharges} from "./ManageAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// import {SelectComponent} from "../../Components/Forms/Formik/SelectComponent";
import SearchSelect from "../../Components/Forms/Formik/SearchSelect";
import "./Manage.scss";
import { Spacer } from "../../Components/UI/Elements";
import { values } from "lodash";
import {getUserDetails} from "../Auth/AuthAction";
import styled from 'styled-components'
import * as Yup from "yup";

const ManageSchema = Yup.object().shape({

  localDeliveryCharges: Yup.string()
    .required("Required"),

    internationalDeliveryCharges:Yup.string()
    .required("Required"),
  
});
function ManageForm(props) {

  useEffect(() => {
    props.getDeliveryCharges(props.merchantId);
    // props.getUserDetails(props.userId);
  }, []);

  //console.log(props.viewCharges && props.viewCharges.internationalDeliveryCharges)
  return (
    <>
      <Formik
      enableReinitialize
        initialValues={{
          localDeliveryCharges:props.viewCharges.localDeliveryCharges,
          internationalDeliveryCharges:props.viewCharges.internationalDeliveryCharges,
          merchantId:props.merchantId,
          currencyName:props.currencyName,
        }}
        validationSchema={ManageSchema}
        onSubmit={(values, { resetForm }) => {
          props.AddDeliveryCharges({
            ...values
          },
          props.merchantId,
          );
          resetForm();
        }}
      >
         {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          ...rest
        }) => (
        <Form>
    <div>
      
          <MainWrapper width="90%" margin="auto">
            {/* <Spacer marginTop="1%"/> */}
            <div>
              <StyledLabel
                >
               <h1> Delivery Charges</h1>           
                         <hr />
              </StyledLabel>
            </div>
            
            <StyledLabel><h1>Local (Flat Rate)</h1></StyledLabel>
            <Spacer marginTop="1%"/>
            <FlexContainer justifyContent="space-evenly">
            
              <ManInputContainer>
                <FastField
                  component={InputComponent}
                  name="localDeliveryCharges"
                  label="Delivery charges"
                  placeholder=""
                  isColumn
                />
              </ManInputContainer>
             
              <ManInputContainer2>
              <FastField
                      component={InputComponent}
                      name="currencyName"
                      label="Currency*"
                      isColumnWithoutNoCreate                     
                      isColumn  
                      disabled
                      style={{ cursor: props.disabled ? "pointer" : "not-allowed" }}                     
                    />
                  </ManInputContainer2>
              </FlexContainer>
            <Spacer marginTop="2%"/>
            <StyledLabel><h1>International</h1></StyledLabel>
            <Spacer marginTop="2%"/>
             <FlexContainer justifyContent="space-evenly">
            
              <ManInputContainer>
                <FastField
                  component={InputComponent}
                  name="internationalDeliveryCharges"
                  label="Delivery charges per Kg"
                  placeholder=""
                  isColumn
                />
              </ManInputContainer>
              <ManInputContainer2>
              <FastField
                      component={InputComponent}
                      name="currencyName"
                      label="Currency*"
                      isColumnWithoutNoCreate                     
                      isColumn  
                      disabled
                      style={{ cursor: props.disabled ? "pointer" : "not-allowed" }}                     
                    />
                  </ManInputContainer2>
              </FlexContainer>
             
            <Spacer marginTop="2%"/>
            <FlexContainer
              display= "flex"
              justifyContent= "flex-end"
              margin= "1em 0em 0 0 "
          >
            <Button
              className='advBtn'
            htmlType="submit"
                background ="#3066BE"
                boxShadow ="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius= "10px"
                color= "#FFFFFF"
                width= "10.5625em"
            > 
             <h3 class="text-white font-bold">
              Save Changes
              </h3>
            </Button>
   
          </FlexContainer>
          </MainWrapper>
        </div>
          
        </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = ({ manage,auth}) => ({
  currencies:manage.currencies,
  viewCharges:manage.viewCharges,
  merchantId:auth.userDetails.merchantDetails.merchantId,
  userId: auth.userDetails.userId,
  currencyName:auth.userDetails.currencyName,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      AddDeliveryCharges,
      getCurrency,
      getDeliveryCharges,
      // getUserDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageForm);


const ManInputContainer = styled.div`
 
  width: 38%;
  
  @media only screen and (max-width: 600px) {
    width: 100%;
    
  }
`
const ManInputContainer2 = styled.div`
 
  width: 22%;
  
  @media only screen and (max-width: 600px) {
    width: 100%;
    
  }
`