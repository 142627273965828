import React from "react";
import { Tooltip } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RollbackOutlined } from "@ant-design/icons";
import { Formik, Form, FastField } from "formik";
import { InputComponent } from "../../../../../Components/Forms/Formik/InputComponent";
import { StyledLabel } from "../../../../../Components/UI/Elements";
import {
  FlexContainer,
  MainWrapper,
} from "../../../../../Components/UI/Layout";
import { addTrackOrder } from "../../../CustomerAction";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import TrackTable from "./TrackTable";
import styled from "styled-components";
import MainHeader from "../../../Header/MainHeader";
// import {withRouter} from "react-router";
import CustomerContentHeader from "../../../Header/CustomerContentHeader";
import "../../../Customer.scss"
function Track(props) {
  let history = useHistory();

  const shopName = props.shopName.name;
  const str = shopName && shopName.replace(/ +/g, "");

  function handleCallBack(data, orderId) {
    history.push(`/${str}/ordermaincontent/${orderId}`);
  }
  return (
    <>
      <MainHeader />
      {/* <CustomerContentHeader /> */}

      <Formik
        initialValues={{
          orderId: "",
        }}
        onSubmit={(values, { resetForm }) => {
          props.addTrackOrder(
            {
              ...values,
            },
            handleCallBack
          );
          resetForm();
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          ...rest
        }) => (
          <Form>
            <FlexContainer justifyContent="center">
              <MainWrapper width="65%">
                <h1>Track Order</h1>
                <hr />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <InputContainer>
                    <div style={{ width: "100%" }}>
                      <FastField
                        component={InputComponent}
                        name="orderId"
                        label="Order Id"
                        isColumn
                      />
                    </div>
                  </InputContainer>

                  <ButtonContainer>
                    <div style={{ marginTop: "20px", marginLeft: "10px" }}>
                      <Button
                        className="advBtn"
     
                        loading={props.trackingOrder}
                      >
                      <label class="text-white font-bold">Submit</label>  
                      </Button>
                    </div>
                  </ButtonContainer>
                </div>
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Link to="/">
                    <Button
                      className="advBtn"
                 
                    >
              <label class="text-white font-bold"> Continue Shopping</label> 
                    </Button>
                  </Link>
                </div>
              </MainWrapper>
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}
const mapStateToProps = ({ customer }) => ({
  trackingOrder: customer.trackingOrder,
  shopName: customer.shopName,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addTrackOrder,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Track);
const ButtonContainer = styled.div`
  display: flex;
  justify-content:flex-end @media only screen and (max-width: 600px) {
    justify-content: center;
  }
`;
const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;
