import React, { Suspense, useState } from "react";
import { MainWrapper } from "../../Components/UI/Elements";
import { BundleLoader } from "../../Components/Placeholder";
import { FlexContainer } from "../../Components/UI/Layout";
import { Tooltip } from "antd";
import { RollbackOutlined } from "@ant-design/icons";
import ProductsActionLeft from "./ProductsActionLeft";
import ProductsActionRight from "./ProductsActionRight";
import ProductInformationForm from "./child/ProductInformationForm";
import ProductImageForm from "./child/ProductImageForm";
function ProductsDetails(props) {
  const name = [
    {
      productsName: "Product Information",
      productId: "1",
      component: <ProductInformationForm />,
    },
    // {
    //   productsName: "Product Image",
    //   productId: "2",
    //   component: <ProductImageForm />,
    // },
  ];
  // const nameStarter = [
  //   {
  //     productsName: "Product Information",
  //     productId: "1",
  //     component: <ProductInformationForm />,
  //   },
  // ];
  const [products] = useState(name);
  const [currentProductsOpen, setCurrentProductsOpen] = useState(name[0]);
  const handleProductClick = (item) => {
    // debugger;
    setCurrentProductsOpen(item);
  };
 

  return (
    <div style={{backgroundColor:"lightgrey"}}>
      <div>
      <Tooltip title="Back">
          <RollbackOutlined
            style={{ marginRight: "0.3rem", color: "#1890ff",fontSize:"2em" }}
            onClick={() => props.history.goBack()}
          />
        </Tooltip>
      </div>
      {false ? (
        <MainWrapper>
          <BundleLoader />
        </MainWrapper>
      ) : (
        <FlexContainer>
          <Suspense fallback={"Loading..."}>
            <FlexContainer flexWrap="no-wrap" style={{ width: "100%" }}>
              <div style={{ width: "25%" }}>
                <ProductsActionLeft
                  handleProductClick={handleProductClick}
                  product={products}
                  currentProductsOpen={currentProductsOpen}
                />
              </div>
              <div style={{ width: "75%" }}>
                <ProductsActionRight current={currentProductsOpen} />
              </div>
            </FlexContainer>
          </Suspense>
        </FlexContainer>
      )}
    </div>
  );
}

export default ProductsDetails;
