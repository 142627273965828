import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCountries } from "../../../../Containers/Auth/AuthAction";
import styled from "styled-components";
import { Formik, Form, Field, FastField, FieldArray } from "formik";
import SearchSelect from "../../../../Components/Forms/Formik/SearchSelect";
import { Spacer } from "../../../../Components/UI/Elements";
// import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Button } from "antd";
import { InputComponent } from "../../../../Components/Forms/Formik/InputComponent";
// import SearchSelect from "../../../../Components/Forms/Formik/SearchSelect";
import { FlexContainer } from "../../../../Components/UI/Layout";
import AddressFieldArray from "../../../../Components/Forms/Formik/AddressFieldArray";
import { addDeliveryInfo } from "../../CustomerAction";
import { Link, useHistory } from "react-router-dom";
import { getDeliveryInfo } from "../../CustomerAction";

const CartMainSchema = Yup.object().shape({
  // address1: Yup.string().required("Input needed!"),
  emailId: Yup.string().email("Enter a valid Email").required("Input needed!"),
  firstName: Yup.string().required("Input needed!"),
  lastName: Yup.string().required("Input needed!"),
  dialCode: Yup.string().required("Input needed!"),
  // phoneNumber: Yup.string().matches(phoneRegExp, "Enter a valid Phone No").required("Input needed!"),
  // tag_with_company: Yup.string().required("Please Select Company"),
  countryId: Yup.string().required("Input needed!"),
  phoneNo: Yup.string().required("Input needed!"),
  // address: Yup.string().required("Input needed!"),
  // city: Yup.string().required("Input needed!"),
  // country: Yup.string().required("Please select your country!"),
  // pinCode: Yup.string().required("Please select your code!"),
});

function CartMainContentForm(props) {
  const shopName = props.shopName.name;
  const str = shopName && shopName.replace(/ +/g, "");
  let history = useHistory();

  function handleCallBack(data) {
    history.push(`/${str}/payment`);
  }

  useEffect(() => {
    props.getCountries();
    const value = localStorage.getItem("cartId");
    const final = JSON.parse(value);
    props.getDeliveryInfo(final.cartId);
  }, []);
  //  const {addDeliveryInfo}=props;

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          // address1: "",
          emailId: "",
          // city: "",
          // state: "",
          // pinCode: "",
          phoneNo: "",
          dialCode: "",
          // country: "",
          countryId: "",
          address: [
            {
              // addressType: "",
              address1: "",
              // address2: "",
              // town: "",
              // street: "",
              city: "",
              pinCode: "",
              state: "",
              // country: "",
              latitude: "",
              longitude: "",
            },
          ],
        }}
        validationSchema={CartMainSchema}
        onSubmit={(values, { resetForm }) => {
          const value = localStorage.getItem("cartId");
          const final = JSON.parse(value);
          // const cartId = localStorage.getItem('cartId')

          props.addDeliveryInfo(
            {
              ...values,
              address1: values.address[0].address1,
              city: values.address[0].city,
              pinCode: values.address[0].pinCode,
              state: values.address[0].state,
              latitude: values.address[0].latitude,
              longitude: values.address[0].longitude,
            },
            final.cartId,
            handleCallBack
          );
          resetForm();
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          ...rest
        }) => (
          <Form>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Spacer marginTop="2%" />
                <Box>
                  <Box1>
                    <InputContainer>
                      <Field
                        name="firstName"
                        label="First Name"
                        type="text"
                        placeholder="Enter name"
                        width={"100%"}
                        component={InputComponent}
                        className="mt-1 block w-full rounded-md border-blue-900 shadow-sm  sm:text-sm"
                        isColumn
                        inlineLabel
                      />
                       {/* <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                      <label className="block text-sm font-medium text-gray-700">
                      First Name
                      </label>
                      <input
                        type="text"
                        placeholder="Enter name"
                        name="firstName"                      
                        className="mt-1 block w-full rounded-md border-blue-900 shadow-sm  sm:text-sm"
                      />
                    </div> */}
                  
                    </InputContainer>
                    {/* <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        autoComplete="family-name"
                        className=" border-red-600"
                        //className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div> */}
                    <InputContainer2>
                      <Field
                        name="lastName"
                        label="Last Name"
                        type="text"
                        placeholder="Enter name"
                        width={"100%"}
                        component={InputComponent}
                        isColumn
                        inlineLabel
                        // style={{
                        //   borderRadius: "0.3em",
                        //   flexBasis: "80%"
                        // }}
                      />
                    </InputContainer2>
                  </Box1>
                  <Box1>
                    <InputContainer3>
                      <FastField
                        name="dialCode"
                        label="Country code"
                        isColumn
                        margintop={"0px"}
                        width={"100%"}
                        selectType="dialCode"
                        component={SearchSelect}
                        isColumnWithoutNoCreate
                        inlineLabel
                      />
                    </InputContainer3>
                    <Spacer />
                    <InputContainer4>
                      <FastField
                        type="mobileNo"
                        name="phoneNo"
                        label="Mobile #"
                        placeholder="Mobile #"
                        component={InputComponent}
                        inlineLabel
                        width={"100%"}
                        isColumn
                        style={
                          {
                            // flexBasis: "80%",
                            // height: "29px",
                          }
                        }
                      />
                    </InputContainer4>
                    <Spacer />
                  </Box1>
                </Box>

                <Spacer marginTop="2%" />
                <FlexContainer justifyContent="space-between">
                  <InputContainer1>
                    <Field
                      name="emailId"
                      label="Email"
                      type="text"
                      placeholder="Enter email"
                      // width={"60%"}
                      component={InputComponent}
                      isColumn
                      inlineLabel
                      //   style={{
                      //     borderRadius: "0.3em"
                      //   }}
                    />
                  </InputContainer1>

                  <InputContainer1>
                    <Field
                      name="countryId"
                      // value={values.countryId}
                      label="Country"
                      type="text"
                      width={"100%"}
                      component={SearchSelect}
                      isColumnWithoutNoCreate
                      selectType="country_name"
                      isColumn
                      placeholder="Enter country"
                      inlineLabel
                      style={{
                        borderRadius: "0.3em",
                      }}
                    />
                  </InputContainer1>
                </FlexContainer>
                <Spacer marginTop="2%" />
                {/* <FlexContainer justifyContent="space-between"> */}
                <FieldArray
                  name="address"
                  render={(arrayHelpers) => (
                    <AddressFieldArray
                      singleAddress
                      arrayHelpers={arrayHelpers}
                      values={values}
                    />
                  )}
                />
                {/* <div style={{ width: "46%" }}>
                    <Field
                      name="address1"
                      label="Address"
                      type="text"
                      placeholder="Enter address"
                      // width={"60%"}
                      component={InputComponent}
                      isColumn
                      inlineLabel
                    //   style={{
                    //     borderRadius: "0.3em"
                    //   }}
                    />
                  </div> */}
                {/* <InputContainer1>
                      <Field
                        name="city"
                        label="City"
                        type="text"
                        width={"100%"}
                        placeholder="Enter city"
                        component={InputComponent}
                        isColumn
                        inlineLabel
                        style={{
                          borderRadius: "0.3em",
                        }}
                      />
                    </InputContainer1> */}
                {/* </FlexContainer> */}
                <Spacer marginTop="2%" />
                <FlexContainer justifyContent="space-between">
                  {/* <InputContainer1>
                      <Field
                        name="state"
                        label="State/Province"
                        placeholder="Enter State/Province"
                        type="text"
                        width={"100%"}
                        component={InputComponent}
                        isColumn
                        inlineLabel
                        style={{
                          borderRadius: "0.3em",
                        }}
                      />
                    </InputContainer1>
                    <InputContainer1>
                      <Field
                        name="pinCode"
                        label="Zip Code"
                        placeholder="Enter pin code"
                        type="text"
                        width={"100%"}
                        component={InputComponent}
                        isColumn
                        inlineLabel
                        style={{
                          borderRadius: "0.3em",
                        }}
                      />
                    </InputContainer1> */}
                </FlexContainer>

                <Spacer />
              </div>
              <Spacer />
            </div>
           
            <Spacer />
            <FlexContainer justifyContent="flex-end">
              <Button
              className="adHBtn"
                type="primary"
                htmlType="submit"
                loading={props.addingDeliveryInfo}
              >
                <label class="text-white font-bold">
                          Next
                          </label>
              </Button>
   
            </FlexContainer>
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapStateToProps = ({ customer, auth }) => ({
  addingDeliveryInfo: customer.addingDeliveryInfo,
  countries: auth.countries,
  fetchingCountries: auth.fetchingCountries,
  shopName: customer.shopName,
  showDeliveryInfo: customer.showDeliveryInfo,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addDeliveryInfo,
      getCountries,
      getDeliveryInfo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartMainContentForm);
const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const Box1 = styled.div`
  width: 47%;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputContainer = styled.div`
  width: 45%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 5px;
  }
`;
const InputContainer1 = styled.div`
  width: 47%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const InputContainer2 = styled.div`
  width: 47%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const InputContainer3 = styled.div`
  width: 33%;

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-right: 5px;
  }
`;
const InputContainer4 = styled.div`
  width: 65%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
const InputContainer5 = styled.div`
  width: 97%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
