import React, { Component } from "react";
import { getCustomerProductList } from "../CustomerAction";
import { connect } from "react-redux";
import { MultiAvatar } from "../../../Components/UI/Elements";
import { bindActionCreators } from "redux";
import { ActionHeader } from "../../../Components/Utils";
import FWLogo from "../../../Assets/Images/logo-shopper.PNG";
import Item from "antd/lib/list/Item";
import HeaderActionCenter from "../Header/HeaderActionCenter";
import MainHeaderRight from "./MainHeaderRight";
import "./MainHeader.scss";
class MainHeader extends Component {
  render() {
    return (
      <>
        <ActionHeader
          hideInMobile={true}
          fontWeight={"bold"}
          leftComponent={<img src={FWLogo} alt="img" class=" w-48"/>}
          centerComponent={<HeaderActionCenter />}
          rightComponent={<MainHeaderRight />}
        />
      </>
    );
  }
}

const mapStateToProps = ({ customer, auth }) => ({
  shopName: customer.shopName,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
