import React from "react";
import { FlexContainer } from "../../../Components/UI/Layout";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Input, Button } from "antd";
import styled from "styled-components";

import { inputCustomerProductDataSearch } from "../CustomerAction";

const { Search } = Input;

class CustomerContentHeaderActionLeft extends React.Component {
  render() {
   
    const shopName = this.props.shopName.name;
    const str = shopName && shopName.replace(/ +/g, "");
  

    return (
      <div class=" w-wk flex flex-row mt-2">
        <Search
          placeholder="Search Product"
          onSearch={(value) => {
            this.props.inputCustomerProductDataSearch(this.props.shopName.merchantId,value);
            this.props.setVisibleNewUI(true)
            this.props.setCurrentData(value);
          }}
          defaultValue={this.props.currentData}
          enterButton
          allowClear
        
        />
        
        <Button
className="adHBtn"
       
        
          type={this.props.currentData ? "primary" : "default"}
          onClick={this.props.handleClear}
        >
          <label class=" text-white font-bold">
          Clear
          </label>
        </Button>
        </div>
    );
  }
}

const mapStateToProps = ({ customer,auth }) => ({
  shopName: customer.shopName,
  customer: customer.customer,
  merchantId:auth.userDetails.merchantId,
  // merchantDetailsId: auth.userDetails.mrchantDetailsId,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      inputCustomerProductDataSearch,
    },
    dispatch
  );
//  export default CustomerContentHeaderActionLeft;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContentHeaderActionLeft);
const ContainerWrapper = styled.div`
  /* border:1px solid red; */
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;
