export const GET_CANCEL_ORDER_REQUEST="GET_CANCEL_ORDER_REQUEST";
export const GET_CANCEL_ORDER_SUCCESS="GET_CANCEL_ORDER_SUCCESS";
export const GET_CANCEL_ORDER_FAILURE="GET_CANCEL_ORDER_FAILURE";

export const ADD_DELIVERY_INFO_REQUEST="ADD_DELIVERY_INFO_REQUEST";
export const ADD_DELIVERY_INFO_SUCCESS="ADD_DELIVERY_INFO_SUCCESS";
export const ADD_DELIVERY_INFO_FAILURE="ADD_DELIVERY_INFO_FAILURE";

export const TRACK_ORDER_STATUS_REQUEST="TRACK_ORDER_STATUS_REQUEST";
export const TRACK_ORDER_STATUS_SUCCESS="TRACK_ORDER_STATUS_SUCCESS";
export const TRACK_ORDER_STATUS_FAILURE="TRACK_ORDER_STATUS_FAILURE";

export const GET_ORDER_STATUS_REQUEST="GET_ORDER_STATUS_REQUEST";
export const GET_ORDER_STATUS_SUCCESS="GET_ORDER_STATUS_SUCCESS";
export const GET_ORDER_STATUS_FAILURE="GET_ORDER_STATUS_FAILURE";

export const GET_CUSTOMER_LIST_REQUEST = "GET_CUSTOMER_LIST_REQUEST";
export const GET_CUSTOMER_LIST_SUCCESS = "GET_CUSTOMER_LIST_SUCCESS";
export const GET_CUSTOMER_LIST_FAILURE = "GET_CUSTOMER_LIST_FAILURE";

export const INPUT_CUSTOMER_PRODUCT_SEARCH_DATA_REQUSET =
  "INPUT_CUSTOMER_PRODUCT_SEARCH_DATA_REQUSET";
export const INPUT_CUSTOMER_PRODUCT_SEARCH_DATA_SUCCESS =
  "INPUT_CUSTOMER_PRODUCT_SEARCH_DATA_SUCCESS";
export const INPUT_CUSTOMER_PRODUCT_SEARCH_DATA_FAILURE =
  "INPUT_CUSTOMER_PRODUCT_SEARCH_DATA_FAILURE";


  export const LINK_PRODUCT_INFO_REQUEST =
  "LINK_PRODUCT_INFO_REQUEST";
export const LINK_PRODUCT_INFO_SUCCESS =
  "LINK_PRODUCT_INFO_SUCCESS";
export const LINK_PRODUCT_INFO_FAILURE =
  "LINK_PRODUCT_INFO_FAILURE";


  export const GET_CART_LIST_REQUEST = "GET_CART_LIST_REQUEST";
  export const GET_CART_LIST_SUCCESS = "GET_CART_LIST_SUCCESS";
  export const GET_CART_LIST_FAILURE = "GET_CART_LIST_FAILURE";

  export const SET_CART_ID = "SET_CART_ID";

  export const ADD_PLACE_ORDER_REQUEST="ADD_PLACE_ORDER_REQUEST";
export const ADD_PLACE_ORDER_SUCCESS="ADD_PLACE_ORDER_SUCCESS";
export const ADD_PLACE_ORDER_FAILURE="ADD_PLACE_ORDER_FAILURE";


export const GET_SHOP_NAME_REQUEST = "GET_SHOP_NAME_REQUEST";
export const GET_SHOP_NAME_SUCCESS = "GET_SHOP_NAME_SUCCESS";
export const GET_SHOP_NAME_FAILURE = "GET_SHOP_NAME_FAILURE";

export const UPDATE_CART_REQUEST =
"UPDATE_CART_REQUEST";
export const UPDATE_CART_SUCCESS =
"UPDATE_CART_SUCCESS";
export const UPDATE_CART_FAILURE =
"UPDATE_CART_FAILURE";


export const GET_SORT_BY_REQUEST = "GET_SORT_BY_REQUEST";
export const GET_SORT_BY_SUCCESS = "GET_SORT_BY_SUCCESS";
export const GET_SORT_BY_FAILURE = "GET_SORT_BY_FAILURE";


export const DELETE_CART_DATA_REQUEST = "DELETE_CART_DATA_REQUEST";
export const DELETE_CART_DATA_SUCCESS = "DELETE_CART_DATA_SUCCESS";
export const DELETE_CART_DATA_FAILURE = "DELETE_CART_DATA_FAILURE";

export const HANDLE_STRIPE_MODAL = 'HANDLE_STRIPE_MODAL';


export const MAKE_PAYMENT_REQUEST = 'MAKE_PAYMENT_REQUEST';
export const MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_FAILURE = 'MAKE_PAYMENT_FAILURE';


export const GET_PAYMENT_ID_REQUEST = "GET_PAYMENT_ID_REQUEST";
export const GET_PAYMENT_ID_SUCCESS = "GET_PAYMENT_ID_SUCCESS";
export const GET_PAYMENT_ID_FAILURE= "GET_PAYMENT_ID_FAILURE";

export const UPDATE_DELIVERY_INFO_REQUEST="UPDATE_DELIVERY_INFO_REQUEST";
export const UPDATE_DELIVERY_INFO_SUCCESS="UPDATE_DELIVERY_INFO_SUCCESS";
export const UPDATE_DELIVERY_INFO_FAILURE="UPDATE_DELIVERY_INFO_FAILURE";

export const GET_DELIVERY_INFO_REQUEST="GET_DELIVERY_INFO_REQUEST";
export const GET_DELIVERY_INFO_SUCCESS="GET_DELIVERY_INFO_SUCCESS";
export const GET_DELIVERY_INFO_FAILURE="GET_DELIVERY_INFO_FAILURE";

