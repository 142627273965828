import * as types from "./HomeActionTypes";
import moment from "moment";

const initialState = {
  viewType: "home",

    //add datewise
  addingDatewiseorder: false,
  addingDatewiseorderError: false,
  datewiseOrder: [],
  
  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

  dateRangeList: [
    // {
    //   id: 1,
    //   type: "LifeTime",
    //   value: "Life Time",
    //   starter: true,
    //   isSelected: true,
    //   startDate: moment()
    //     // .subtract(1, "days")
    //     .toISOString(),
    //   endDate: moment().toISOString(),
    // },

    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected:true,
      startDate: moment()
        // .subtract(1, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 2,
      type: "Yesterday",
      value: "Yesterday",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(1, "days")

        .toISOString(),
      endDate: moment()
        .subtract(1, "days")

        .toISOString(),
    },
    {
      id:3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(7, "days")

        .toISOString(),
      endDate: moment()
        .subtract(7, "days")

        .toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(30, "days")

        .toISOString(),
      endDate: moment()
        .subtract(30, "days")

        .toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment().startOf("week").toISOString(),
      endDate: moment().endOf("week").toISOString(),
    },
    {
      id:6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment().startOf("month").toISOString(),
      endDate: moment().endOf("month").toISOString(),
    },
    // {
    //   id: 8,
    //   type: "DateRange",
    //   value: "Date Range",
    //   starter: false,
    //   isSelected: false,
    //   startDate: moment().startOf("year").toISOString,
    //   endDate: moment().endOf("year").toISOString(),
    // },
  ],

  fetchingTestShop: false,
  fetchingTestShop: false,
  testShop:[],

  fetchingPendingList: false,
  fetchingPendingList: false,
  pendingList:[],

  fetchingAcceptedList: false,
  fetchingAcceptedList: false,
  acceptedList:[],

  fetchingShippedList: false,
  fetchingShippedList: false,
  shippedList:[],

  fetchingCancelledList: false,
  fetchingCancelledList: false,
  scancelledList:[],

  puttingProOpenClose:false,
  puttingProOpenCloseError:false,

  fetchingAllProductCategory:false,
  fetchingAllProductCategoryError:false,
  allcategoryProducts:[],

  fetchingProductsByCategoryId: false,
  fetchingProductsByCategoryIdError: false,
  productsbyCategoryId:[],
  
  fetchingAllProductSearch: false, 
  fetchingAllProductSearchError:false, 
  productSearchAll:[],

  fetchingHomeCategoryProductSearch: false, 
  fetchingHomeCategoryProductSearchError:false, 
  productDetailsSearchCategory:[],

  signupForNewsAndOffer: false,
  signupForNewsAndOfferError: false,

  addingContact: false,
  addingContactError: false,

  fetchingMerchantHistory: false,
  fetchingMerchantHistoryError: false,
  merchantHistory:[],

  clickCallBackModal:false,
  clickAvailabilityModal:false,

  fetchingHomepage: false,
  fetchingHomepageError: false,
  Homepage:{},
  updatingHomePage: false, updatingHomePageError: false,
};

export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ORDER_DATEWISE_REQUEST:
      return {
        ...state,
        addingDatewiseorder: true,
        addingDatewiseorderError: false,
      };
    case types.ADD_ORDER_DATEWISE_SUCCESS:
      return {
        ...state,
        addingDatewiseorder: false,
        addingDatewiseorderError: false,
        datewiseOrder: false,
      };
    case types.ADD_ORDER_DATEWISE_FAILURE:
      return {
        ...state,
        addingDatewiseorder: false,
        addingDatewiseorderError: true,
        datewiseOrder: false,
      };

    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
      case types.GET_TEST_SHOP_REQUEST:
      return { ...state, fetchingTestShop: true };
    case types.GET_TEST_SHOP_SUCCESS:
      return {
        ...state,
        fetchingTestShop: false,
        testShop: action.payload,
      };
    case types.GET_TEST_SHOP_FAILURE:
      return {
        ...state,
        fetchingTestShop: false,
        fetchingTestShop: true,
      };

      case types.GET_PENDING_LIST_REQUEST:
      return { ...state, fetchingPendingList: true };
    case types.GET_PENDING_LIST_SUCCESS:
      return {
        ...state,
        fetchingPendingList: false,
        pendingList: action.payload,
      };
    case types.GET_PENDING_LIST_FAILURE:
      return {
        ...state,
        fetchingPendingList: false,
        fetchingPendingList: true,
      };

      case types.GET_ACCEPTED_LIST_REQUEST:
      return { ...state, fetchingAcceptedList: true };
    case types.GET_ACCEPTED_LIST_SUCCESS:
      return {
        ...state,
        fetchingAcceptedList: false,
        acceptedList: action.payload,
      };
    case types.GET_ACCEPTED_LIST_FAILURE:
      return {
        ...state,
        fetchingAcceptedList: false,
        fetchingAcceptedList: true,
      };
      
      case types.GET_SHIPPED_LIST_REQUEST:
      return { ...state, fetchingShippedList: true };
    case types.GET_SHIPPED_LIST_SUCCESS:
      return {
        ...state,
        fetchingShippedList: false,
        shippedList: action.payload,
      };
    case types.GET_SHIPPED_LIST_FAILURE:
      return {
        ...state,
        fetchingShippedList: false,
        fetchingShippedList: true,
      };

      case types.GET_CANCELLED_LIST_REQUEST:
      return { ...state, fetchingCancelledList: true };
    case types.GET_CANCELLED_LIST_SUCCESS:
      return {
        ...state,
        fetchingCancelledList: false,
        cancelledList: action.payload,
      };
    case types.GET_CANCELLED_LIST_FAILURE:
      return {
        ...state,
        fetchingCancelledList: false,
        fetchingCancelledList: true,
      };

      case types.PUT_OPEN_IND_REQUEST:
        return {
          ...state,
          puttingProOpenClose: true,
        };
      case types.PUT_OPEN_IND_SUCCESS:
        return {
          ...state,
          puttingProOpenClose: false,
          testShop:state.testShop.map((item)=>{
            if(item.merchantId === action.payload.merchantId){
              return {...item,openInd:action.payload.openInd}
            }
            else{
              return item
            }
  
          })
        };
      case types.PUT_OPEN_IND_FAILURE:
        return {
          ...state,
          puttingProOpenClose: false,
          puttingProOpenCloseError: true,
        };

        case types.GET_ALLPRODUCT_CATEGORY_REQUEST:
          return { ...state, fetchingAllProductCategory: true };
        case types.GET_ALLPRODUCT_CATEGORY_SUCCESS:
          return {
            ...state,
            fetchingAllProductCategory: false,
            allcategoryProducts: action.payload,
          };
        case types.GET_ALLPRODUCT_CATEGORY_FAILURE:
          return {
            ...state,
            fetchingAllProductCategory: false,
            fetchingAllProductCategoryError: true,
          };
    
          case types.GET_PRODUCT_BY_CATEGORY_ID_REQUEST:
            return { ...state, fetchingProductsByCategoryId: true };
          case types.GET_PRODUCT_BY_CATEGORY_ID_SUCCESS:
            return {
              ...state,
              fetchingProductsByCategoryId: false,
              productsbyCategoryId: action.payload,
            };
          case types.GET_PRODUCT_BY_CATEGORY_ID_FAILURE:
            return {
              ...state,
              fetchingProductsByCategoryId: false,
              fetchingProductsByCategoryIdError: true,
            };

            case types.HOME_ALL_PRODUCT_SEARCH_REQUEST:
              return { ...state, fetchingAllProductSearch: true };
            case types.HOME_ALL_PRODUCT_SEARCH_SUCCESS:
              return {
                ...state,
                fetchingAllProductSearch: false,
                productSearchAll: action.payload,
                // productsbyCategoryId:action.payload
                // serachedData: action.payload,
              };
            case types.HOME_ALL_PRODUCT_SEARCH_FAILURE:
              return { ...state,fetchingAllProductSearchError: true };      
              
              case types.SIGN_UP_NEWS_AND_OFFER_REQUEST:
      return {
        ...state,
        signupForNewsAndOffer: true,
        signupForNewsAndOfferError: false,
      };
    case types.SIGN_UP_NEWS_AND_OFFER_SUCCESS:
      return {
        ...state,
        signupForNewsAndOffer: false,
        signupForNewsAndOfferError: false,
        
      };
    case types.SIGN_UP_NEWS_AND_OFFER_FAILURE:
      return {
        ...state,
        signupForNewsAndOffer: false,
        signupForNewsAndOfferError: true,
      };

      case types.SEARCH_CATEGORY_PRODUCTS_DETAILS_REQUEST:
        return { ...state, fetchingAllProductSearchfetchingAllProductSearch: true };
      case types.SEARCH_CATEGORY_PRODUCTS_DETAILS_SUCCESS:
        return {
          ...state,
          fetchingAllProductSearchfetchingAllProductSearch: false,
          productsbyCategoryId: action.payload,
          // productsbyCategoryId:action.payload
        };
      case types.SEARCH_CATEGORY_PRODUCTS_DETAILS_FAILURE:
        return { ...state,fetchingAllProductSearchfetchingAllProductSearchError: true };   
        
        case types.ADD_CONTACT_DETAILS_REQUEST:
          return { ...state, addingContact: true };
        case types.ADD_CONTACT_DETAILS_SUCCESS:
          return { ...state, addingContact: false,
               placedOrderData: action.payload };
        case types.ADD_CONTACT_DETAILS_FAILURE:
          return { ...state,
               addingContact: false,
               addingContactError: true 
              };
  case types.GET_MERCHANT_HISTORY_REQUEST:
    return { ...state, fetchingMerchantHistory: true };
  case types.GET_MERCHANT_HISTORY_SUCCESS:
    return {
      ...state,
      fetchingMerchantHistory: false,
      merchantHistory: action.payload,
    };
  case types.GET_MERCHANT_HISTORY_FAILURE:
    return {
      ...state,
      fetchingMerchantHistory: false,
      fetchingMerchantHistoryError: true,
    };
    case types.HANDLE_AVAILABILITY_MODAL:
      return {...state,clickAvailabilityModal:action.payload}
    case types.HANDLE_CALL_BACK_MODAL:
      return { ...state,clickCallBackModal: action.payload };

      case types.GET_HOMEPAGE_REQUEST:
        return { ...state, fetchingHomepage: true };
      case types.GET_HOMEPAGE_SUCCESS:
        return {
          ...state,
          fetchingHomepage: false,
          Homepage: action.payload,
        };
      case types.GET_HOMEPAGE_FAILURE:
        return {
          ...state,
          fetchingHomepage: false,
          fetchingHomepageError: true,
        };
      case types.UPDATE_HOMEPAGE_REQUEST:
        return { ...state, updatingHomePage: true };
      case types.UPDATE_HOMEPAGE_SUCCESS:
        return { ...state, updatingHomePage: false, 
          // Homepage:state.Homepage.map((item) => {
          //             if (item.merchantId === action.payload.merchantId) {
          //               return action.payload;
          //             } else {
          //               return item;
          //             }
          //           }),
                    
                  };
      case types.UPDATE_HOMEPAGE_FAILURE:
        return { ...state, updatingHomePage: false, updatingHomePageError: true }; 
      default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {

    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });

