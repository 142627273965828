import * as types from "./AccountActionTypes";

const initialState = {
  fetchingShopDetails: false,
  fetchingShopDetailsError:false,
  shopDetails: [],

  updatingShopDetails: false,
  updatingShopDetailsError: false,
  updateShopByMerchantId:[],

  idUploadModal: false,
  addressUploadModal: false,

//add Id docs
  addingIdDocument: false,
  addingIdDocumentError: false,

  //get Id document
  fetchingIdDocuments: false,
  fetchingIdDocumentsError: false,
  documentsById: [],

  //add Id docs
  addingAddressDocument: false,
  addingAddressDocumentError: false,

  //get Id document
  fetchingAddressDocuments: false,
  fetchingAddressDocumentsError: false,
  documentsByAddress: [],

  accountShopdetailsModal:false,
  merchantHistoryModal:false,

};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    //getShopDetails

    case types.GET_SHOP_DETAILS_REQUEST:
      return { ...state, fetchingShopDetails: true };
    case types.GET_SHOP_DETAILS_SUCCESS:
      return {
        ...state,
        fatchingShopDetails: false,
        shopDetails: action.payload,
      };
    case types.GET_SHOP_DETAILS_FAILURE:
      return { ...state, fetchingShopDetails: false,fetchingShopDetailsError:true };

    case types.UPDATE_SHOP_DETAILS_REQUEST:
      return { ...state, updatingShopDetails: true };
    case types.UPDATE_SHOP_DETAILS_SUCCESS:
      return { ...state, updatingShopDetails: false, 
        updateShopByMerchantId:state.updateShopByMerchantId.map((item) => {
                    if (item.merchantId === action.payload.merchantId) {
                      return action.payload;
                    } else {
                      return item;
                    }
                  }),
                  
                };
    case types.UPDATE_SHOP_DETAILS_FAILURE:
      return { ...state, updatingShopDetails: false, updatingShopDetailsError: true };

    case types.HANDLE_ID_UPLOAD_MODAL:
      return { ...state, idUploadModal: action.payload };

    case types.HANDLE_ADDRESS_UPLOAD_MODAL:
      return { ...state, addressUploadModal: action.payload };

//ADD ID DOCS
      
      /**
       * get list of documents of a Id
       */
      case types.GET_ID_DOCUMENTS_REQUEST:
        return {
          ...state,
          fetchingIdDocuments: true,
        };
      case types.GET_ID_DOCUMENTS_SUCCESS:
        return {
          ...state,
          fetchingIdDocuments: false,
          documentsById: action.payload,
        };
      case types.GET_ID_DOCUMENTS_FAILURE:
        return {
          ...state,
          fetchingIdDocuments: false,
          fetchingIdDocumentsError: true,
        };  

// ADD ADDRESS DOCS
        case types.ADD_ADDRESS_DOCUMENT_REQUEST:
            return {
              ...state,
              addingAddressDocument: true,
              addingAddressDocumentError: false,
            };
          case types.ADD_ADDRESS_DOCUMENT_SUCCESS:
            return {
              ...state,
              addingAddressDocument: false,
              addingAddressDocumentError: false,
              addressUploadModal: false,
            };
          case types.ADD_ADDRESS_DOCUMENT_FAILURE:
            return {
              ...state,
              addingIdDocument: false,
              addingIdDocumentError: true,
              addressUploadModal: false,
            };
          /**
           * get list of documents of a ADDRESS
           */
          case types.GET_ADDRESS_DOCUMENTS_REQUEST:
            return {
              ...state,
              fetchingAddressDocuments: true,
            };
          case types.GET_ADDRESS_DOCUMENTS_SUCCESS:
            return {
              ...state,
              fetchingAddressDocuments: false,
              documentsByAddress: action.payload,
            };
          case types.GET_ADDRESS_DOCUMENTS_FAILURE:
            return {
              ...state,
              fetchingAddressDocuments: false,
              fetchingAddressDocumentsError: true,
            };

            // case types.UPDATE_SHOP_LOGO_REQUEST:
            //   return{...state,updatingShopLogo:true}
            //   case types.UPDATE_SHOP_LOGO_SUCCESS:
            //     return{...state,
            //     image : state.imageId. === action.payload .imageId ? act
            //     }

             case types. HANDLE_ACCOUNT_SHOPDETAILS_MODAL:
              return { ...state, accountShopdetailsModal: action.payload };
            
          case types.HANDLE_MERCHANT_HISTORY_MODAL:
            return {...state,merchantHistoryModal:action.payload};
    default:
      return state;
  }
}