import * as types from "./TransactionActionTypes";


const initialState = {
 
  fetchingTransactionListByTransactionId: false,
  fetchingTransactionListByTransactionIdError: false,
  transactionListByTransactionId:[],

};

export const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.ADD_ORDER_DATEWISE_REQUEST:
    //   return {
    //     ...state,
    //     addingDatewiseorder: true,
    //     addingDatewiseorderError: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_SUCCESS:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: false,
    //     datewiseOrder: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_FAILURE:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: true,
    //     datewiseOrder: false,
    //   };

    
    case types.GET_TRANSACTION_LIST_BY_TRANSACTION_ID_REQUEST:
      return { ...state, fetchingTransactionListBytransactionId: true };
    case types.GET_TRANSACTION_LIST_BY_TRANSACTION_ID_SUCCESS:
      return {
        ...state,
        fetchingTransactionListByTransactionId: false,
        transactionListByTransactionId: action.payload,
      };
    case types.GET_TRANSACTION_LIST_BY_TRANSACTION_ID_FAILURE:
      return {
        ...state,
        fetchingTransactionListByTransactionId: false,
        fetchingTransactionListByTransactionIdError: true,
      };

      case types.GET_TRANSACTIONS_TABLE_REQUEST:
        return { ...state, fetchingTransactionsTable: true };
      case types.GET_TRANSACTIONS_TABLE_SUCCESS:
        return {
          ...state,
          fetchingTransactionsTable: false,
          TransactionsTable: action.payload,
        };
      case types.GET_TRANSACTIONS_TABLE_FAILURE:
        return {
          ...state,
          fetchingTransactionsTable: false,
          fetchingtransactionsTableError: true,
        };

    default:
      return state;
  }
};

