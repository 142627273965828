import React from "react";
import { MainWrapper } from "../../../Components/UI/Layout";
import { Formik, Form, FastField } from "formik";
import { InputComponent } from "../../../Components/Forms/Formik/InputComponent";
import { Button } from "antd";
import CameraImage from "../../../Assets/Images/camera.svg";
import { StyledLabel } from "../../../Components/UI/Elements";
import {linkProductImage} from "../ProductsAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

function ProductImageForm(props) {
  return (
    <>
      <Formik initialValues={{}} 

       onSubmit={(values, { resetForm }) => {
        props.linkProductImage(
            {
                ...values,
            },
        );
        resetForm();
    }}
      >
         {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                    values,
                    ...rest
                }) => (
        <Form>
          <MainWrapper>
            {/* <h4>Product images (up to 10)</h4> */}
            <div style={{ marginBottom: "1em" }}>
              <StyledLabel
                marginLeft="0"
                color="#000000"
                fontWeight="500"
                fontSize=" 1.5625em"
              >
                Product images (up to 10)
              </StyledLabel>
            </div>
            <img src={CameraImage} alt="Camera" />
          </MainWrapper>
          <MainWrapper>
            <div style={{ marginBottom: "1em" }}>
              <StyledLabel
                marginLeft="0"
                color="#000000"
                fontWeight="500"
                fontSize=" 1.5625em"
              >
                {" "}
                Add Inventory
              </StyledLabel>
            </div>

            <div style={{ width: "40%" }}>
              <FastField
                component={InputComponent}
                name="productName"
                label="Total available items"
                placeholder="Enter quantity"
                isColumn
              />
            </div>
          </MainWrapper>
          <MainWrapper>
            <div style={{ marginBottom: "1em" }}>
              <StyledLabel
                marginLeft="0"
                color="#000000"
                fontWeight="500"
                fontSize=" 1.5625em"
              >
                Add sizes
              </StyledLabel>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "40%" }}>
                <FastField
                  component={InputComponent}
                  name="size"
                  label="Size1"
                  placeholder="Enter size"
                  isColumn
                />
                <h5>
                  Size 1 will be first shown to the buyer as its the cheaper
                </h5>
              </div>
              <div style={{ width: "25%" }}>
                <FastField
                  component={InputComponent}
                  name="price"
                  label="Price"
                  placeholder="Col$ Price"
                  isColumn
                />
              </div>
              <div style={{ width: "25%" }}>
                <FastField
                  component={InputComponent}
                  name="discountedPrice"
                  label="Discounted Price"
                  placeholder="Col$ Enter value"
                  isColumn
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  background: " #FFFFFF",
                  border: "0.0625em solid #000000",
                  borderRadius: "0.125em",
                }}
              >
                Add another size
              </Button>
            </div>
          </MainWrapper>
          <MainWrapper>
            <div style={{ marginBottom: "1em" }}>
              <StyledLabel
                marginLeft="0"
                color="#000000"
                fontWeight="500"
                fontSize=" 1.5625em"
              >
                Add Colors
              </StyledLabel>
            </div>

            <div style={{ width: "40%" }}>
              <FastField
                component={InputComponent}
                name="size"
                label="Color1"
                placeholder="Choose color"
                isColumn
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  background: " #FFFFFF",
                  border: "0.0625em solid #000000",
                  borderRadius: "0.125em",
                }}
              >
                Add another size
              </Button>
            </div>
          </MainWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{
                background: "#3066BE",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "15px",
                color: "white",
              }}
              htmlType="submit"  
            >
              Add products
            </Button>
          </div>
        </Form>
                )}
      </Formik>
    </>
  );
}

const mapStateToProps = ({products }) => ({
  addingProductsImage:products.addingProductsImage
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
      {
        linkProductImage
      },
      dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductImageForm);

