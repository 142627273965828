import * as types from "./EmailSettingActionType";

const initialState = {

    addingEmailSettingInShopper: false,
    addingEmailSettingInShopperError: false,

    fetchingEmailData:false,
    fetchingEmailDataError:false,
    showEmail:[],

};

export const EmailSettingReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.ADD_EMAIL_SETTING_IN_SHOPPER_REQUEST:
            return { ...state, addingEmailSettingInShopper: true };
          case types.ADD_EMAIL_SETTING_IN_SHOPPER_SUCCESS:
            return { ...state, addingEmailSettingInShopper: false };
          case types.ADD_EMAIL_SETTING_IN_SHOPPER_FAILURE:
            return { ...state, addingEmailSettingInShopper: false, addingEmailSettingInShopperError: true };
         
            case types.GET_EMAIL_DATA_REQUEST:
              return { ...state, fetchingEmailData: true };
          case types.GET_EMAIL_DATA_SUCCESS:
              return { ...state, fetchingEmailData: false, showEmail: action.payload };
          case types.GET_EMAIL_DATA_FAILURE:
              return { ...state, fetchingEmailData: false, fetchingEmailDataError: true };               
    default:
        return state;
    }
  };