export const GET_SHOP_DETAILS_REQUEST="GET_SHOP_DETAILS_REQUEST";
export const GET_SHOP_DETAILS_SUCCESS="GET_SHOP_DETAILS_SUCCESS";
export const GET_SHOP_DETAILS_FAILURE="GET_SHOP_DETAILS_FAILURE";

export const UPDATE_SHOP_DETAILS_REQUEST ="UPDATE_SHOP_DETAILS_REQUEST";
export const UPDATE_SHOP_DETAILS_SUCCESS="UPDATE_SHOP_DETAILS_SUCCESS";
export const UPDATE_SHOP_DETAILS_FAILURE="UPDATE_SHOP_DETAILS_FAILURE";

export const HANDLE_ID_UPLOAD_MODAL="HANDLE_ID_UPLOAD_MODAL";
export const HANDLE_ADDRESS_UPLOAD_MODAL="HANDLE_ADDRESS_UPLOAD_MODAL";

export const ADD_ID_DOCUMENT_REQUEST="ADD_ID_DOCUMENT_REQUEST";
export const ADD_ID_DOCUMENT_SUCCESS="ADD_ID_DOCUMENT_SUCCESS";
export const ADD_ID_DOCUMENT_FAILURE="ADD_ID_DOCUMENT_FAILURE";

export const GET_ID_DOCUMENTS_REQUEST="GET_ID_DOCUMENTS_REQUEST";
export const GET_ID_DOCUMENTS_SUCCESS="GET_ID_DOCUMENTS_SUCCESS";
export const GET_ID_DOCUMENTS_FAILURE="GET_ID_DOCUMENTS_FAILURE";

export const ADD_ADDRESS_DOCUMENT_REQUEST="ADD_ADDRESS_DOCUMENT_REQUEST";
export const ADD_ADDRESS_DOCUMENT_SUCCESS="ADD_ADDRESS_DOCUMENT_SUCCESS";
export const ADD_ADDRESS_DOCUMENT_FAILURE="ADD_ADDRESS_DOCUMENT_FAILURE";

export const GET_ADDRESS_DOCUMENTS_REQUEST="GET_ADDRESS_DOCUMENTS_REQUEST";
export const GET_ADDRESS_DOCUMENTS_SUCCESS="GET_ADDRESS_DOCUMENTS_SUCCESS";
export const GET_ADDRESS_DOCUMENTS_FAILURE="GET_ADDRESS_DOCUMENTS_FAILURE";

export const UPDATE_SHOP_LOGO_REQUEST ="UPDATE_SHOP_LOGO_REQUEST";
export const UPDATE_SHOP_LOGO_SUCCESS ="UPDATE_SHOP_LOGO_SUCCESS";
export const UPDATE_SHOP_LOGO_FAILURE ="UPDATE_SHOP_LOGO_FAILURE";

export const HANDLE_ACCOUNT_SHOPDETAILS_MODAL="HANDLE_ACCOUNT_SHOPDETAILS_MODAL";
export const HANDLE_MERCHANT_HISTORY_MODAL="HANDLE_MERCHANT_HISTORY_MODAL";