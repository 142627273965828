import styled from "styled-components";

const FormWrapper = styled.div`
    padding: 1rem;
width: ${props => props.width}
   
    @media only screen and (max-width: 600px) {
    
       width:89%
      
           
         }
         @media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2){
 
}
         
`
export default FormWrapper;