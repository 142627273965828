export const ADD_ORDER_DATEWISE_REQUEST = "ADD_ORDER_DATEWISE_REQUEST";
export const ADD_ORDER_DATEWISE_SUCCESS = "ADD_ORDER_DATEWISE_SUCCESS";
export const ADD_ORDER_DATEWISE_FAILURE = "ADD_ORDER_DATEWISE_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const GET_LIST_PRODUCT_REQUEST="GET_LIST_PRODUCT_REQUEST";
export const GET_LIST_PRODUCT_SUCCESS="GET_LIST_PRODUCT_SUCCESS";
export const GET_LIST_PRODUCT_FAILURE="GET_LIST_PRODUCT_FAILURE";

export const SET_GOODS_VIEW_TYPE="SET_GOODS_VIEW_TYPE";

export const GET_ALL_RECORDS_REQUEST = "GET_ALL_RECORDS_REQUEST";
export const GET_ALL_RECORDS_SUCCESS = "GET_ALL_RECORDS_SUCCESS";
export const GET_ALL_RECORDS_FAILURE = "GET_ALL_RECORDS_FAILURE";

export const GET_DATE_WISE_PRODUCT_REQUEST="GET_DATE_WISE_PRODUCT_REQUEST";
export const GET_DATE_WISE_PRODUCT_SUCCESS="GET_DATE_WISE_PRODUCT_SUCCESS";
export const GET_DATE_WISE_PRODUCT_FAILURE="GET_DATE_WISE_PRODUCT_FAILURE";

export const HANDLE_GOODS_PRODUCT_MODAL="HANDLE_GOODS_PRODUCT_MODAL";
export const SET_EDIT_ADMIN_GOODS="SET_EDIT_ADMIN_GOODS";