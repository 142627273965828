import * as types from "./ProductsActionType";
import moment from "moment";

const initialState = {
  viewType: "products",

  addingProductsInformation: false,
  addingProductsInformationError: false,

  addingProductsImage: false,
  addingProductsImageError: false,

  fetchingRecordsByUserId: false,
  fetchingRecordsByUserIdError: false,
  recordData: {},

  fetchingProductsCategory: false,
  fetchingProductsCategoryError: false,
  productsCategory: [],

  fetchingProductsList: false,
  fetchingProductsListError: false,
  products: [],
  //     {
  //       name:"Fresh Apple",
  //     cost:'$622.93',
  //   image:"https://5.imimg.com/data5/AK/RA/MY-68428614/apple-500x500.jpg"
  // },
  // {
  //   name:"Fresh Orange",
  // cost:'$132.07',
  // image:"https://static8.depositphotos.com/1020804/884/i/600/depositphotos_8840885-stock-photo-orange-fruits-on-a-white.jpg"
  // },
  // {
  //   name:"Sweet Gauva",
  // cost:'$222.37',
  // image:"https://post.healthline.com/wp-content/uploads/2020/09/benefits-of-guavas-732x549-thumbnail-732x549.jpg"
  // },
  // {
  // name:"Jeera Rice",
  // cost:'$41.07',
  // image:"https://media.newyorker.com/photos/5f2c85539a557880d973a759/2:2/w_1592,h_1592,c_limit/Buford-FrenchRice.jpg"
  // },
  // {
  //   name:"Dal",
  // cost:'$167.57',
  // image:"https://www.deccanherald.com/sites/dh/files/styles/article_detail/public/articleimages/2021/08/17/file7azd6lft42t15fdbyfqo-848818-1629184004.jpg?itok=f-X9ASgB"
  // },
  // {
  // name:"Tamato",
  // cost:'$35.89',
  // image:"https://post.healthline.com/wp-content/uploads/2020/09/tomatoes-1200x628-facebook-1200x628.jpg"
  // },

  puttingProductInStock: false,
  puttingProductInStockError: false,
  instockPut: [],

  setEditingProducts:{},

  updatingProducts: false,
  updatingProductsError: false,
  productsUpdate:[],

  fetchingProductInputSearchData:false,
  fetchingProductInputSearchDataError:false,
  productByMerchantId:[],

  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

 type: "All",
  dateRangeList: [
    {
      id: 8,
      type: "All",
      value: "All",
      starter: true,
      isSelected: true,
      startDate: moment().toISOString(),

      endDate: moment().toISOString(),
    },

    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected: false,
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
    },
    // {
    //   id: 2,
    //   type: "Yesterday",
    //   value: "Yesterday",
    //   starter: false,
    //   isSelected: false,
    //   startDate: moment()
    //     .subtract(1, "days")

    //     .toISOString(),
    //   endDate: moment().toISOString(),
    // },
    {
      id: 3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
      .subtract(7, "days").toISOString(),
      endDate: moment()


        .toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(30, "days").toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").toISOString(),
      endDate: moment.utc().toISOString(),
    },
    {
      id: 6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").subtract(1,"month").toISOString(),
      endDate: moment.utc().endOf('month').subtract(1,"month").toISOString(),
    }
  ],

  fetchingDatewiseReport: false,
  fetchingDatewiseReportError: false,
  dateProductsReport: [],

  reportType: [
    "products",
  ],
  selectedReportType: "products",
  selectedSubReportType: "products",

  fetchingSizeOfProducts: false,
  fetchingSizeOfProductsError: false,
  showSize:[],
  addingSizeOfProducts: false,
  addingSizeOfProductsError: false,
  updatingSizeOfProduct: false,
  updatingSizeOfProductError: false,
  deleteSizeofProduct: false, deleteSizeofProductError: false,

  fetchingColorOfProducts: false,
  fetchingColorOfProductsError: false,
  showColor:[],
  addingColorOfProducts: false,
  addingColorOfProductsError: false,
  updatingColorOfProduct: false,
  updatingColorOfProductError: false,
  deleteColorofProduct: false, deleteColorofProductError: false
};

export const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PRODUCTS_VIEW_TYPE:
      return { ...state, viewType: action.payload };

      case types.SET_PRODUCT_EDIT:
        return { ...state, setEditingProducts: action.payload };   

    case types.LINK_PRODUCTS_INFORMATION_REQUEST:
      return { ...state, addingProductsInformation: true };
    case types.LINK_PRODUCTS_INFORMATION_SUCCESS:
      return {
        ...state,
        addingProductsInformation: false,
        // products: action.payload,
        products: state.products.filter(
          (item) => item.instockInd === action.payload
        ),
      };
    case types.LINK_PRODUCTS_INFORMATION_FAILURE:
      return {
        ...state,
        addingProductsInformation: false,
        addingProductsInformationError: true,
      };

    case types.LINK_PRODUCTS_IMAGE_REQUEST:
      return { ...state, addingProductsImage: true };
    case types.LINK_PRODUCTS_IMAGE_SUCCESS:
      return {
        ...state,
        addingProductsImage: false,
      };
    case types.LINK_PRODUCTS_IMAGE_FAILURE:
      return {
        ...state,
        addingProductsImage: false,
        addingProductsImageError: true,
      };

    case types.GET_PRODUCT_LIST_REQUEST:
      return { ...state, fetchingProductsList: true };
    case types.GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        fetchingProductsList: false,
        products: action.payload,
      };
    case types.GET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        fetchingProductsList: false,
        fetchingProductsListError: true,
      };

    case types.GET_RECORDS_REQUEST:
      return { ...state, fetchingRecordsByUserId: true };
    case types.GET_RECORDS_SUCCESS:
      return {
        ...state,
        fetchingRecordsByUserId: false,
        recordData: action.payload,
      };
    case types.GET_RECORDS_FAILURE:
      return {
        ...state,
        fetchingRecordsByUserId: false,
        fetchingRecordsByUserIdError: true,
      };

    case types.GET_PRODUCT_CATEGORY_REQUEST:
      return { ...state, fetchingProductsCategory: true };
    case types.GET_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        fetchingProductsCategory: false,
        productsCategory: action.payload,
      };
    case types.GET_PRODUCT_CATEGORY_FAILURE:
      return {
        ...state,
        fetchingProductsCategory: false,
        fetchingProductsCategoryError: true,
      };

    case types.PUT_IN_STOCK_PRODUCTS_REQUEST:
      return {
        ...state,
        puttingProductInStock: true,
      };
    case types.PUT_IN_STOCK_PRODUCTS_SUCCESS:
      return {
        ...state,
        puttingProductInStock: false,
        products:state.products.map((item)=>{
          if(item.productId === action.payload.productId){
            return {...item,instockInd:action.payload.instockInd}
          }
          else{
            return item
          }

        })
      };
    case types.PUT_IN_STOCK_PRODUCTS_FAILURE:
      return {
        ...state,
        puttingProductInStock: false,
        puttingProductInStockError: true,
      };


  case types.UPDATE_PRODUCTS_REQUEST:
      return { ...state, updatingProducts: true };
    case types.UPDATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        updatingProducts: false,
        productsUpdate: state.productsUpdate.map((item) => {
          if (item.mrchantDetailsId == action.payload.mrchantDetailsId) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case types.UPDATE_PRODUCTS_FAILURE:
      return {
        ...state,
        updatingProducts: false,
        updatingProductsError: true,
      };
 //SEARCH
 case types.INPUT_PRODUCT_SEARCH_DATA_REQUSET:
  return { ...state, fetchingProductInputSearchData: true };
case types.INPUT_PRODUCT_SEARCH_DATA_SUCCESS:
  return {
    ...state,
    fetchingProductInputSearchData: false,
    productByMerchantId: action.payload,
  };
case types.INPUT_PRODUCT_SEARCH_DATA_FAILURE:
  return { ...state, fetchingProductInputSearchDataError: true };

  case types.SET_SELECTED_REPORT_TYPE:
    return {
      ...state,
      selectedReportType: action.payload,
      // selectedSubReportType: "order",
    };
  case types.SET_SUB_SELECTED_REPORT_TYPE:
    return {
      ...state,
      selectedSubReportType: action.payload,
    };
   
    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        type: action.payload.type

      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,

      };

case types.GET_DATE_WISE_REPORT_REQUEST:
      return { ...state, fetchingDatewiseReport: true };
    case types.GET_DATE_WISE_REPORT_SUCCESS:
      return {
        ...state,
        fetchingDatewiseReport: false,
        fetchingDatewiseReportError: false,
        products: action.payload,
      };
    case types.GET_DATE_WISE_REPORT_FAILURE:
      return {
        ...state,
        fetchingDatewiseReport: false,
        fetchingDatewiseReportError: true,
      };
///////SIZE///////
case types.GET_SIZE_OF_PRODUCT_REQUEST:
      return { ...state, fetchingSizeOfProducts: true };
    case types.GET_SIZE_OF_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchingSizeOfProducts: false,
        showSize: action.payload,
      };
    case types.GET_SIZE_OF_PRODUCT_FAILURE:
      return {
        ...state,
        fetchingSizeOfProducts: false,
        fetchingSizeOfProductsError: true,
      };
      case types.ADD_SIZE_OF_PRODUCTS_REQUEST:
      return { ...state, addingSizeOfProducts: true };
    case types.ADD_SIZE_OF_PRODUCTS_SUCCESS:
      return {
        ...state,
        addingSizeOfProducts: false,
        showSize:action.payload,
      };
    case types.ADD_SIZE_OF_PRODUCTS_FAILURE:
      return {
        ...state,
        addingSizeOfProducts: false,
        addingSizeOfProductsError: true,
      };
      case types.UPDATE_SIZE_OF_PRODUCTS_REQUEST:
      return { ...state, updatingSizeOfProduct: true };
    case types.UPDATE_SIZE_OF_PRODUCTS_SUCCESS:
      return {
        ...state,
        updatingSizeOfProduct: false,
       showSize:action.payload,
      };
      case types.UPDATE_SIZE_OF_PRODUCTS_FAILURE:
        return {
          ...state,
          updatingSizeOfProduct: false,
          updatingSizeOfProductError: true,
        };
        case types.DELETE_SIZE_OF_PRODUCT_REQUEST:
          return { ...state, deleteSizeofProduct: true };
        case types.DELETE_SIZE_OF_PRODUCT_SUCCESS:
          return {
            ...state,
            deleteSizeofProduct: false,
            showSize:action.payload,
          };
        case types.DELETE_SIZE_OF_PRODUCT_FAILURE:
          return { ...state, deleteSizeofProduct: false, deleteSizeofProductError: false };
  ///////COLOR//////////
  case types.GET_COLOR_OF_PRODUCT_REQUEST:
      return { ...state, fetchingColorOfProducts: true };
    case types.GET_COLOR_OF_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchingColorOfProducts: false,
        showColor: action.payload,
      };
    case types.GET_COLOR_OF_PRODUCT_FAILURE:
      return {
        ...state,
        fetchingColorOfProducts: false,
        fetchingColorOfProductsError: true,
      };
          case types.ADD_COLOR_OF_PRODUCTS_REQUEST:
            return { ...state, addingColorOfProducts: true };
          case types.ADD_COLOR_OF_PRODUCTS_SUCCESS:
            return {
              ...state,
              addingColorOfProducts: false,
              showColor:action.payload,
            };
          case types.ADD_COLOR_OF_PRODUCTS_FAILURE:
            return {
              ...state,
              addingColorOfProducts: false,
              addingColorOfProductsError: true,
            };
        case types.UPDATE_COLOR_OF_PRODUCTS_REQUEST:
          return { ...state, updatingColorOfProduct: true };
        case types.UPDATE_COLOR_OF_PRODUCTS_SUCCESS:
          return {
            ...state,
            updatingColorOfProduct: false,
           showColor:action.payload,
          };
          case types.UPDATE_COLOR_OF_PRODUCTS_FAILURE:
            return {
              ...state,
              updatingColorOfProduct: false,
              updatingColorOfProductError: true,
            };
            case types.DELETE_COLOR_OF_PRODUCT_REQUEST:
              return { ...state, deleteColorofProduct: true };
            case types.DELETE_COLOR_OF_PRODUCT_SUCCESS:
              return {
                ...state,
                deleteColorofProduct: false,
                showColor:action.payload,
              };
            case types.DELETE_COLOR_OF_PRODUCT_FAILURE:
              return { ...state, deleteColorofProduct: false, deleteColorofProductError: false };

    default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
  
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });