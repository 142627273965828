export const ADD_ORDER_DATEWISE_REQUEST = "ADD_ORDER_DATEWISE_REQUEST";
export const ADD_ORDER_DATEWISE_SUCCESS = "ADD_ORDER_DATEWISE_SUCCESS";
export const ADD_ORDER_DATEWISE_FAILURE = "ADD_ORDER_DATEWISE_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const GET_ORDER_LIST_BY_ORDER_ID_REQUEST="GET_ORDER_LIST_BY_ORDER_ID_REQUEST";
export const GET_ORDER_LIST_BY_ORDER_ID_SUCCESS="GET_ORDER_LIST_BY_ORDER_ID_SUCCESS";
export const GET_ORDER_LIST_BY_ORDER_ID_FAILURE="GET_ORDER_LIST_BY_ORDER_ID_FAILURE";

export const SET_ORDER_VIEW_TYPE="SET_ORDER_VIEW_TYPE";

export const GET_DATE_WISE_REPORT_REQUEST = "GET_DATE_WISE_REPORT_REQUEST";
export const GET_DATE_WISE_REPORT_SUCCESS = "GET_DATE_WISE_REPORT_SUCCESS";
export const GET_DATE_WISE_REPORT_FAILURE = "GET_DATE_WISE_REPORT_FAILURE";

export const SET_SELECTED_REPORT_TYPE = "SET_SELECTED_REPORT_TYPE";

export const SET_SUB_SELECTED_REPORT_TYPE = "SET_SUB_SELECTED_REPORT_TYPE";









