import React, { Component } from "react";
import { Button, Tooltip } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Field } from "formik";
import { FlexContainer } from "../../UI/Layout";
import { Spacer, StyledLabel } from "../../UI/Elements";
import FormikPlacesAutoComplete from "./FormikPlacesAutoComplete";
import { InputComponent } from "../Formik/InputComponent";
import { SelectComponent } from "../Formik/SelectComponent";
import styled from "styled-components";
class AddressFieldArray extends Component {
  render() {
    
    const { arrayHelpers, values, singleAddress } = this.props;
   
    return (
      <div>
        {values &&
          values.address.map((address, index) => (
            <div>
              <div
                key={index}
                style={{ display: "flex", width: "100%", marginTop: "0.94em" }}
              >
                <EnvironmentOutlined
                  // type="environment"
                  style={{
                    marginTop: "0.68em",
                    fontSize: "1.12em",
                    marginRight: "0.68em",
                  }}
                />
                <Field
                isRequired
                  name={`address[${index}]`}
                  component={FormikPlacesAutoComplete}
                  isColumn
                  options={{}}
                  style={{ height: "2em", marginTop: "0.25em" }}
                />
                &nbsp;
              </div>

              <span>
                <Spacer style={{ margin: 0 }} />
                <p
                  style={{
                    fontWeight: "bold",
                    marginBottom: "0.25em",
                    fontStyle: "italic",
                    color: "#1890ff",
                    margin: 0,
                  }}
                >
                  Address input is only allowed using Location feature
                </p>
                <Spacer style={{ marginBottom: "0.94em" }} />

                <FlexContainer justifyContent="space-between">
                  <InputContainer1>
                    <Tooltip title="Use Location feature for easy search ">
                      <Field
                      
                        name={`address.${index}.address1`}
                        label="Address*"
                        isColumn
                        component={InputComponent}
                        width={"100%"}
                        inlineLabel
                        style={{ flexBasis: "80%" }}
                      />
                    </Tooltip>
                  </InputContainer1>
                  <InputContainer1>
                    <Tooltip title="Use Location feature for easy search ">
                      <Field
                        name={`address.${index}.state`}
                        label="State/Province*"
                        component={InputComponent}
                        width={"100%"}
                        isColumn
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                        }}
                      />
                    </Tooltip>
                  </InputContainer1>
                </FlexContainer>
                <Spacer />

                <FlexContainer justifyContent="space-between">
                  <InputContainer1>
                    <Tooltip title="Use Location feature for easy search ">
                      <Field
                        name={`address.${index}.city`}
                        label="City*"
                        component={InputComponent}
                        width={"100%"}
                        isColumn
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                        }}
                      />
                    </Tooltip>
                  </InputContainer1>
                  <InputContainer1>
                    <Tooltip title="Use Location feature for easy search ">
                      <Field
                        name={`address.${index}.pinCode`}
                        label="Zip code"
                        // disabled
                        component={InputComponent}
                        isColumn
                        width={"100%"}
                        inlineLabel
                        style={{
                          flexBasis: "80%",
                        }}
                      />
                    </Tooltip>
                  </InputContainer1>
                </FlexContainer>
              </span>
            </div>
          ))}
      </div>
    );
  }
}

export default AddressFieldArray;
const InputContainer1 = styled.div`
  width: 47%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
