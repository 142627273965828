import React, { useMemo } from "react";
import { ViewEditCard, StyledLabel } from "../../Components/UI/Elements";
import styled from "styled-components";
import { FlexContainer } from "../../Components/UI/Layout";

function ProductsProfileView(props) {
  const ProductID = useMemo(() => {
    return props.product.map((item) => item.productId);
  }, [props.product]);

  // const item = "Product Information";

  return (
    <>
      {/* <h1
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "25px",
          color: "#000000",
          width: "214px";
height: 38px;


font-family: 'Poppins';
font-style: normal;
font-weight: 500;





        }}
      > */}
      <StyledLabel
        fontSize=" 1.5625em"
      >
        Quick Navigation
      </StyledLabel>
      {/* </h1> */}
      {props.product.map((item, i) => {
        return (
          <StageWrapper>
            <ViewEditCard>
              {({ viewType }, toggleViewType) =>
                viewType === "view" ? (
                  <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    // onClick={() => props.handleProductClick(item)}
                    onClick={() => props.handleProductClick(item)}
                    style={{
                      backgroundColor:
                        props.current &&
                        props.current.productId === item.productId &&
                        "#FFFFFF",

                      // color: "#3066BE",
                      // color:props.current && props.current.productId === item.productId && "#3066BE"
                    }}
                  >
                    <StageName
                      style={{
                        color:
                          props.current.productId === item.productId &&
                          " #3066BE",
                        cursor: "pointer",
                      }}
                    >
                      {item.productsName}
                    </StageName>
                  </FlexContainer>
                ) : null
              }
            </ViewEditCard>
          </StageWrapper>
        );
      })}
    </>
  );
}

export default ProductsProfileView;
const StageWrapper = styled.div`
  width: 90%;
  height: auto;
  cursor: pointer;
`;
const StageName = styled.h3`
  color: ${(props) => props.theme.color || "teal"};
  font-weight: 400;
  flex-basis: 80%;
  // margin-bottom: 0;
  margin: 0;
`;
