export const ADD_DELIVERY_CHARGES_REQUEST = "ADD_DELIVERY_CHARGES_REQUEST";
export const ADD_DELIVERY_CHARGES_SUCCESS = "ADD_DELIVERY_CHARGES_SUCCESS";
export const ADD_DELIVERY_CHARGES_FAILURE = "ADD_DELIVERY_CHARGES_FAILURE";

export const GET_CURRENCY_REQUEST = "GET_CURRENCY_REQUEST";
export const GET_CURRENCY_SUCCESS = "GET_CURRENCY_SUCCESS";
export const GET_CURRENCY_FAILURE = "GET_CURRENCY_FAILURE";

export const GET_DELIVERY_CHARGES_REQUEST = "GET_DELIVERY_CHARGES_REQUEST";
export const GET_DELIVERY_CHARGES_SUCCESS = "GET_DELIVERY_CHARGES_SUCCESS";
export const GET_DELIVERY_CHARGES_FAILURE = "GET_DELIVERY_CHARGES_FAILURE";

export const HANDLE_DELIVERY_CHARGE_MODAL="HANDLE_DELIVERY_CHARGE_MODAL";
