import * as types from "./NewsLetterActionTypes";


const initialState = {

  fetchingnewsLetterTable: false,
  fetchingnewsLetterTableError:false,
  news:[],

};
export const newsLetterReducer = (state = initialState, action) => {
  switch (action.type) { 
    
   
//GET NEWS LETTER 

      case types.GET_NEWS_LETTER_TABLE_REQUEST:
        return { ...state, fetchingnewsLetterTable: true };
      case types.GET_NEWS_LETTER_TABLE_SUCCESS:
        return {
          ...state,
          fetchingnewsLetterTable: false,
          news: action.payload,
        };
      case types.GET_NEWS_LETTER_TABLE_FAILURE:
        return {
          ...state,
          fetchingnewsLetterTable: false,
          fetchingnewsLetterTableError: true,
        };

   
        default:
          return state;
      }
    };
