import { Button } from 'antd'
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FlexContainer, MainWrapper } from '../../../../Components/UI/Layout'
import CartMainContentForm from './CartMainContentForm'
import CartMainContentRight from './CartMainContentRight'

class CartMainContent extends Component {
    render() {
        // const cartData=this.props.cart.storeCart;
        // const cartSummaryData= cartData && cartData.shippingAddress;
        // // const cartShippingData=cartSummaryData&&cartSummaryData.shippingAddress;
      
        return (
            <>
                <FlexContainer style={{ justifyContent: "center" }}>
                    <h1 class=" max-sm: mt-5 text-xl font-normal underline  md:text-3xl "> 
                    
                            Fill Delivery Information
                   
                    </h1>
                    <span style={{}}>
                        {/* <Button >
                            Already a user? Sign in
                        </Button> */}
                    </span>
                </FlexContainer>
                {/* <br/>
                <br/> */}
                <FlexContainer
                    style={{ justifyContent: "center" }}
                // marginTop="-44px"
                >
                    <MainWrapper
                        background="#FFFFFF"
                        boxShadow="4px 4px 4px rgba(163, 171, 185, 0.5)"
                        borderRadius="20px"
                        width="50rem"
                        border="none"
                    >

                        <CartMainContentForm />
                    </MainWrapper>
                    {/* <MainWrapper
                        background="#FFFFFF"
                        boxShadow="4px 4px 4px rgba(163, 171, 185, 0.5)"
                        borderRadius="20px"
                        width="20%"
                        marginLeft="30px"
                        border="none"
                    >
                        <CartMainContentRight />
                    </MainWrapper> */}
                </FlexContainer>
            </>
        )
    }


}
const mapStateToProps = ({ customer, auth }) => ({
    cart: customer.cart,
    contactId: customer.productInfo.contactId,
    shopName: customer.shopName

    // linkingProductInfo: customer.linkingProductInfo,
    // userId: auth.userDetails.userId,
    // productId:products.products.productId
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            //   getCartProductList,

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CartMainContent);
