import * as types from "./AuthTypes";
const initialState = {
  env: "server",
  logging: false,
  loginError: false,

  fetchingCountries: false,
  fetchingCountriesError: false,
  countries: [],

  fetchingUserDetails: false,
  fetchingUserDetailsError: null,
  userDetails: JSON.parse(sessionStorage.getItem("userDetails")) || {},
  
  doSigning: false,
  doSigningError: false,

  verifyingEmail: false,
  verifyingEmailError: false,

  validatingOtp: false,
  validatingOtpError: false,
  validOtp:"",

  doResetpassword: false,
  doResetpasswordError: false,

  fetchingBussinessCategory: false,
  fetchingBussinessCategoryError: false,
  bussinessCategory:[],
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case types.LOGIN_REQUEST:
      return { ...state, logging: true };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        logging: false,
      };
    case types.LOGIN_FAILURE:
      return { ...state, logging: false, loginError: true };

    case types.GET_USER_DETAILS_REQUEST:
      return { ...state, fetchingUserDetails: true };
    case types.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchingUserDetails: false,
        userDetails:
          action.payload || JSON.parse(sessionStorage.getItem("userDetails")),
      };
    case types.GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        fetchingUserDetails: false,
        fetchingUserDetailsError: true,
      };

      case types.GET_COUNTRIES_REQUEST:
        return { ...state, fetchingCountries: true };
      case types.GET_COUNTRIES_SUCCESS:
        return { ...state, fetchingCountries: false, countries: action.payload };
      case types.GET_COUNTRIES_FAILURE:
        return {
          ...state,
          fetchingCountries: false,
          fetchingCountriesError: true,
        };

        case types.DO_SIGN_UP_REQUEST:
          return { ...state, doSigning: true };
        case types.DO_SIGN_UP_SUCCESS:
          return {
            ...state,
            doSigning: false,
          };
        case types.DO_SIGN_UP_FAILURE:
          return {
            ...state,
            doSigning: false,
            doSigningError: true,
          };  

          case types.VERIFY_EMAIL_REQUEST:
            return { ...state, verifyingEmail: true };
          case types.VERIFY_EMAIL_SUCCESS:
            return {
              ...state,
              verifyingEmail: false,
            };
          case types.VERIFY_EMAIL_FAILURE:
            return {
              ...state,
              verifyingEmail: false,
              verifyingEmailError: true,
            };  

            case types.VALIDATE_OTP_REQUEST:
              return { ...state, validatingOtp: true };
            case types.VALIDATE_OTP_SUCCESS:
              return {
                ...state,
                validatingOtp: false,
                validOtp:action.payload
              };
            case types.VALIDATE_OTP_FAILURE:
              return {
                ...state,
                validatingOtp: false,
                validatingOtpError: true,
              };  

              case types.CHANGE_PASSWORD_REQUEST:
                return { ...state, changingPassword: true, changingPasswordError: false };
              case types.CHANGE_PASSWORD_SUCCESS:
                return {
                  ...state,
                  changingPassword: false,
                  changingPasswordError: false,
                };
              case types.CHANGE_PASSWORD_FAILURE:
                return { ...state, changingPassword: false, changingPasswordError: true };

                case types.FORGOT_PASSWORD_REQUEST:
                  return { ...state, doResetpassword: true };
                case types.FORGOT_PASSWORD_SUCCESS:
                  return {
                    ...state,
                    doResetpassword: false,
                    doResetpasswordError: false,
                    };
                case types.FORGOT_PASSWORD_FAILURE:

                  case types.GET_BUSSINESS_CATEGORY_REQUEST:
                    return { ...state, fetchingBussinessCategory: true };
                  case types.GET_BUSSINESS_CATEGORY_SUCCESS:
                    return { ...state, fetchingBussinessCategory: false, bussinessCategory: action.payload };
                  case types.GET_BUSSINESS_CATEGORY_FAILURE:
                    return {
                      ...state,
                      fetchingBussinessCategory: false,
                      fetchingBussinessCategoryError: true,
                    };               
    default:
      return state;
  }
};
