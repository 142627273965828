import React, { Component, useEffect,useForm, useState } from "react";
import { FlexContainer, MainWrapper } from "../../../Components/UI/Layout";
import { Formik, Form, Field,FastField } from "formik";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { InputComponent } from "../../../Components/Forms/Formik/InputComponent";
import { StyledLabel } from "../../../Components/UI/Elements";
import Upload from "../../../Components/Forms/Formik/Upload";
import { Button, Switch, Select } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { linkProductsInformation, getProductCategory, } from "../ProductsAction";
import { getCurrency,handleDeliveryChargeModal } from "../../Manage/ManageAction";
import CameraImage from "../../../Assets/Images/camera.svg";
import { Spacer } from "../../../Components/UI/Elements";
import SearchSelect from "../../../Components/Forms/Formik/SearchSelect";
import { SelectComponent } from "../../../Components/Forms/Formik/SelectComponent";
import "../../Products/Products.scss";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { getUserDetails } from "../../Auth/AuthAction";
import AddColorRow from "./AddColorRow";
import AddSizeRow from "./AddSizeRow";
import { Link } from "react-router-dom";
import { TextareaComponent } from "../../../Components/Forms/Formik/TextareaComponent";
import SetDeliveryChargeModal from "../../Manage/SetDeliveryChargeModal";
const { Option } = Select;
const ProductInfoSchema = Yup.object().shape({
  name: Yup.string().required("Required"),

  price: Yup.number().positive().required("Required"),
  discountedPrice: Yup.number().positive()
     .when(['price'], (price, schema) => {
          return price > 0 ? schema.max(price) : schema.max(0);
      }),
  // units: Yup.string().required("Required"),
  // imageId: Yup.string().required("Please upload image"),

  productUnit: Yup.mixed().required("Required"),

  productWeight: Yup.string().required("Required"),

  category: Yup.string().required("Required"),
});

const toolbarOption = {
  options: [
    "inline",
    // "fontSize",
    // "fontFamily",
    "list",
    // "textAlign",
    // "colorPicker",
    // "link",
  ],
  // options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline"],
  },
};

function ProductInformationForm(props) {
  //add color code

  const [rows, setRows] = useState([{ colour: "", colourId: "" }]);
  const [colourId, setcolourId] = useState(1);
 const [selectCategory,setSelectCategory]=useState("")

  function handleChangeName(e) {
    e.persist();
    setRows((v) => {
      return v.map((d) => {
        if (`${d.colourId}_name` === e.target.name) {
          return { ...d, colour: e.target.value };
        } else {
          return d;
        }
      });
    });
  }
  function handleAddRowClick() {
    setcolourId((v) => v + 1);
    setRows((v) => [...v, { colour: "", colourId: colourId + 1 }]);
  }

  function handleDelete(row) {
    setRows((v) => v.filter((d) => d.colourId !== row.colourId));
  }

  // add size code

  const [size, setSize] = useState([{ productSize: "", value: "", sizeId: 1 }]);
  const [sizeId, setSizeId] = useState(1);

  function handleChangeSize(e) {
    e.persist();
    setSize((v) => {
      return v.map((d) => {
        if (`${d.sizeId}_name` === e.target.name) {
          return { ...d, productSize: e.target.value };
        } else {
          return d;
        }
      });
    });
  }

  function handleDeleteSize(item) {
    setSize((v) => v.filter((d) => d.sizeId !== item.sizeId));
  }

  function handleAddSizeClick() {
    setSizeId((v) => v + 1);
    setSize((v) => [...v, { productSize: "", value: "", sizeId: sizeId + 1 }]);
  }

  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const [edit, setedit] = useState(true);
  const [stock, setstock] = useState(true);
  const [consult, setConsult] = useState(true);

  function handleStock(checked) {
    setstock(checked);
  }
  let history = useHistory();

  function handleClick() {
    history.push("/products");
  }
  function onEditorStateChange(editorState) {
  
    seteditorState(editorState);
    setedit(false);
  }
  function handleChangeCategory(selectCategory) {
    setSelectCategory(selectCategory);
  console.log("333",selectCategory)
  }
  function handleConsult(checked) {
    setConsult(checked);
  }

  useEffect(() => {
    props.getUserDetails(props.userId);
    props.getProductCategory();
  }, []);
  const {disabled}=props;

  const categoryList=props.productsCategory.map((item)=> {
    return {label:item.categoryName,
            value:item.categoryId,
    }
  })

  return (
    <>
      <Formik
        initialValues={{
          merchantId: props.merchantId,

          description: "",
          discountedPrice: "",
          instockInd: stock ? "true" : "false",
          // consult: selectCategory==="CDG35610853028212023" && consult ? "true" : "false",
          name: "",
          price: "",
          productColour: "",
          productFullName: "",
          productSize: "",
          units: "",
          userId: props.userId,
          currencyId: props.currencyId,
          currencyName: props.currencyName,
          productWeight: "",
          productUnit: "",
          category: "",
          imageId:"",
        }}
        validationSchema={selectCategory==="CDG35610853028212023" ?null:ProductInfoSchema}
        onSubmit={(values, { resetForm }) => {
          const htmlBody = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );
         
          props.linkProductsInformation(
            {
              ...values,
              colours: rows,
              sizes: size,
              instockInd: stock ? "true" : "false",
              // consult: selectCategory==="CDG35610853028212023" && consult ? "true" : "false",
              currencyId: props.currencyId,
            },
            handleClick
          );

          resetForm();
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          ...rest
        }) => (
          <Form>
            <FlexContainer justifyContent="center">
              <div>
                <Button
                  className="ajktn"
                  loading={props.addingProductsInformation}
                  htmlType="submit"
                >
                  <label class="text-white font-bold">
                  Add
                  </label>
                </Button>
              </div>
            </FlexContainer>
            <MainWrapper width="90%">
              <div>
                <StyledLabel>
                  <h1>Product Information</h1>
                </StyledLabel>
              </div>
              <Spacer />
              <div style={{ width: "100%" }}>
                <FastField
                  component={InputComponent}
                  name="name"
                  label="Product Name*"
                  placeholder="Enter product name"
                  isColumn
                  isRequired
                />
              </div>

              <Spacer marginTop="2%" />
              <FlexContainer width="95%" justifyContent="space-between">
                <div style={{ width: "30%" }}>
                {/* <StyledLabel>Product category*</StyledLabel> 
                  <Select
                    name="category"
                    // label="Product category*"
                    placeholder="Enter product category"
                    // selectType="categoryName"
                    isColumn
                    style={{ width: "100%" }}
                    onChange={handleChangeCategory}
                  >
                    {categoryList.map((item,i)=>{ return(
<Option value= {item.value}>{item.label}</Option>
                    )})}
                    </Select> */}
                    <Field
                    name="category"
                    label="Product category*"
                    placeholder="Enter product category"
                    selectType="categoryName"
                    value={values.categoryId}
                    isColumn
                    component={SearchSelect}
                    isColumnWithoutNoCreate
                  />
                </div>
                  
                <div style={{ width: "30%" }}>
                  <FastField
                    component={SelectComponent}
                    name="productUnit"
                    label="Product Unit*"
                    placeholder="Units"
                    options={[
                      "piece",
                      "kg",
                      "gm",
                      "ml",
                      "litre",
                      "meter",
                      "sq.ft",
                      "sq.meter",
                      "hour",
                      "day",
                      "month",
                      "year",
                      "service",
                      "pound",
                      "gallon"
                    ]}
                    isColumn
                    style={{width:"99%"}}
                  />
                </div>
                <div style={{ width: "24%" }}>
                {selectCategory==="CDG35610853028212023" ? ( 
                <div>
                <StyledLabel>Show Calender</StyledLabel>
                       <Switch
                         checked={consult}
                         onChange={handleConsult}
                         checkedChildren="Yes"
                         unCheckedChildren="No"
                       ></Switch> </div>) : ( 
                  <Field
                  isRequired
                    component={InputComponent}
                    name="productWeight"
                    label="Weight (per Unit)*"
                    placeholder="Enter Weight (in Kg)"
                    isColumn
                    
                  />)}
                </div>
              </FlexContainer>

              <Spacer marginTop="2%" />
              <FlexContainer width="95%" justifyContent="space-between">
                <div style={{ width: "30%" }}>
                  <FastField
                    component={InputComponent}
                    name="price"
                    label="Price*"
                    placeholder="Enter Price"
                    isColumn
                   
                  />
                </div>
                <div style={{ width: "30%" }}>
                  <FastField
                    component={InputComponent}
                    name="discountedPrice"
                    label="Price After Discount"
                    placeholder="Enter discounted price"
                    isColumn
                    
                  />
                </div>
                <div style={{ width: "24%" }}>
                  <FastField
                    component={InputComponent} 
                    name="currencyName"
                    label="Currency*"
                    isColumnWithoutNoCreate
                    isColumn
                    disabled
                    style={{
                      cursor: props.disabled ? "pointer" : "not-allowed",
                    }}
                  />
                </div>
              </FlexContainer>

              <Spacer />
              <div>
                  <FastField
                    name="description"
                    label="Product Description"
                    width={"100%"}
                    isColumn
                    component={TextareaComponent}
                    style={{ width: "-webkit-fill-available",minHeight:"5em" }}
                  />
              </div>
            </MainWrapper>
            <Spacer />
            <MainWrapper width="90%">
              <Spacer />
              <div>
                <StyledLabel>
                  <h1>Product image</h1>
                </StyledLabel>
              </div>
              <FastField name="imageId" component={Upload} 
              
              />
             
              <p style={{color: "tomato",
    fontWeight: "600",
    padding: "0.1rem 0", 
    borderRadius: "0.1rem" }}>
                {values.imageId===""? "Please select Image" :""}
              </p>
            </MainWrapper>
            <MainWrapper width="90%">
              <Spacer />
              <div>
                <StyledLabel>
                  {" "}
                  <h1>Add Inventory</h1>
                </StyledLabel>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div style={{ width: "40%" }}>
                  <FastField
                    component={InputComponent}
                    name="units"
                    label="Total available items"
                    // placeholder="Enter quantity"
                    disabled
                    style={{ cursor: disabled ? "pointer" : "not-allowed" }} 
                    isColumn
                  />
                </div>
                {!props.deliveryChargesInd ? (
                  <>             
                    <div>
                      <StyledLabel>In Stock</StyledLabel>
                      <Switch
                        checked={stock}
                        onChange={handleStock}
                        disabled
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                      ></Switch>
                      <h2>Delivery Charges input Needed</h2>
                    </div>
                    <div >
                     
                        <Button className="button_div2"
                        onClick={()=>{props.handleDeliveryChargeModal(true)}}
                        >
                          Set Delivery Charge
                        </Button>
                    </div>
                  </>
                ) : (
                  <div>
                    <StyledLabel>In Stock</StyledLabel>
                    <Switch
                      checked={stock}
                      onChange={handleStock}
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    ></Switch>
                  </div>
                )}
              </div>
            </MainWrapper>

            <MainWrapper width="90%">
              <Spacer />
              <div>
                <StyledLabel>
                  <h1>Add sizes</h1>
                </StyledLabel>
              </div>

              <AddSizeRow
                handleChangeSize={handleChangeSize}
                handleAddSizeClick={handleAddSizeClick}
                handleDeleteSize={handleDeleteSize}
                size={size}
              />
            </MainWrapper>
            <MainWrapper width="90%">
              <Spacer />
              <div>
                <StyledLabel>
                  <h1>Add Colors</h1>
                </StyledLabel>
              </div>

              <div style={{ width: "100%" }}>
                <AddColorRow
                  handleChangeName={handleChangeName}
                  handleAddRowClick={handleAddRowClick}
                  handleDelete={handleDelete}
                  rows={rows}
                />
              </div>
            </MainWrapper>
            <FlexContainer justifyContent="center">
              <div>
                <Button
                  className="ajktn"
                  loading={props.addingProductsInformation}
                  htmlType="submit"
                >
                  <label class="text-white font-bold">
                  Add
                  </label>
                </Button>
              </div>
            </FlexContainer>
          </Form>
        )}
      </Formik>
      <SetDeliveryChargeModal
      handleDeliveryChargeModal={props.handleDeliveryChargeModal}
      setDeliveryCharge={props.setDeliveryCharge}
      />
    </>
  );
}

const mapStateToProps = ({ products, auth, manage, home }) => ({
  addingProductsInformation: products.addingProductsInformation,
  user: auth.userDetails,
  userId: auth.userDetails.userId,
  merchantId: auth.userDetails.merchantDetails.merchantId,
  productsCategory: products.productsCategory,
  fetchingProductsCategory: products.fetchingProductsCategory,
  currencies: manage.currencies,
  currencyName: auth.userDetails.currencyName,
  currencyId: auth.userDetails.currency,
  testShop: home.testShop,
  deliveryChargesInd:auth.userDetails.merchantDetails && auth.userDetails.merchantDetails.deliveryChargesInd,
  setDeliveryCharge:manage.setDeliveryCharge
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      linkProductsInformation,
      getProductCategory,
      getCurrency,
      getUserDetails,
      handleDeliveryChargeModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductInformationForm);
