import React from "react";
import { FlexContainer } from "../../Components/UI/Layout";
import ProductsProfileCard from "./ProductsProfileCard";

function ProductsActionLeft(props) {
  return (
    <div>
      <FlexContainer flexDirection="column" style={{ display: "block" }}>
        <ProductsProfileCard
          handleProductClick={props.handleProductClick}
          product={props.product}
          currentProductsOpen={props.currentProductsOpen}
          // subscriptionType={props.subscriptionType}
        />
      </FlexContainer>
    </div>
  );
}
export default ProductsActionLeft;
