import * as types from "./ShopActionTypes";
import moment from "moment";

const initialState = {
  viewType: "shop",

  //     //add datewise
  //   addingDatewiseorder: false,
  //   addingDatewiseorderError: false,
  //   datewiseOrder: [],

  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

  dateRangeList: [
    // {
    //   id: 1,
    //   type: "LifeTime",
    //   value: "Life Time",
    //   starter: true,
    //   isSelected: true,
    //   startDate: moment()
    //     // .subtract(1, "days")
    //     .toISOString(),
    //   endDate: moment().toISOString(),
    // },

    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected: true,
      startDate: moment()
        // .subtract(1, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 2,
      type: "Yesterday",
      value: "Yesterday",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(1, "days")

        .toISOString(),
      endDate: moment()
        .subtract(1, "days")

        .toISOString(),
    },
    {
      id: 3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(7, "days")

        .toISOString(),
      endDate: moment()
        .subtract(7, "days")

        .toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(30, "days")

        .toISOString(),
      endDate: moment()
        .subtract(30, "days")

        .toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment().startOf("week").toISOString(),
      endDate: moment().endOf("week").toISOString(),
    },
    {
      id: 6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment().startOf("month").toISOString(),
      endDate: moment().endOf("month").toISOString(),
    },
    // {
    //   id: 8,
    //   type: "DateRange",
    //   value: "Date Range",
    //   starter: false,
    //   isSelected: false,
    //   startDate: moment().startOf("year").toISOString,
    //   endDate: moment().endOf("year").toISOString(),
    // },
  ],

  fetchingShopCard: false,
  fetchingShopCardError: false,
  shopCard: [],

  fetchingShopList: false,
  fetchingShopListError: false,
  shopList: [],

  fetchingMerchantProduct: false,
  fetchingMerchantProductError: false,
  shopDetailPro: [],

  puttingShopInApproval: false,
  puttingShopInApprovalError: false,

  addShopAccount: false,
  shopHistoryModal: false,

};

export const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SHOP_VIEW_TYPE:
      return { ...state, viewType: action.payload };
    // case types.ADD_ORDER_DATEWISE_REQUEST:
    //   return {
    //     ...state,
    //     addingDatewiseorder: true,
    //     addingDatewiseorderError: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_SUCCESS:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: false,
    //     datewiseOrder: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_FAILURE:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: true,
    //     datewiseOrder: false,
    //   };

    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };


    case types.GET_SHOP_LIST_REQUEST:
      return { ...state, fetchingShopList: true };
    case types.GET_SHOP_LIST_SUCCESS:
      return {
        ...state,
        fetchingShopList: false,
        shopList: action.payload,
      };
    case types.GET_SHOP_LIST_FAILURE:
      return {
        ...state,
        fetchingShopList: false,
        fetchingShopListError: true,
      };


    case types.GET_SHOP_CARD_REQUEST:
      return { ...state, fetchingShopCard: true };
    case types.GET_SHOP_CARD_SUCCESS:
      return {
        ...state,
        fetchingShopCard: false,
        shopCard: action.payload,
      };
    case types.GET_SHOP_CARD_FAILURE:
      return {
        ...state,
        fetchingShopCard: false,
        fetchingShopCardError: true,
      };

    case types.GET_MERCHANT_PRODUCT_REQUEST:
      return { ...state, fetchingMerchantProduct: true };
    case types.GET_MERCHANT_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchingMerchantProduct: false,
        shopDetailPro: action.payload,
      };
    case types.GET_MERCHANT_PRODUCT_FAILURE:
      return {
        ...state,
        fetchingMerchantProduct: false,
        fetchingMerchantProductError: true,
      };

    case types.PUT_IN_APPROVAL_SHOP_REQUEST:
      return {
        ...state,
        puttingShopInApproval: true,
      };
    case types.PUT_IN_APPROVAL_SHOP_SUCCESS:
      return {
        ...state,
        puttingShopInApproval: false,
        // shopCard: state.shopCard.map((item) => {
        //   if (item.merchantId === action.payload.merchantId) {
        //     return { ...item, approveInd: action.payload.approveInd }
        //   }
        //   else {
        //     return item
        //   }

        // })
      };
    case types.PUT_IN_APPROVAL_SHOP_FAILURE:
      return {
        ...state,
        puttingShopInApproval: false,
        puttingShopInApprovalError: true,
      };

    case types.HANDLE_SHOP_ACCOUNT_MODAL:
      return { ...state, addShopAccount: action.payload };
  
  case types.HANDLE_SHOP_HISTORY_MODAL:
  return { ...state,shopHistoryModal: action.payload };

    default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
  
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });

