import * as types from "./GoodsActionTypes";
import moment from "moment";

const initialState = {
  viewType: "goods",

  //     //add datewise
  //   addingDatewiseorder: false,
  //   addingDatewiseorderError: false,
  //   datewiseOrder: [],

  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

  type: "All",
  dateRangeList: [
    {
      id: 8,
      type: "All",
      value: "All",
      starter: true,
      isSelected: true,
      startDate: moment().toISOString(),

      endDate: moment().toISOString(),
    },

    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected: false,
      startDate: moment()
        // .subtract(1, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },
    // {
    //   id: 2,
    //   type: "Yesterday",
    //   value: "Yesterday",
    //   starter: false,
    //   isSelected: false,
    //   startDate: moment()
    //     .subtract(1, "days")

    //     .toISOString(),
    //   endDate: moment().toISOString(),
    // },
    {
      id: 3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
      .subtract(7, "days")

      .toISOString(),
    endDate: moment().toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
      .subtract(30, "days")

      .toISOString(),
    endDate: moment().toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").toISOString(),
      endDate: moment.utc().toISOString(),
    },
    {
      id: 6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").subtract(1,"month").toISOString(),
      endDate: moment.utc().endOf('month').subtract(1,"month").toISOString(),
    },
    // {
    //   id: 8,
    //   type: "DateRange",
    //   value: "Date Range",
    //   starter: false,
    //   isSelected: false,
    //   startDate: moment().startOf("year").toISOString,
    //   endDate: moment().endOf("year").toISOString(),
    // },
  ],
  reportType: [
    "goods",
  ],
  selectedReportType: "goods",
  selectedSubReportType: "goods",

 

  fetchingGoodsList: false,
  fetchingGoodsListError: false,
  goods: [],

  fetchingAllRecords: false,
  fetchingAllRecordsError: false,
  recordAllData: {},
  
  fetchingDatewiseProduct: false,
  fetchingDatewiseProductError: false,

  goodsAdminProductModal:false,
  setEditingAdminGoods:{},
};

export const goodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_GOODS_VIEW_TYPE:
      return { ...state, viewType: action.payload };

    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        type: action.payload.type
      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.GET_LIST_PRODUCT_REQUEST:
      return { ...state, fetchingGoodsList: true };
    case types.GET_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchingGoodsList: false,
        goods: action.payload,
      };
    case types.GET_LIST_PRODUCT_FAILURE:
      return {
        ...state,
        fetchingGoodsList: false,
        fetchingGoodsListError: true,
      };

 //GET ALL RECORDS
 case types.GET_ALL_RECORDS_REQUEST:
  return { ...state, fetchingAllRecords: true };
case types.GET_ALL_RECORDS_SUCCESS:
  return {
    ...state,
    fetchingAllRecords: false,
    recordAllData: action.payload,
  };
case types.GET_ALL_RECORDS_FAILURE:
  return {
    ...state,
    fetchingAllRecords: false,
    fetchingAllRecordsError: true,
  };

  case types.GET_DATE_WISE_PRODUCT_REQUEST:
    return { ...state, fetchingDatewiseProduct: true };
  case types.GET_DATE_WISE_PRODUCT_SUCCESS:
    return {
      ...state,
      fetchingDatewiseProduct: false,
      fetchingDatewiseProductError: false,
      goods: action.payload,
    };
  case types.GET_DATE_WISE_PRODUCT_FAILURE:
    return {
      ...state,
      fetchingDatewiseProduct: false,
      fetchingDatewiseProductError: true,
    };
case types.HANDLE_GOODS_PRODUCT_MODAL:
  return {...state, goodsAdminProductModal:action.payload};
case types.SET_EDIT_ADMIN_GOODS:
  return {...state, setEditingAdminGoods:action.payload}
  default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
   
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
