import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
class PrivateRoute extends React.Component {
  componentDidMount() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        //catches if the session ended!
        if (error.response.status ===401) {
          sessionStorage.clear();
          // store.dispatch({ type: LOGOUT });
          this.props.history.push("/login");
          message.error("Your session has expired. Please re-login.");
        }
        return Promise.reject(error);
      }
    );
    if (!this.props.userDetails) {
      this.props.history.push("/login");
      message.error("Your session has expired. Please re-login.");
    }
  }

  componentWillUpdate(nextProps) {
    if (!nextProps.userDetails) {
      this.props.history.push("/login");
    }
  }
  render() {
    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          sessionStorage.getItem("userDetails") ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    userDetails: JSON.stringify(sessionStorage.getItem("userDetails")),
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
