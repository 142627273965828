import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { FlexContainer } from '../../../Components/UI/Layout'
import { MultiAvatar } from '../../../Components/UI/Elements'

const HeaderActionCenter = (props) => {
  return (
    <div class=" md:w-w60">
    <div className="flex w-wk justify-center">
      <div className=' w-auto'>
      <MultiAvatar
         primaryTitle={props.shopName.name}
          imageId={props.shopName.merchantImageId}
          imgHeight={45}
          imgWidth={45}
        />
    
        
      </div>
      <div className=' w-auto'>
      <h1 className=' xl:text-3xl sm:text-base'>{`${props.shopName.name}`}</h1>
      
        </div>
        </div>
         </div>
  )
}

const mapStateToProps = ({ auth, customer }) => ({
  shopName: customer.shopName,
})

export default connect(mapStateToProps)(HeaderActionCenter)
