import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import PaymentModal from "../Payment/PaymentModal"
import { bindActionCreators } from 'redux';
import { Radio, Input, Space, Button } from "antd";
// import COD from "../../../../../Assets/Images/cod.png";
import stripe1 from "../../../../../Assets/Images/stripe.png";
import pay from "../../../../../Assets/Images/Payment.png";
import { FlexContainer,Spacer } from "../../../../../Components/UI/Elements";
import { addPlaceOrder ,handleStripeModal} from "../../../CustomerAction";

class PaymentLeftContent extends React.Component {
  state = {
    value: 1,
  };

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleAddPlaceOrder = () => {
    const value = localStorage.getItem('cartId')
    const final=JSON.parse(value)
  
  let data={
     
      
      cartId: final.cartId,
      mode:this.state.value,
      
      // identifierType:"",
      // quantity: 1,
      // storeTerminal: 
      //   {
      //     storeId:this.props.customer.length && this.props.customer[0].merchantDetailsId || "",
      //   }
      
    }

    this.props.addPlaceOrder(data,final.cartId,{mode:this.state.value});
    // {
    //   cartId:this.props.cartId

    // },
  

}

  render() {
    const {
      handleStripeModal,
      addStripeModal,
     
    } = this.props;
    const cartData=this.props.deliveryInfo.storeCart;
    const cartSummaryData= cartData && cartData.cartSummary
    const finalSubTotalValue=cartSummaryData && cartSummaryData.subTotal;
    const finalgrandTotalValue=cartSummaryData && cartSummaryData.grandTotal;
    const countryData=this.props.showDeliveryInfo.storeCart
    const cartShippingData= countryData && countryData.shippingAddress && countryData.shippingAddress.countryName;
  //  const {countryName}=this.props.deliveryInfo.storeCart.shippingAddress
    const shopName= this.props.shopName.name
    const str = shopName&&shopName.replace(/ +/g, "");

    const { value } = this.state;
    if (this.props.updatingDeliveryInfo){
      return <p>Loading</p>
    }
    return (
      <>
        <br />
        <Radio.Group onChange={this.onChange} value={value}>
       
          
          <div class="flex justify-between items-baseline">
          <Radio.Group onChange={this.onChange} value={value}>
            <Radio value={"Stripe"} checked={true}>
            <div class="flex">
             
                {/* <img src={stripe1} alt="stripe1" style={{
                   width:"5.25em",
                   }} /> */} <div style={{  
                  fontWeight:"800",
                  fontSize:"1.3em" ,
                  color:"blue" 
                }}>
                Stripe
              </div>
              
               
                <Button type="primary" 
                // shape="round" 
                style={{ backgroundColor: "#0073c8", marginLeft: "0.62em",borderRadius:"0.3rem"  }} 
                onClick={() => { handleStripeModal(true); }}>
               Checkout
               </Button>
            
              </div>
              <div>
              <p>
                <h3>Credit and Debit Card payments such as Mastercard, Visa, American Express, iDEAL, Apple Pay, Google Pay, Klarna and much more.</h3>
              </p>
            </div>
            </Radio>
            </Radio.Group>
            </div>
           
            <Spacer style={{ marginBottom: "6em" }} />

           <FlexContainer justifyContent="space-between"style={{display:"flex" , alignItems:"center" }}> 
           <Radio.Group onChange={this.onChange} value={value}>
           
           <Radio value={"Cash on delivery"}
           >
                <div style={{
                  display:"flex", 
                  alignItems:"baseline", 
                  // justifyContent:"space-evenly"
                  }}>
            <div style={{  
                  fontWeight:"800",
                  fontSize:"1.3em"  
                }}>
                COD
              </div>
              {/* <img
                style={{  
                  width:"5.25em", 
                  // height:"6.25em", 
                  // marginTop:"-2em"  
                }}
                alt="pay"
                src={pay}
              /> */}
               <Link to={`/${str}/ordersucess`}>
              
              <Button
                type="primary"
                onClick={()=>this.handleAddPlaceOrder()}
                disabled={this.props.shopName.countryName === cartShippingData &&this.props.shopName.codInd===true? false:true}
               
                style={{ backgroundColor: "#0073c8", marginLeft: "0.62em",borderRadius:"0.3rem"  }} 
              
              ><label class="text-white">
                Checkout</label>
              </Button>
                
            </Link>
             
          {/* <div classname="pay"> */}
              
            {/* </div>          */}
  </div>  
  <p>
                <h3 >Pay in cash or pay in person at the time of delivery</h3>
              </p>        
</Radio>
           </Radio.Group>
          </FlexContainer>
         
          <FlexContainer justifyContent="flex-end">
          {/* <Link to="/shopName/ordersucess"> */}
        
          </FlexContainer>
          <br />
          <br />
        </Radio.Group>
        <PaymentModal
        addStripeModal={addStripeModal}
        handleStripeModal={handleStripeModal}
        currency={this.props.shopName.currencyName}
        total={finalSubTotalValue}
        
      />
      </>
      
    );
  
  }
 


}

const mapStateToProps = ({ customer, auth }) => ({
  // customer: customer.customer,
  placedOrderData:customer.placedOrderData,
  addStripeModal: customer.addStripeModal,
  shopName:customer.shopName,
  placeOrder:customer.placeOrder,
  deliveryInfo:customer.deliveryInfo,
  updatingDeliveryInfo:customer.updatingDeliveryInfo,
  showDeliveryInfo:customer.showDeliveryInfo,
  // linkingProductInfo: customer.linkingProductInfo,
  // productInfo:customer.productInfo,
  //  cartId:customer.productInfo.cartId,
  // cartId: customer.dispatch.length && customer.dispatch[0].cartId || "",

  // userId: auth.userDetails.userId,
  // productId:products.products.productId
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
      {
         addPlaceOrder,
         handleStripeModal
      },
      dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(PaymentLeftContent);
