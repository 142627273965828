
// login request types
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

// get user details action types
export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

//logout and reset the redux store
export const LOGOUT = "LOGOUT";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const DO_SIGN_UP_REQUEST = "DO_SIGN_UP_REQUEST";
export const DO_SIGN_UP_SUCCESS = "DO_SIGN_UP_SUCCESS";
export const DO_SIGN_UP_FAILURE = "DO_SIGN_UP_FAILURE";

export const VERIFY_EMAIL_REQUEST="VERIFY_EMAIL_REQUEST";
export const VERIFY_EMAIL_SUCCESS="VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE="VERIFY_EMAIL_FAILURE";

export const VALIDATE_OTP_REQUEST="VALIDATE_OTP_REQUEST";
export const VALIDATE_OTP_SUCCESS="VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE="VALIDATE_OTP_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const GET_BUSSINESS_CATEGORY_REQUEST = "GET_BUSSINESS_CATEGORY_REQUEST";
export const GET_BUSSINESS_CATEGORY_SUCCESS = "GET_BUSSINESS_CATEGORY_SUCCESS";
export const GET_BUSSINESS_CATEGORY_FAILURE = "GET_BUSSINESS_CATEGORY_FAILURE";

