import * as types from "./ManageActionType";

const initialState = {
    addingDeliveryCharges: false,
    addingDeliveryChargesError: false,
    
    fetchingCurrency: false,
    fetchingCurrencyError: false,
    currencies: [],

    fetchingDeliveryCharge: false, 
    fetchingDeliveryChargeError: false, 
    viewCharges:{},

    setDeliveryCharge:false,
};

export const manageReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.ADD_DELIVERY_CHARGES_REQUEST:
      return { ...state, addingDeliveryCharges: true };
    case types.ADD_DELIVERY_CHARGES_SUCCESS:
      return {
        ...state,
        addingDeliveryCharges: false,
        setDeliveryCharge:false,
      };
    case types.ADD_DELIVERY_CHARGES_FAILURE:
      return {
        ...state,
        addingDeliveryCharges: false,
        addingDeliveryChargesError: true,
      };  

      case types.GET_CURRENCY_REQUEST:
      return { ...state, fetchingCurrency: true };
    case types.GET_CURRENCY_SUCCESS:
      return { ...state, fetchingCurrency: false, currencies: action.payload };
    case types.GET_CURRENCY_FAILURE:
      return {
        ...state,
        fetchingCurrency: false,
        fetchingCurrencyError: true,
      };

      case types.GET_DELIVERY_CHARGES_REQUEST:
        return { ...state, fetchingDeliveryCharge: true };
    case types.GET_DELIVERY_CHARGES_SUCCESS:
        return { ...state, fetchingDeliveryCharge: false, viewCharges: action.payload };
    case types.GET_DELIVERY_CHARGES_FAILURE:
        return { ...state, fetchingDeliveryCharge: false, fetchingDeliveryChargeError: true };  

    case types.HANDLE_DELIVERY_CHARGE_MODAL:
      return {...state, setDeliveryCharge:action.payload}
    default:
      return state;
  }
};
