import * as types from "./ProductsActionType";
import { base_url } from "../../Config/Auth";
import axios from "axios";
import { createBrowserHistory } from "history";
import { message } from "antd";
import moment from "moment";

const history = createBrowserHistory();

export const setProductsViewType = (viewType) => (dispatch) =>
  dispatch({ type: types.SET_PRODUCTS_VIEW_TYPE, payload: viewType });

  export const setEditProducts = (name) => (dispatch) => {
    dispatch({
      type: types.SET_PRODUCT_EDIT,
      payload: name,
    });
  };

export const linkProductsInformation = (data,cb) => (dispatch) => {
  dispatch({
    type: types.LINK_PRODUCTS_INFORMATION_REQUEST,
  });
  axios
    .post(`${base_url}/product`, data)
    .then((res) => {
      dispatch({
        type: types.LINK_PRODUCTS_INFORMATION_SUCCESS,
        payload: res.data,
      });
      cb &&cb()
    })
    .catch((err) => {
      dispatch({
        type: types.LINK_PRODUCTS_INFORMATION_FAILURE,
        payload: err,
      });
      cb&&cb()
    });
};

export const linkProductImage = (data) => (dispatch) => {
  dispatch({
    type: types.LINK_PRODUCTS_IMAGE_REQUEST,
  });
  axios
    .post(`${base_url}/image/submit`, data)
    .then((res) => {
   
      dispatch({
        type: types.LINK_PRODUCTS_IMAGE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
    
      dispatch({
        type: types.LINK_PRODUCTS_IMAGE_FAILURE,
        payload: err,
      });
    });
};
// to get products

export const getProductList = (merchantId) => (dispatch) => {
  dispatch({
    type: types.GET_PRODUCT_LIST_REQUEST,
  });
  axios
    .get(`${base_url}/product/productCatalogue/${merchantId}`)
    
    .then((res) => {
 
      dispatch({
        type: types.GET_PRODUCT_LIST_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
   
      dispatch({
        type: types.GET_PRODUCT_LIST_FAILURE,
        payload: err,
      });
    });
};

export const getRecords = (merchantId) => (dispatch) => {
  dispatch({
    type: types.GET_RECORDS_REQUEST,
  });
  axios
    .get(`${base_url}/product/record/count/${merchantId}`, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
  
      dispatch({
        type: types.GET_RECORDS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
   
      dispatch({
        type: types.GET_RECORDS_FAILURE,
        payload: err,
      });
    });
};

export const getProductCategory = () => (dispatch) => {
  dispatch({
    type: types.GET_PRODUCT_CATEGORY_REQUEST,
  });
  axios
    .get(`${base_url}/category/category`)
    .then((res) => {
    
      dispatch({
        type: types.GET_PRODUCT_CATEGORY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {

      dispatch({
        type: types.GET_PRODUCT_CATEGORY_FAILURE,
        payload: err,
      });
    });
};

export const putInStock = (data,productId,merchantId,cb) => (dispatch) => {
  dispatch({ type: types.PUT_IN_STOCK_PRODUCTS_REQUEST });

  axios
    .put(`${base_url}/product/instock/${productId} `, data,)
    .then((res) => {
      dispatch(getProductList(merchantId))
      dispatch({
        type: types.PUT_IN_STOCK_PRODUCTS_SUCCESS,
        payload: res.data,
      });
      cb && cb();
    })
    .catch((err) => {
      dispatch({
        type: types.PUT_IN_STOCK_PRODUCTS_FAILURE,
      });
      cb && cb();
    });
};


export const updateProductInfo = (data, productId,cb) => (
  dispatch
) => {
  dispatch({
    type: types.UPDATE_PRODUCTS_REQUEST,
  });
  axios
    .put(`${base_url}/product/${productId}`, data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      // dispatch(getDistributors(userId));
      dispatch({
        type: types.UPDATE_PRODUCTS_SUCCESS,
        payload: res.data,
      });
      cb &&cb()
    })
    .catch((err) => {
      dispatch({
        type: types.UPDATE_PRODUCTS_FAILURE,
        payload: err,
      });
      cb &&cb()
    });
};

//SEARCH
export const inputProductDataSearch = (merchantId,productName,data) => (dispatch) => {
  dispatch({
    type: types.INPUT_PRODUCT_SEARCH_DATA_REQUSET,
  });
  axios
    .get(`${base_url}/product/productSearch/${merchantId}/${productName}`,data, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token") || "",
      },
    })
    .then((res) => {
      dispatch({
        type: types.INPUT_PRODUCT_SEARCH_DATA_SUCCESS,
        payload: res.data,
      });
      {res.data.message ? 
        message.info(res.data.message)
        :
        message.info("Product exist")
        }
    })
    .catch((err) => {
      dispatch({
        type: types.INPUT_PRODUCT_SEARCH_DATA_FAILURE,
        payload: err,
      });
    });
};

export const setSelectedReportType = (type) => (dispatch) =>
  dispatch({
    type: types.SET_SELECTED_REPORT_TYPE,
    payload: type,
  });

export const setSubSelectedReportType = (type) => (dispatch) =>
  dispatch({
    type: types.SET_SUB_SELECTED_REPORT_TYPE,
    payload: type,
  });

  /**
 * set selected time range from time interval report
 */
   export const setSelectedTimeIntervalReport = (selectedTime) => (dispatch) => {
    dispatch({
      type: types.CHANGE_SELECTED_TIME_INTERVAL_REPORT,
      payload: selectedTime,
    });
  };
  /**
   * set current Time  report
   */
   export const setTimeRangeReport = (startDate, endDate) => (dispatch) => {
    dispatch({
      type: types.SET_TIME_INTERVAL_REPORT,
      payload: {
        startDate: moment(startDate).toISOString(),
        endDate: moment(endDate).toISOString(),
      },
    });
  };

  export const getProductDateWise = (merchantId,endDate,startDate) => (dispatch) => {
    dispatch({
      type: types.GET_DATE_WISE_REPORT_REQUEST,
    });
    axios
      .get(`${base_url}/product/dateWiseProduct/${merchantId}?endDate=${endDate}&startDate=${startDate}`,)
      .then((res) => {
        dispatch({
          type: types.GET_DATE_WISE_REPORT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.GET_DATE_WISE_REPORT_FAILURE,
        });
      });
  };
///////SIZE//////
  export const getSizePro = (productId) => (dispatch) => {
    dispatch({
      type: types.GET_SIZE_OF_PRODUCT_REQUEST,
    });
    axios
      .get(`${base_url}/product/sizes/${productId}`)
      
      .then((res) => {
   
        dispatch({
          type: types.GET_SIZE_OF_PRODUCT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
     
        dispatch({
          type: types.GET_SIZE_OF_PRODUCT_FAILURE,
          payload: err,
        });
      });
  };
  export const addSizePro = (data,cb) => (dispatch) => {
    dispatch({
      type: types.ADD_SIZE_OF_PRODUCTS_REQUEST,
    });
    axios
      .post(`${base_url}/product/addSize`, data)
      .then((res) => {
        message.success("Product Size added successfully")
        dispatch({
          type: types.ADD_SIZE_OF_PRODUCTS_SUCCESS,
          payload: res.data,
        });
        cb &&cb()
      })
      .catch((err) => {
        dispatch({
          type: types.ADD_SIZE_OF_PRODUCTS_FAILURE,
          payload: err,
        });
        cb&&cb()
      });
  };
  export const updateSize = (data,cb) => (dispatch) => {
    console.log("dt",data)
    dispatch({
      type: types.UPDATE_SIZE_OF_PRODUCTS_REQUEST,
    });
    axios
      .put(
        `${base_url}/product/updateSize`,data )
      .then((res) => {
        console.log(res);
        message.success("Product Size updated successfully")
        dispatch({
          type: types.UPDATE_SIZE_OF_PRODUCTS_SUCCESS,
          payload: res.data,
        });
        cb && cb("success");
      })
      .catch((err) => {
        // console.log(err);
        dispatch({
          type: types.UPDATE_SIZE_OF_PRODUCTS_FAILURE,
          payload: err,
        });
        cb && cb("error");
      });
  };
  export const deleteSize = (sizeId,productId) => (dispatch) => {
    dispatch({
      type: types.DELETE_SIZE_OF_PRODUCT_REQUEST,
    });
    axios                 
      .delete(`${base_url}/product/sizeDelete/${sizeId}/${productId}`,)
      .then((res) => {
        console.log(res);
        message.success("Product Size deleted successfully")
        dispatch({
          type: types.DELETE_SIZE_OF_PRODUCT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.DELETE_SIZE_OF_PRODUCT_FAILURE,
          payload: err,
        });
      });
  };
  //////COLOR///////
  export const getColorPro = (productId) => (dispatch) => {
    dispatch({
      type: types.GET_COLOR_OF_PRODUCT_REQUEST,
    });
    axios
      .get(`${base_url}/product/colors/${productId}`)
      
      .then((res) => {
   
        dispatch({
          type: types.GET_COLOR_OF_PRODUCT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
     
        dispatch({
          type: types.GET_COLOR_OF_PRODUCT_FAILURE,
          payload: err,
        });
      });
  };
  export const addColorPro = (data,cb) => (dispatch) => {
    dispatch({
      type: types.ADD_COLOR_OF_PRODUCTS_REQUEST,
    });
    axios
      .post(`${base_url}/product/addColor`, data)
      .then((res) => {
        message.success("Product Color added successfully")
        dispatch({
          type: types.ADD_COLOR_OF_PRODUCTS_SUCCESS,
          payload: res.data,
        });
        cb &&cb()
      })
      .catch((err) => {
        dispatch({
          type: types.ADD_COLOR_OF_PRODUCTS_FAILURE,
          payload: err,
        });
        cb&&cb()
      });
  };
  export const updateColor = (data,cb) => (dispatch) => {
    dispatch({
      type: types.UPDATE_COLOR_OF_PRODUCTS_REQUEST,
    });
    axios
      .put(
        `${base_url}/product/updateColour`,data)
      .then((res) => {
        console.log(res);
        message.success("Product Color updated successfully")
        dispatch({
          type: types.UPDATE_COLOR_OF_PRODUCTS_SUCCESS,
          payload: res.data,
        });
        cb && cb("success");
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.UPDATE_COLOR_OF_PRODUCTS_FAILURE,
        });
        cb && cb("error");
      });
  };
  export const deleteColor = (colourId,productId) => (dispatch) => {
    dispatch({
      type: types.DELETE_COLOR_OF_PRODUCT_REQUEST,
    });
  
    axios
      .delete(`${base_url}/product/colourDelete/${colourId}/${productId}`)
      .then((res) => {
        message.success("Product Color deleted successfully")
        dispatch({
          type: types.DELETE_COLOR_OF_PRODUCT_SUCCESS,
          payload:res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: types.DELETE_COLOR_OF_PRODUCT_FAILURE,
          payload: err,
        });
      });
  };