import React, { Component } from "react";

import MobileImg from "../../Assets/Images/mobile_login.png";

import RightLogin from "../../Assets/Images/login-right.png";
import "./SignUp.scss";

class RandomImageScreen extends Component {
  render() {
    const { viewport } = this.props;
    const style = viewport === "mobile" ? "randomScreenMobile" : "randomScreen";
    const Image = viewport === "mobile" ? MobileImg : RightLogin;
    const ImageStyle = viewport === "mobile" ? "mobileImgStyle" : "webImgStyle";
    return (
      <div className={style}>
        <img src={Image} alt="hello" className={ImageStyle} />
      </div>
    );
  }
}

export default RandomImageScreen;
