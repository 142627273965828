import * as types from "./PaymentsActionTypes";
import moment from "moment";

const initialState = {
  viewType: "payments",

  //     //add datewise
  //   addingDatewiseorder: false,
  //   addingDatewiseorderError: false,
  //   datewiseOrder: [],

  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

  dateRangeList: [
    // {
    //   id: 1,
    //   type: "LifeTime",
    //   value: "Life Time",
    //   starter: true,
    //   isSelected: true,
    //   startDate: moment()
    //     // .subtract(1, "days")
    //     .toISOString(),
    //   endDate: moment().toISOString(),
    // },

    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected: true,
      startDate: moment()
        // .subtract(1, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 2,
      type: "Yesterday",
      value: "Yesterday",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(1, "days")

        .toISOString(),
      endDate: moment()
        .subtract(1, "days")

        .toISOString(),
    },
    {
      id: 3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(7, "days")

        .toISOString(),
      endDate: moment()
        .subtract(7, "days")

        .toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(30, "days")

        .toISOString(),
      endDate: moment()
        .subtract(30, "days")

        .toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment().startOf("week").toISOString(),
      endDate: moment().endOf("week").toISOString(),
    },
    {
      id: 6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment().startOf("month").toISOString(),
      endDate: moment().endOf("month").toISOString(),
    },
    // {
    //   id: 8,
    //   type: "DateRange",
    //   value: "Date Range",
    //   starter: false,
    //   isSelected: false,
    //   startDate: moment().startOf("year").toISOString,
    //   endDate: moment().endOf("year").toISOString(),
    // },
  ],

  addingPaymentPlaceOrder: false,
  addingPaymentPlaceOrderError: false,
  paymentPlaceOrder:[],

  fetchingPayCod: false,
  fetchingPayCodError: false,
  payCod:[],
  
  addingPayCod: false,
  addingPayCodError:false,
  poPayCod:[],
};

export const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.ADD_ORDER_DATEWISE_REQUEST:
    //   return {
    //     ...state,
    //     addingDatewiseorder: true,
    //     addingDatewiseorderError: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_SUCCESS:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: false,
    //     datewiseOrder: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_FAILURE:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: true,
    //     datewiseOrder: false,
    //   };

    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.ADD_PAYMENT_PLACE_ORDER_REQUEST:
      return { ...state, addingPaymentPlaceOrder: true };
    case types.ADD_PAYMENT_PLACE_ORDER_SUCCESS:
      return {
        ...state,
        addingPaymentPlaceOrder: false,
        paymentPlaceOrder: action.payload,
      };
    case types.ADD_PAYMENT_PLACE_ORDER_FAILURE:
      return {
        ...state,
        addingPaymentPlaceOrder: false,
        addingPaymentPlaceOrderError: true,
      };

      case types.GET_PAY_COD_REQUEST:
        return { ...state, fetchingPayCod: true };
      case types.GET_PAY_COD_SUCCESS:
        return {
          ...state,
          fetchingPayCod: false,
          payCod: action.payload,
        };
      case types.GET_PAY_COD_FAILURE:
        return {
          ...state,
          fetchingPayCod: false,
          fetchingPayCodError: true,
        };
        
        case types.POST_PAY_COD_REQUEST:
          return { ...state, addingPayCod: true };
        case types.POST_PAY_COD_SUCCESS:
          return {
            ...state,
            addingPayCod: false,
            // poPayCod: action.payload,
            payCod: state.payCod.map((item) => {
              if (item.paymentRuleengineId === action.payload.paymentRuleengineId) {
                return{...item,codInd:action.payload.codInd}
              } else {
                return item;
              }
            }),
          };
        case types.POST_PAY_COD_FAILURE:
          return {
            ...state,
            addingPayCod: false,
            addingPayCodError: true,
          };

    default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
