import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FlexContainer } from "../../../Components/UI/Layout";
import { MultiAvatar } from "../../../Components/UI/Elements";
import "./MainHeader.scss";

const MainHeaderRight = (props) => {
  return (
    <FlexContainer alignItems="center">
      <div class="max-sm:flex justify-end">
        <p
        class=" max-sm:mt-1 font-bold text-xs text-gray-500 w-wk m-1 break-words md:text-sm mt-0 ">
          {`+${props.shopName.dialCode}  ${props.shopName.phoneNo}`} <br />
          {props.shopName.email}
          <br />
          {props.shopName.addresses && props.shopName.addresses.address1},{" "}<br/>
          {props.shopName.addresses && props.shopName.addresses.city},{" "}
          {props.shopName.addresses && props.shopName.addresses.state},{" "}<br/>
          {props.shopName.countryName}
        </p>
      </div>
    </FlexContainer>
  );
};

const mapStateToProps = ({ auth, customer }) => ({
  shopName: customer.shopName,
});

export default connect(mapStateToProps)(MainHeaderRight);
