export const LINK_PRODUCTS_INFORMATION_REQUEST = "LINK_PRODUCTS_INFORMATION_REQUEST";
export const LINK_PRODUCTS_INFORMATION_SUCCESS = "LINK_PRODUCTS_INFORMATION_SUCCESS";
export const LINK_PRODUCTS_INFORMATION_FAILURE = "LINK_PRODUCTS_INFORMATION_FAILURE";

export const LINK_PRODUCTS_IMAGE_REQUEST = "LINK_PRODUCTS_IMAGE_REQUEST";
export const LINK_PRODUCTS_IMAGE_SUCCESS = "LINK_PRODUCTS_IMAGE_SUCCESS";
export const LINK_PRODUCTS_IMAGE_FAILURE = "LINK_PRODUCTS_IMAGE_FAILURE";

export const SET_PRODUCTS_VIEW_TYPE = "SET_PRODUCTS_VIEW_TYPE";

export const GET_PRODUCT_LIST_REQUEST = "GET_PRODUCT_LIST_REQUEST";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_FAILURE = "GET_PRODUCT_LIST_FAILURE";

export const GET_RECORDS_REQUEST = "GET_RECORDS_REQUEST";
export const GET_RECORDS_SUCCESS = "GET_RECORDS_SUCCESS";
export const GET_RECORDS_FAILURE = "GET_RECORDS_FAILURE";
 
export const GET_PRODUCT_CATEGORY_REQUEST="GET_PRODUCT_CATEGORY_REQUEST";
export const GET_PRODUCT_CATEGORY_SUCCESS="GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_FAILURE="GET_PRODUCT_CATEGORY_FAILURE";

export const PUT_IN_STOCK_PRODUCTS_REQUEST="PUT_IN_STOCK_PRODUCTS_REQUEST";
export const PUT_IN_STOCK_PRODUCTS_SUCCESS="PUT_IN_STOCK_PRODUCTS_SUCCESS";
export const PUT_IN_STOCK_PRODUCTS_FAILURE="PUT_IN_STOCK_PRODUCTS_FAILURE";

export const UPDATE_PRODUCTS_REQUEST="UPDATE_PRODUCTS_REQUEST";
export const UPDATE_PRODUCTS_SUCCESS="UPDATE_PRODUCTS_SUCCESS";
export const UPDATE_PRODUCTS_FAILURE="UPDATE_PRODUCTS_FAILURE";

export const SET_PRODUCT_EDIT="SET_PRODUCT_EDIT";

//Search
export const INPUT_PRODUCT_SEARCH_DATA_REQUSET =
  "INPUT_PRODUCT_SEARCH_DATA_REQUSET";
export const INPUT_PRODUCT_SEARCH_DATA_SUCCESS =
  "INPUT_PRODUCT_SEARCH_DATA_SUCCESS";
export const INPUT_PRODUCT_SEARCH_DATA_FAILURE =
  "INPUT_PRODUCT_SEARCH_DATA_FAILURE";

  export const SET_SELECTED_REPORT_TYPE = "SET_SELECTED_REPORT_TYPE";
  export const SET_SUB_SELECTED_REPORT_TYPE = "SET_SUB_SELECTED_REPORT_TYPE";

  export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const GET_DATE_WISE_REPORT_REQUEST = "GET_DATE_WISE_REPORT_REQUEST";
export const GET_DATE_WISE_REPORT_SUCCESS = "GET_DATE_WISE_REPORT_SUCCESS";
export const GET_DATE_WISE_REPORT_FAILURE = "GET_DATE_WISE_REPORT_FAILURE";
//////SIZE/////
export const GET_SIZE_OF_PRODUCT_REQUEST="GET_SIZE_OF_PRODUCT_REQUEST";
export const GET_SIZE_OF_PRODUCT_SUCCESS="GET_SIZE_OF_PRODUCT_SUCCESS";
export const GET_SIZE_OF_PRODUCT_FAILURE="GET_SIZE_OF_PRODUCT_FAILURE";
export const ADD_SIZE_OF_PRODUCTS_REQUEST="ADD_SIZE_OF_PRODUCTS_REQUEST";
export const ADD_SIZE_OF_PRODUCTS_SUCCESS="ADD_SIZE_OF_PRODUCTS_SUCCESS";
export const ADD_SIZE_OF_PRODUCTS_FAILURE="ADD_SIZE_OF_PRODUCTS_FAILURE";
export const UPDATE_SIZE_OF_PRODUCTS_REQUEST="UPDATE_SIZE_OF_PRODUCTS_REQUEST";
export const UPDATE_SIZE_OF_PRODUCTS_SUCCESS="UPDATE_SIZE_OF_PRODUCTS_SUCCESS";
export const UPDATE_SIZE_OF_PRODUCTS_FAILURE="UPDATE_SIZE_OF_PRODUCTS_FAILURE";
export const DELETE_SIZE_OF_PRODUCT_REQUEST="DELETE_SIZE_OF_PRODUCT_REQUEST";
export const DELETE_SIZE_OF_PRODUCT_SUCCESS="DELETE_SIZE_OF_PRODUCT_SUCCESS";
export const DELETE_SIZE_OF_PRODUCT_FAILURE="DELETE_SIZE_OF_PRODUCT_FAILURE";
//////COLOR/////
export const GET_COLOR_OF_PRODUCT_REQUEST="GET_COLOR_OF_PRODUCT_REQUEST";
export const GET_COLOR_OF_PRODUCT_SUCCESS="GET_COLOR_OF_PRODUCT_SUCCESS";
export const GET_COLOR_OF_PRODUCT_FAILURE="GET_COLOR_OF_PRODUCT_FAILURE";
export const ADD_COLOR_OF_PRODUCTS_REQUEST="ADD_COLOR_OF_PRODUCTS_REQUEST";
export const ADD_COLOR_OF_PRODUCTS_SUCCESS="ADD_COLOR_OF_PRODUCTS_SUCCESS";
export const ADD_COLOR_OF_PRODUCTS_FAILURE="ADD_COLOR_OF_PRODUCTS_FAILURE";
export const UPDATE_COLOR_OF_PRODUCTS_REQUEST="UPDATE_COLOR_OF_PRODUCTS_REQUEST";
export const UPDATE_COLOR_OF_PRODUCTS_SUCCESS="UPDATE_COLOR_OF_PRODUCTS_SUCCESS";
export const UPDATE_COLOR_OF_PRODUCTS_FAILURE="UPDATE_COLOR_OF_PRODUCTS_FAILURE";
export const DELETE_COLOR_OF_PRODUCT_REQUEST="DELETE_COLOR_OF_PRODUCT_REQUEST";
export const DELETE_COLOR_OF_PRODUCT_SUCCESS="DELETE_COLOR_OF_PRODUCT_SUCCESS";
export const DELETE_COLOR_OF_PRODUCT_FAILURE="DELETE_COLOR_OF_PRODUCT_FAILURE";