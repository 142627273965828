export const ADD_ORDER_DATEWISE_REQUEST = "ADD_ORDER_DATEWISE_REQUEST";
export const ADD_ORDER_DATEWISE_SUCCESS = "ADD_ORDER_DATEWISE_SUCCESS";
export const ADD_ORDER_DATEWISE_FAILURE = "ADD_ORDER_DATEWISE_FAILURE";

// export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
//   "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
// export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const GET_DASH_BOARD_ID_REQUEST = "GET_DASH_BOARD_ID_REQUEST";
export const GET_DASH_BOARD_ID_SUCCESS = "GET_DASH_BOARD_ID_SUCCESS";
export const GET_DASH_BOARD_ID_FAILURE = "GET_DASH_BOARD_ID_FAILURE";

export const GET_LATEST_SALE_TABLE_REQUEST = "GET_LATEST_SALE_TABLE_REQUEST";
export const GET_LATEST_SALE_TABLE_SUCCESS = "GET_LATEST_SALE_TABLE_SUCCESS";
export const GET_LATEST_SALE_TABLE_FAILURE = "GET_LATEST_SALE_TABLE_FAILURE";

export const GET_TOP_10_SHOP_REQUEST = "GET_TOP_10_SHOP_REQUEST";
export const GET_TOP_10_SHOP_SUCCESS = "GET_TOP_10_SHOP_SUCCESS";
export const GET_TOP_10_SHOP_FAILURE = "GET_TOP_10_SHOP_FAILURE";

export const GET_TOP_SELLING_PRODUCT_REQUEST =
  "GET_TOP_SELLING_PRODUCT_REQUEST";
export const GET_TOP_SELLING_PRODUCT_SUCCESS =
  "GET_TOP_SELLING_PRODUCT_SUCCESS";
export const GET_TOP_SELLING_PRODUCT_FAILURE =
  "GET_TOP_SELLING_PRODUCT_FAILURE";

export const GET_DASH_BOARD_ID__REQUEST = "GET_DASH_BOARD_ID__REQUEST";
export const GET_DASH_BOARD_ID__SUCCESS = "GET_DASH_BOARD_ID__SUCCESS";
export const GET_DASH_BOARD_ID__FAILURE = "GET_DASH_BOARD_ID__FAILURE";

export const GET_PENDING_ORDERS_REQUEST = "GET_PENDING_ORDERS_REQUEST";
export const GET_PENDING_ORDERS_SUCCESS = "GET_PENDING_ORDERS_SUCCESS";
export const GET_PENDING_ORDERS_FAILURE = "GET_PENDING_ORDERS_FAILURE";

export const GET_ACCEPTED_DATA_REQUEST = "GET_ACCEPTED_DATA_REQUEST";
export const GET_ACCEPTED_DATA_SUCCESS = "GET_ACCEPTED_DATA_SUCCESS";
export const GET_ACCEPTED_DATA_FAILURE = "GET_ACCEPTED_DATA_FAILURE";

export const GET_PENDED_DATA_REQUEST = "GET_PENDED_DATA_REQUEST";
export const GET_PENDED_DATA_SUCCESS = "GET_PENDED_DATA_SUCCESS";
export const GET_PENDED_DATA_FAILURE = "GET_PENDED_DATA_FAILURE";

export const GET_SHIPPED_DATA_REQUEST = "GET_SHIPPED_DATA_REQUEST";
export const GET_SHIPPED_DATA_SUCCESS = "GET_SHIPPED_DATA_SUCCESS";
export const GET_SHIPPED_DATA_FAILURE = "GET_SHIPPED_DATA_FAILURE";

export const GET_CANCELLED_DATA_REQUEST = "GET_CANCELLED_DATA_REQUEST";
export const GET_CANCELLED_DATA_SUCCESS = "GET_CANCELLED_DATA_SUCCESS";
export const GET_CANCELLED_DATA_FAILURE = "GET_CANCELLED_DATA_FAILURE";

export const GET_JUMPSTARTBOX_DATA_REQUEST="GET_JUMPSTARTBOX_DATA_REQUEST";
export const GET_JUMPSTARTBOX_DATA_SUCCESS="GET_JUMPSTARTBOX_DATA_SUCCESS";
export const GET_JUMPSTARTBOX_DATA_FAILURE="GET_JUMPSTARTBOX_DATA_FAILURE";

export const GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_REQUEST="GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_REQUEST";
export const GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_SUCCESS="GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_SUCCESS";
export const GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_FAILURE="GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_FAILURE";