import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { Spacer } from "../../Components/UI/Elements";
import { Input } from "./styled";
import { ValidationError, Title, SubTitle } from "../../Components/UI/Elements";
import { FlexContainer } from "../../Components/UI/Layout";
import Button from "antd/lib/button";
import styled from "styled-components";
import {
  CheckCircleTwoTone,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  forgotPassword,
  verifyEmailurL,
  validateOtpurL,
} from "./AuthAction";
import RandomImageScreen from "./RandomImageScreen";

//import { tick } from "../../Assets/Images/Logo.png";

/**
 * yup validation scheme for set Password
 */
// const ChangePasswordSchema = Yup.object().shape({
//   password: Yup.string()
//     .required("Required")
//     .min(8, "password should be min 8 character ")
//     .max(50, "password should be max 50 character !"),
//   confirmPassword: Yup.string()
//     .required("Enter password")
//     .oneOf([Yup.ref("password")], "Passwords do not match"),
// });
class ForgotPassword extends Component {
  state = {
    type: "password",
    type1: "password",
    show1: Boolean(),
    show2: Boolean(),
    show: Boolean(),
  };
  handleClick = () =>
    this.setState(({ type, prevState }) => ({
      type: type === "text" ? "password" : "text",
      show: !this.state.show,
    }));
  handleClick1 = () =>
    this.setState(({ type1, prevState }) => ({
      type1: type1 === "text" ? "password" : "text",
      show1: !this.state.show1,
    }));
  InputComponent = ({ field, form: { touched, errors }, ...props }) => (
    <div>
      <Input {...field} {...props} />
      {touched[field.name] && errors[field.name] && (
        <ValidationError>{errors[field.name]}</ValidationError>
      )}
    </div>
  );
  componentDidMount() {
  
  }
  callback = (data) => {
    const { history } = this.props;
    if (data === "success") {
      history.push("/login");
    } else {
      history.push("/");
    }
  };

  render() {
    return (
      <>
        <div className="main" style={{ width: "100%", display: "flex" }}>
          <div className="forgot_password" style={{ width: "50%" }}>
            <FlexContainer>
              <AuthContainer
                style={{
                  backgroundColor: "white",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <FormWrapper>
                  <Title>Forgot Password</Title>
                  <SubTitle>
                    Link will be sent to your registered email id
                  </SubTitle>
                  <Spacer />
                  <Formik
                    initialValues={{
                      email: "",
                      otp: "",
                      password: "",  
                      confirmPassword: "",
                    }}
                    // validationSchema={ChangePasswordSchema}
                    onSubmit={(values) => {
                    
                      this.props.forgotPassword(
                        {
                          email: values.email,
                          password: values.password,

                        },
                        this.callback
                      );
                    }}
                  >
                    {({ errors, touched, values, isSubmitting }) => (
                      <Form style={{ width: "25vw" }}>
                        <div>
                          <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "75%" }}>
                              <Field
                                // type="defaultUser.email"
                                placeholder="Enter your email"
                                name="email"
                                // label="Verify Email"
                                // className="field"
                                isColumn
                                width={"100%"}
                                component={this.InputComponent}
                                inlineLabel
                              />
                            </div>
                            <div style={{ width: "25%" }}>
                              <Button
                                 onClick={() => {
                                  this.props.verifyEmailurL({
                                    emailId: values.email,
                                    otp: 0,
                                  });
                                  // this.handleOtpField()
                                }}
                                style={{
                                  width: "100%",
                                  margin: "7%",
                                  background: "#3066BE",
                                }}
                                // disabled={!this.state.checked}
                              >
                                            <label class="text-white font-bold">Send OTP</label>
                              </Button>
                            </div>
                          </div>

                          <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "75%" }}>
                              <Field
                                // disabled={!this.state.otp}
                                name="validateotp"
                                // label="Validate OTP*"
                                placeholder="Validate OTP"
                                isColumn
                                component={this.InputComponent}
                              />
                            </div>
                            <div style={{ width: "25%" }}>
                              <Button
                                onClick={() => {
                                  this.props.validateOtpurL({
                                    emailId: values.email,
                                    otp: values.validateotp,
                                  });
                                }}
                                style={{
                                  width: "100%",
                                  margin: "7%",
                                  background: "#3066BE",
                                }}

                                // disabled={!this.state.checked}
                              >
                                       <label class="text-white font-bold">Validate</label> 
                              </Button>
                            </div>
                          </div>

                          <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "100%" }}>
                              <Field
                                name="password"
                                type={this.state.type}
                                placeholder=" New password"
                                component={this.InputComponent}
                              />
                            </div>
                            {this.state.show ? (
                              <EyeOutlined
                                type="eye"
                                onClick={this.handleClick}
                                style={{
                                  marginLeft: "-1.25em",
                                  marginTop: "1.25em",
                                }}
                                size="24"
                              />
                            ) : (
                              <EyeInvisibleOutlined
                                type="eye-invisible"
                                onClick={this.handleClick}
                                size="24"
                                style={{
                                  marginLeft: "-1.25em",
                                  marginTop: "1.25em",
                                }}
                              />
                            )}
                          </div>

                          <div style={{ width: "100%", display: "flex" }}>
                            <div style={{ width: "100%" }}>
                              <Field
                                name="confirmPassword"
                                type={this.state.type1}
                                placeholder="Confirm new password"
                                component={this.InputComponent}
                              />
                            </div>
                            {this.state.show1 ? (
                              <EyeOutlined
                                type="eye"
                                onClick={this.handleClick1}
                                style={{
                                  marginLeft: "-1.25em",
                                  marginTop: "1.25em",
                                }}
                                // style={{ size: 24 }}
                              />
                            ) : (
                              <EyeInvisibleOutlined
                                type="eye-invisible"
                                onClick={this.handleClick1}
                                style={{
                                  marginLeft: "-1.25em",
                                  marginTop: "1.25em",
                                }}
                                // style={{ size: 24 }}
                              />
                            )}
                            {values.password.length &&
                        values.password === values.confirmPassword ? (
                          <CheckCircleTwoTone
                            type="check-circle"
                            theme="twoTone"
                            twoToneColor="#52c41a"
                            size={80}
                            style={{
                              marginLeft: "1.25em",
                              marginTop: "0.875em",
                              fontSize: "1.5625em",
                            }}
                          />
                        ) : null}
                          </div>
                        </div>
                        <Spacer style={{ marginBottom: "1em" }} />
                        <div>
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              {" "}
                              <Link
                                to="/login"
                                style={{ textAlign: "center", fontSize: 14 }}
                              >
                                Back to login
                              </Link>
                            </span>

                            <Button
                              htmlType="submit"
                              Loading={this.props.forgotPassword}
                              style={{ width: "15.875em", height: "2.5em",background: "#3066BE", }}
                              // onClick={() => this.props.forgotPassword('prabeen.strange@gmail.com', 'chicharito14')}
                            >
                                <label class="text-white font-bold">  Save Password    </label> 
                            </Button>
                          </span>
                        </div>
                        <Spacer style={{ marginBottom: "1em" }} />
                      </Form>
                    )}
                  </Formik>
                  <br />
                  {/* <Link to='/register' style={{ textAlign: 'center' }}>Doesn't have an account? Register</Link> */}
                </FormWrapper>
              </AuthContainer>
            </FlexContainer>
          </div>
          
            <RandomImageScreen viewport={"mobile"} />
            <RandomImageScreen />
          
        </div>
      </>
    );
  }
}
const mapStateToProps = ({ auth }) => ({
  forgotPassword: auth.forgotPassword,
  forgotPasswordError: auth.forgotPasswordError,
  // email: auth.userDetails.email,
  user: auth.userDetails,
  userType: auth.userDetails.userType,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      forgotPassword,
      verifyEmailurL,
      validateOtpurL,
    },
    dispatch
  );
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);

const AuthContainer = styled.div`
  // width: 50%;
  width:${(props) => props.width || "50%"}
  min-height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  @media only screen and (max-width: 37.5em) { 
   width:100%
  }
`;
const FormWrapper = styled.div`    
padding: 1rem;
width: ${(props) => props.width}
     border-radius: 0.3rem;
    box-shadow: 0em 0.25em 0.625em -0.125em #444;
    border: 0.0625em solid #ddd;
    background: #fff;
    @media only screen and (max-width: 37.5em) {
       width:89%
         }
 @media only screen 
and (min-device-width : 48em) 
and (max-device-width : 64em)
and (-webkit-min-device-pixel-ratio: 2){
}`;
