//!. Domain Name for generating merchant shop link
//2. Stripe return url
//3. Stripe_pk


//UAT test
// const login_url = "https://dev.shoppr.pro";
// const base_url = "https://dev.shoppr.pro";

//Production 
const login_url = "https://prod.shoppr.pro";
const base_url = "https://prod.shoppr.pro";
// const login_url = "http://ShoppermeProd-env.eba-4i9zs9pp.us-east-1.elasticbeanstalk.com";
// const base_url  = "http://ShoppermeProd-env.eba-4i9zs9pp.us-east-1.elasticbeanstalk.com";

export { base_url, login_url };
// master:production
// alpha-demo
// dev-Test
// 