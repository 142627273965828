import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import "antd/dist/antd.css";
import { CloseOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FlexContainer } from "../../../Components/UI/Elements";


function AddColorRow(props) {

   
    return (
    
        <>
            {props.rows.map((row, i) => {
              return (
                <div style={{ width: "60%",display:"flex",justifyContent:"space-around" }}>
                  <div style={{ width: "10%" }}>
                    <b>{`Color ${i + 1}`}</b>
                  </div>
                  <div style={{ width: "40%" }}>
                  <Input
                    name={`${row.colourId}_name`}
                    value={`${row.colour}`}
                    onChange={props.handleChangeName}
                    // placeholder={`Name ${row.colourId}`}
                  />
                  </div>
                  <div style={{ width: "10%" }}>
                  {props.rows.length > 1 && (row.colourId + 1 > row.colourId) ? (
                                    <CloseOutlined onClick={() => props.handleDelete(row)} />
                                ) : null}
                  </div>
                </div>
              );
            })}
            <div className="button">
              <Button type="primary" onClick={props.handleAddRowClick}>
                Add Row
              </Button>
            </div>
     
            </>     
        
    );
}


const mapStateToProps = ({ settings, user }) => ({
   
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({
       
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddColorRow);
