import * as types from "./OrdainActionTypes";
import moment from "moment";

const initialState = {
  viewType: "ordain",

  //     //add datewise
  //   addingDatewiseorder: false,
  //   addingDatewiseorderError: false,
  //   datewiseOrder: [],

  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

  dateRangeList: [
    {
      id: 8,
      type: "All",
      value: "All",
      starter: true,
      isSelected: true,
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
    },

    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected: false,
      startDate: moment().toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 2,
      type: "Yesterday",
      value: "Yesterday",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(1, "days")
        .toISOString(),
      endDate: moment().subtract(1, "days").toISOString(),
    },
    {
      id: 3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(7, "days")
        .toISOString(),
      endDate: moment()
        .toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(30, "days")
        .toISOString(),
      endDate: moment()
        .toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").toISOString(),
      endDate: moment.utc().toISOString(),
    },
    {
      id: 6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").subtract(1,"month").toISOString(),
      endDate: moment.utc().endOf('month').subtract(1,"month").toISOString(),
    }
  ],
  type: "All",

  fetchingOrdersByUserId: false,
  fetchingOrdersByUserIdError: false,
  ordersByUserId: [],

  fetchingReportType: false,
  fetchingReportTypeError: false,
  onlyReportTypes: [],

  addingOrder: false,
  addingOrderError: false,

  reportType: [
    "ordain",
  ],
  selectedReportType: "ordain",
  selectedSubReportType: "ordain",
  // reportSalesSubTypes: ["Demand", "Order", "OverView"],
  // switchTypeReport: "dispatch",
  // switchQualityControlType: "SNF",
  fetchingDatewiseReport: false,
  fetchingDatewiseReportError: false,
  dateOrdainReport: [],
};

export const ordainReducer = (state = initialState, action) => {
  switch (action.type) {


    case types.GET_REPORT_TYPE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        fetchingReportType: false,
        onlyReportTypes: action.payload,
      };
    case types.GET_REPORT_TYPE_DEPARTMENT_FAILURE:
      return {
        ...state,
        fetchingReportType: false,
        fetchingReportTypeError: true,
      };

    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        type: action.payload.type

      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,

      };

    case types.GET_ORDERS_BY_USER_ID_REQUEST:
      return { ...state, fetchingOrdersByUserId: true };
    case types.GET_ORDERS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        fetchingOrdersByUserId: false,
        ordersByUserId: action.payload,
      };
    case types.GET_ORDERS_BY_USER_ID_FAILURE:
      return {
        ...state,
        fetchingOrdersByUserId: false,
        fetchingOrdersByUserIdError: true,
      };

    case types.SET_ORDAIN_VIEW_TYPE:
      return {
        ...state,
        reportViewType: action.payload,
        viewType: action.payload
        
      };

    case types.SET_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: action.payload,
        // selectedSubReportType: "order",
      };
    case types.SET_SUB_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedSubReportType: action.payload,
      };


    case types.ADD_ORDER_REQUEST:
      return { ...state, addingOrder: true };
    case types.ADD_ORDER_SUCCESS:
      return {
        ...state, addingOrder: false,
        ordersByUserId: state.ordersByUserId.map((item) => {
          if (item.orderId === action.payload.orderId) {
            return action.payload
          } else {
            return item
          }
        })
      };
    case types.ADD_ORDER_FAILURE:
      return {
        ...state,
        addingOrder: false,
        addingOrderError: true,
        // addCurdleModal: false,
      };

    case types.GET_DATE_WISE_REPORT_REQUEST:
      return { ...state, fetchingDatewiseReport: true };
    case types.GET_DATE_WISE_REPORT_SUCCESS:
      return {
        ...state,
        fetchingDatewiseReport: false,
        fetchingDatewiseReportError: false,
        ordersByUserId: action.payload,
      };
    case types.GET_DATE_WISE_REPORT_FAILURE:
      return {
        ...state,
        fetchingDatewiseReport: false,
        fetchingDatewiseReportError: true,
      };

    default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    //console.log(newDate);
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
