import * as types from "./OrderActionTypes";
import moment from "moment";

const initialState = {
  viewType: "order",

  isCustomSelected: false,
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),

  dateRangeList: [

    {
      id: 8,
      type: "All",
      value: "All",
      starter: true,
      isSelected: true,
      startDate: moment()
        .toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 1,
      type: "Today",
      value: "Today",
      starter: true,
      isSelected: false,
      startDate: moment()
        .toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 2,
      type: "Yesterday",
      value: "Yesterday",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(1, "days")
        .toISOString(),
        endDate: moment().subtract(1, "days").toISOString(),
    },
    {
      id: 3,
      type: "Last7days",
      value: "Last 7 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(7, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },

    {
      id: 4,
      type: "Last30days",
      value: "Last 30 days",
      starter: false,
      isSelected: false,
      startDate: moment()
        .subtract(30, "days")
        .toISOString(),
      endDate: moment().toISOString(),
    },
    {
      id: 5,
      type: "Thismonth",
      value: "This month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").toISOString(),
      endDate: moment.utc().toISOString(),
    },
    {
      id: 6,
      type: "Lastmonth",
      value: "Last month",
      starter: false,
      isSelected: false,
      startDate: moment.utc().startOf("month").subtract(1,"month").toISOString(),
      endDate: moment.utc().endOf('month').subtract(1,"month").toISOString(),
    },
  ],
  type: 'All',

  fetchingOrderListByOrderId: false,
  fetchingOrderListByOrderIdError: false,
  orderListByOrderId: [],
  reportType: [
    "order",
  ],
  selectedReportType: "order",
  selectedSubReportType: "order",

  fetchingDatewiseReport: false,
  fetchingDatewiseReportError: false,
  dateOrderReport: [],
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ORDER_VIEW_TYPE:
      return { ...state, viewType: action.payload };

    // case types.ADD_ORDER_DATEWISE_REQUEST:
    //   return {
    //     ...state,
    //     addingDatewiseorder: true,
    //     addingDatewiseorderError: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_SUCCESS:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: false,
    //     datewiseOrder: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_FAILURE:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: true,
    //     datewiseOrder: false,
    //   };

    case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
      return {
        ...state,
        dateRangeList: newDateRange(state.dateRangeList, action.payload),
        isCustomSelected: false,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        type: action.payload.type
      };

    case types.SET_TIME_INTERVAL_REPORT:
      return {
        ...state,
        isCustomSelected: true,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.GET_ORDER_LIST_BY_ORDER_ID_REQUEST:
      return { ...state, fetchingOrderListByOrderId: true };
    case types.GET_ORDER_LIST_BY_ORDER_ID_SUCCESS:
      return {
        ...state,
        fetchingOrderListByOrderId: false,
        orderListByOrderId: action.payload,
      };
    case types.GET_ORDER_LIST_BY_ORDER_ID_FAILURE:
      return {
        ...state,
        fetchingOrderListByOrderId: false,
        fetchingOrderListByOrderIdError: true,
      };

    case types.SET_SELECTED_REPORT_TYPE:
      return {
        ...state,
        selectedReportType: action.payload,
        // selectedSubReportType: "order",
      };

    case types.GET_DATE_WISE_REPORT_REQUEST:
      return { ...state, fetchingDatewiseReport: true };
    case types.GET_DATE_WISE_REPORT_SUCCESS:
      return {
        ...state,
        fetchingDatewiseReport: false,
        fetchingDatewiseReportError: false,
        orderListByOrderId: action.payload,
      };
    case types.GET_DATE_WISE_REPORT_FAILURE:
      return {
        ...state,
        fetchingDatewiseReport: false,
        fetchingDatewiseReportError: true,
      };

    default:
      return state;
  }
};

const newDateRange = (dateRange, newDate) =>
  dateRange.map((range) => {
    //console.log(newDate);
    if (range.id === newDate.id) {
      return { ...range, isSelected: true };
    } else {
      return { ...range, isSelected: false };
    }
  });
