import React from "react";
import CartMainContentHeader from "../Header/CartMainContentHeader";
import MainHeader from "../Header/MainHeader";
import CartMainContent from "./Child/CartMainContent";
import FWLogo from "../../../Assets/Images/logo-shopper.PNG";


function Cart() {
  return (
    <>
     <div className="showInMobileLogo">
          <img src={FWLogo} alt="img" className="shopprLogoPng" />
        </div>
      <MainHeader />
      <div style={{ backgroundColor: "#F6F7FB", height: "100vh" }}>
        <CartMainContentHeader />
        <CartMainContent />
      </div>
    </>
  );
}

export default Cart;
