import * as types from "./DashboardActionType";
import moment from "moment";

const initialState = {
  viewType: "dashboard",

  //     //add datewise
  //   addingDatewiseorder: false,
  //   addingDatewiseorderError: false,
  //   datewiseOrder: [],

  // isCustomSelected: false,
  // startDate: moment().toISOString(),
  // endDate: moment().toISOString(),

  fetchingDashBoardId: false,
  fetchingDashBoardIdError: false,
  dashBoardId: [],

  fetchingLatestSaleTable: false,
  fetchingLatestSaleTableError: false,
  latestSaleTable: [],

  fetchingTop10Shop: false,
  fetchingTop10ShopError: false,
  top10Shop: [],

  fetchingTopSellingProducts: false,
  fetchingTopSellingProductsError: false,
  topSellingProducts: [],

  fetchingPendingOrders: false,
  fetchingPendingOrdersError: false,
  pendingTab: [],

  fetchingAcceptedData: false,
  fetchingAcceptedDataError: false,
  acceptedTable: [],

  fetchingPendedData: false,
  fetchingPendedDataError: false,
  pendedTable: [],

  fetchingShippedData: false,
  fetchingShippedDataError: false,
  shippedTable: [],

  fetchingJumpstartBoxData: false,
  fetchingJumpstartBoxDataError:false,
  jumpstartboxData:[],

  fetchingJumpstartBoxDatabyMerchantId: false,
  fetchingJumpstartBoxDatabyMerchantIdError: false,
  jumpstartboxMerchantData:[],
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    // case types.ADD_ORDER_DATEWISE_REQUEST:
    //   return {
    //     ...state,
    //     addingDatewiseorder: true,
    //     addingDatewiseorderError: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_SUCCESS:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: false,
    //     datewiseOrder: false,
    //   };
    // case types.ADD_ORDER_DATEWISE_FAILURE:
    //   return {
    //     ...state,
    //     addingDatewiseorder: false,
    //     addingDatewiseorderError: true,
    //     datewiseOrder: false,
    //   };

    // case types.CHANGE_SELECTED_TIME_INTERVAL_REPORT:
    //   return {
    //     ...state,
    //     dateRangeList: newDateRange(state.dateRangeList, action.payload),
    //     isCustomSelected: false,
    //     startDate: action.payload.startDate,
    //     endDate: action.payload.endDate,
    //   };

    // case types.SET_TIME_INTERVAL_REPORT:
    //   return {
    //     ...state,
    //     isCustomSelected: true,
    //     startDate: action.payload.startDate,
    //     endDate: action.payload.endDate,
    //   };

    case types.GET_DASH_BOARD_ID__REQUEST:
      return { ...state, fetchingDashBoardId: true };
    case types.GET_DASH_BOARD_ID__SUCCESS:
      return {
        ...state,
        fetchingDashBoardId: false,
        DashBoardId: action.payload,
      };
    case types.GET_DASH_BOARD_ID__FAILURE:
      return {
        ...state,
        fetchingDashBoardId: false,
        fetchingDashBoardIdError: true,
      };
    //latestSalestable
    case types.GET_LATEST_SALE_TABLE_REQUEST:
      return { ...state, fetchingLatestSaleTable: true };
    case types.GET_LATEST_SALE_TABLE_SUCCESS:
      return {
        ...state,
        fetchingLatestSaleTable: false,
        latestSaleTable: action.payload,
      };
    case types.GET_LATEST_SALE_TABLE_FAILURE:
      return {
        ...state,
        fetchingLatestSaleTable: false,
        fetchingLatestSaleTableError: true,
      };

    case types.GET_TOP_10_SHOP_REQUEST:
      return { ...state, fetchingTop10Shop: true };
    case types.GET_TOP_10_SHOP_SUCCESS:
      return {
        ...state,
        fetchingTop10Shop: false,
        Top19Shop: action.payload,
      };
    case types.GET_TOP_10_SHOP_FAILURE:
      return {
        ...state,
        fetchingTop10Shop: false,
        fetchingTop10ShopError: true,
      };

    case types.GET_TOP_SELLING_PRODUCT_REQUEST:
      return { ...state, fetchingTopSellingProducts: true };
    case types.GET_TOP_SELLING_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchingTopSellingProducts: false,
        Top19Shop: action.payload,
      };
    case types.GET_TOP_SELLING_PRODUCT_FAILURE:
      return {
        ...state,
        fetchingTopSellingProducts: false,
        fetchingTopSellingProductsError: true,
      };

    case types.GET_PENDING_ORDERS_REQUEST:
      return { ...state, fetchingPendingOrders: true };
    case types.GET_PENDING_ORDERS_SUCCESS:
      return {
        ...state,
        fetchingPendingOrders: false,
        pendingTab: action.payload,
      };
    case types.GET_PENDING_ORDERS_FAILURE:
      return {
        ...state,
        fetchingPendingOrders: false,
        fetchingPendingOrdersError: true,
      };

    case types.GET_ACCEPTED_DATA_REQUEST:
      return { ...state, fetchingAcceptedData: true };
    case types.GET_ACCEPTED_DATA_SUCCESS:
      return {
        ...state,
        fetchingAcceptedData: false,
        acceptedTable: action.payload,
      };
    case types.GET_ACCEPTED_DATA_FAILURE:
      return {
        ...state,
        fetchingAcceptedData: false,
        fetchingAcceptedDataError: true,
      };

    case types.GET_PENDED_DATA_REQUEST:
      return { ...state, fetchingPendedData: true };
    case types.GET_PENDED_DATA_SUCCESS:
      return {
        ...state,
        fetchingPendedData: false,
        pendedTable: action.payload,
      };
    case types.GET_PENDED_DATA_FAILURE:
      return {
        ...state,
        fetchingPendedData: false,
        fetchingPendedDataError: true,
      };

    case types.GET_SHIPPED_DATA_REQUEST:
      return { ...state, fetchingShippedData: true };
    case types.GET_SHIPPED_DATA_SUCCESS:
      return {
        ...state,
        fetchingShippedData: false,
        shippedTable: action.payload,
      };
    case types.GET_SHIPPED_DATA_FAILURE:
      return {
        ...state,
        fetchingShippedData: false,
        fetchingShippedDataError: true,
      };


      case types.GET_CANCELLED_DATA_REQUEST:
        return { ...state, fetchingCancelledData: true };
      case types.GET_CANCELLED_DATA_SUCCESS:
        return {
          ...state,
          fetchingCancelledData: false,
          cancelledTable: action.payload,
        };
      case types.GET_CANCELLED_DATA_FAILURE:
        return {
          ...state,
          fetchingCancelledData: false,
          fetchingCancelledDataError: true,
        };
  
        case types.GET_JUMPSTARTBOX_DATA_REQUEST:
          return { ...state, fetchingJumpstartBoxData: true };
        case types.GET_JUMPSTARTBOX_DATA_SUCCESS:
          return {
            ...state,
            fetchingJumpstartBoxData: false,
            jumpstartboxData: action.payload,
          };
        case types.GET_JUMPSTARTBOX_DATA_FAILURE:
          return {
            ...state,
            fetchingJumpstartBoxData: false,
            fetchingJumpstartBoxDataError: true,
          };
         
          case types.GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_REQUEST:
            return { ...state, fetchingJumpstartBoxDatabyMerchantId: true };
          case types.GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_SUCCESS:
            return {
              ...state,
              fetchingJumpstartBoxDatabyMerchantId: false,
              jumpstartboxMerchantData: action.payload,
            };
          case types.GET_JUMPSTARTBOX_DATA_BY_MERCHANT_ID_FAILURE:
            return {
              ...state,
              fetchingJumpstartBoxDatabyMerchantId: false,
              fetchingJumpstartBoxDatabyMerchantIdError: true,
            };

    default:
      return state;
  }
};

// const newDateRange = (dateRange, newDate) =>
//   dateRange.map((range) => {
//     
//     if (range.id === newDate.id) {
//       return { ...range, isSelected: true };
//     } else {
//       return { ...range, isSelected: false };
//     }
//   });