export const ADD_ORDER_DATEWISE_REQUEST = "ADD_ORDER_DATEWISE_REQUEST";
export const ADD_ORDER_DATEWISE_SUCCESS = "ADD_ORDER_DATEWISE_SUCCESS";
export const ADD_ORDER_DATEWISE_FAILURE = "ADD_ORDER_DATEWISE_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const GET_SHOP_CARD_REQUEST = "GET_SHOP_CARD_REQUEST";
export const GET_SHOP_CARD_SUCCESS = "GET_SHOP_CARD_SUCCESS";
export const GET_SHOP_CARD_FAILURE = "GET_SHOP_CARD_FAILURE";

export const SET_SHOP_VIEW_TYPE = "SET_SHOP_VIEW_TYPE";

export const GET_SHOP_LIST_REQUEST = "GET_SHOP_LIST_REQUEST";
export const GET_SHOP_LIST_SUCCESS = "GET_SHOP_LIST_SUCCESS";
export const GET_SHOP_LIST_FAILURE = "GET_SHOP_LIST_FAILURE";

export const GET_MERCHANT_PRODUCT_REQUEST = "GET_MERCHANT_PRODUCT_REQUEST";
export const GET_MERCHANT_PRODUCT_SUCCESS = "GET_MERCHANT_PRODUCT_SUCCESS";
export const GET_MERCHANT_PRODUCT_FAILURE = "GET_MERCHANT_PRODUCT_FAILURE";

export const PUT_IN_APPROVAL_SHOP_REQUEST = "PUT_IN_APPROVAL_SHOP_REQUEST";
export const PUT_IN_APPROVAL_SHOP_SUCCESS = "PUT_IN_APPROVAL_SHOP_SUCCESS";
export const PUT_IN_APPROVAL_SHOP_FAILURE = "PUT_IN_APPROVAL_SHOP_FAILURE";

export const HANDLE_SHOP_ACCOUNT_MODAL = "HANDLE_SHOP_ACCOUNT_MODAL";
export const HANDLE_SHOP_HISTORY_MODAL="HANDLE_SHOP_HISTORY_MODAL";