import React, { Component } from "react";
import {
  FlexContainer,
  MainWrapper,
} from "../../../../../Components/UI/Layout";
import PaymentLeftContent from "./PaymentLeftContent";
import PaymentRightContent from "./PaymentRightContent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PaymentCenterContent from "./PaymentCenterContent";
import { Tooltip, Input, Button } from "antd";
import "./Payment.scss";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { RollbackOutlined } from "@ant-design/icons";
import { createBrowserHistory } from "history";
import { getDeliveryInfo } from "../../../CustomerAction";
const history = createBrowserHistory();
class PaymentMainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  handleClickEdit = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };
  handleCancl = () => {
    this.setState({
      visible: false,
    });
  };

  handleCallback = (data) => {
    if (data === "success") {
      this.setState({
        visible: false,
      });
    }
    const value = localStorage.getItem("cartId");
    const final = JSON.parse(value);
    this.props.getDeliveryInfo(final.cartId);
  };
  componentDidMount() {
    const value = localStorage.getItem("cartId");
    const final = JSON.parse(value);
    this.props.getDeliveryInfo(final.cartId);
  }
  render() {
    return (
      <>
        {/* <div style={{ fontWeight: 500, fontSize: "  1.875em",marginLeft: "1em" }}>Choose Payment</div> */}
        <FlexContainer style={{ justifyContent: "center" }}>
          <h1 class="underline max-sm:text-xl md:text-3xl font-normal">
            Checkout
          </h1>
        </FlexContainer>

        <div className="box w-wk">
          <div class="flex items-center flex-col ">
          <MainWrapper
            background="#FFFFFF"
            boxShadow="0.25em 0.25em 0.25em rgba(163 171, 185, 0.5)"
            borderRadius="1.25em"
            width="60%"
            border="none"
          >
            <PaymentLeftContent />
          </MainWrapper>
          <div className="box2 mt-4">
          <MainWrapper
            background="#FFFFFF"
            boxShadow="0.25em 0.25em 0.25em rgba(163, 171, 185, 0.5)"
            borderRadius="1.25em"
            width="60%"
            marginLeft="1.875em"
            border="none"
            // style={{ marginRight: "23%" }}
          >
            <div class="flex justify-between w-full">
            <h2>Shipping Address</h2>
            <p
                  onClick={this.handleClickEdit}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                 <Tooltip title="edit">
                  <BorderColorIcon style={{fontSize:"1rem"}}/>
                  </Tooltip>
                </p>
                </div>
            {this.state.visible ? (
              <PaymentCenterContent
                handleCallback={this.handleCallback}
                handleCancl={this.handleCancl}
                data={
                  this.props.showDeliveryInfo &&
                  this.props.showDeliveryInfo.storeCart &&
                  this.props.showDeliveryInfo.storeCart.shippingAddress
                }
              />
            ) : (
              <>
                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .firstName}{" "}
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .lastName}
                </p>
                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .dialCode}{" "}
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .phoneNo}
                </p>
                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .emailId}
                </p>
                
                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .address1}
                 </p>
                

                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress.city}
                </p>
                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .countryName}
                </p>
                <p>
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress.state}
                  &nbsp;
                  {this.props.showDeliveryInfo &&
                    this.props.showDeliveryInfo.storeCart &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress &&
                    this.props.showDeliveryInfo.storeCart.shippingAddress
                      .pinCode}
                </p>

                
              </>
            )}
          </MainWrapper>
        </div>
        </div>
          <MainWrapper
            background="#FFFFFF"
            boxShadow="0.25em 0.25em 0.25em rgba(163, 171, 185, 0.5)"
            borderRadius="1.25em"
            width="30%"
            marginLeft="1.875em"
            border="none"
            style={{height:"max-content"}}
          >
            <PaymentRightContent />
          </MainWrapper>
       
       
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ customer, auth }) => ({
  showDeliveryInfo: customer.showDeliveryInfo,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDeliveryInfo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMainContent);
