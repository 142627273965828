import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FlexContainer } from "../../Components/UI/Layout";
import { Button } from "antd";
import "./OrderTemplate.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class OrderSuccessfull extends React.Component {

  render() {

    const shopName = this.props.shopName.name;
    const str = shopName && shopName.replace(/ +/g, "");

    if (this.props.placedOrderData === null) {
      return <p>....Loading</p>;
    }
    return (
      <div className="oderContainer">
        <div className="box center">
          <CheckCircleFilled
            style={{ fontSize: "6.6875em", color: "#3066BE" }}
          />
          <div class="mt-2 items-center flex max-sm:flex-col md:flex-col">
          <h1>
            Your Order is Successfull <br/> 
            {this.props.placedOrderData.storeCart.orderNumber || ""}
          </h1>

          <h1 class="text-center">
            For More Details click on "Track Order" to check order status.
          </h1>
          </div>
          <FlexContainer justifyContent="center" style={{ width: "100%" }}>
            <div className="btnTrck">
              <Link to={`/${str}/track`}>
                <Button
                  className="btnTrack">
                  Track Order
                </Button>
              </Link>
            </div>
            &nbsp;&nbsp;
            <div>
              <Link to="/">
                <Button className="btnShping" >
                  Continue Shopping
                </Button>
              </Link>
            </div>
          </FlexContainer>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      //  addPlaceOrder
    },
    dispatch
  );
const mapStateToProps = ({ customer, auth }) => ({
  // customer: customer.customer,
  placedOrderData: customer.placedOrderData,
  placeOrder: customer.placeOrder,
  shopName: customer.shopName,
  // linkingProductInfo: customer.linkingProductInfo,
  // productInfo:customer.productInfo,
  //  cartId:customer.productInfo.cartId,
  // cartId: customer.dispatch.length && customer.dispatch[0].cartId || "",

  // userId: auth.userDetails.userId,
  // productId:products.products.productId
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSuccessfull);
