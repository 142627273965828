import { Button } from 'antd';
import React from 'react';
import { connect } from "react-redux";
import MainHeader from "../../../Header/MainHeader";
import { bindActionCreators } from "redux";
import { MultiAvatar } from "../../../../../Components/UI/Elements";
// import {cancelOrder} from "../../../CustomerAction"
import Orange from "../../../../../Assets/Images/orange.jpg";
import { Link } from "react-router-dom";
import {withRouter} from "react-router";
import "../../../Customer.scss";
import { FlexContainer, MainWrapper, Spacer } from '../../../../../Components/UI/Elements';
import styled from 'styled-components';
import { CurrencySymbol } from '../../../../../Components/Common';


class OrderMainContentDetails extends React.Component {
    state = {
       
        trackcurrentOrderId:"",
        
      };
    

    componentDidMount() {
        this.setState({trackcurrentOrderId:this.props.match.params.orderId});

       }
      
    render() {
      // const cartData=this.props.cart.storeCart;
      // const cartSummaryData= cartData && cartData.cartSummary
      // const finalSubTotalValue=cartSummaryData && cartSummaryData.subTotal;
      // const finalgrandTotalValue=cartSummaryData && cartSummaryData.grandTotal;
      // const finalshippingValue=cartSummaryData && cartSummaryData.shippingCharge;
      
        // const cartData=this.props.cart.storeCart;
        // const cartSummaryData= cartData && cartData.cartSummary
        // const finalSubTotalValue=cartSummaryData && cartSummaryData.subTotal;
        // const finalgrandTotalValue=cartSummaryData && cartSummaryData.grandTotal;
       
        // {this.props.trackingOrderData.map((item) => {
        return (
            <>
            <MainHeader />
             {/* {this.props.trackingOrderData.map((item) => { */}
            {/* <MainWrapper style={{width: "49%",
    marginLeft: "338px",
    border: "2px solid red",
    marginTop: "20px"}}> */}
     <FlexContainer justifyContent="center">
       <MainWrapper style={{
                        background:"#FFFFFF",
                        boxShadow:"4px 4px 4px rgba(163, 171, 185, 0.5)",
                        borderRadius: "20px",
                        width: "65%",
                        border:"none",
                        marginLeft:"0.5%"
       }}
                    >
                <FlexContainer >
                
                    <Spacer marginTop="10px"/>
                    {/* <span >
                        <i class="fas fa-clipboard-list"></i>
                    </span> */}
                    &nbsp;
                    <div>
                     <h2>Order  {this.props.match.params.orderId}</h2> 
                     <h4>Your order status is <b>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].status}</b></h4> 

                     {this.props.trackingOrderData.length && this.props.trackingOrderData[0].status !== "Shipped"&& this.props.trackingOrderData.length && this.props.trackingOrderData[0].status !== "Cancelled"? ( 
                        <Link to={`/shopName/ordercancellationmaincontent/${this.props.trackingOrderData.length && this.props.trackingOrderData[0].orderId}`}>
                        <Button className="advBtn"
                        >
                        <label class="text-white font-bold">Cancel Order</label>
                        </Button>
                        </Link>
                    ): null}
                    </div>
                  
                </FlexContainer>
             {/* })} */}
            
                 <hr /> 
                {/* <div>
                    <br />
                    <i class="fas fa-truck"></i>
                    &nbsp;&nbsp;
                  
                </div> */}
                 {/* <hr />
                 <div>
                     &nbsp;&nbsp;
                     <br />
                     <i class="fas fa-sticky-note"></i>
                     &nbsp;&nbsp;
                   
                </div>
                 <hr /> */}
                <br />
                {/* <div>2 Items</div> */}
                <br />
                {/* <FlexContainer>
                    <img
                        src={Orange}
                        style={{ width: "80px", height: "80px", borderRadius: "20px" }}
                    />
                    <span style={{ marginLeft: "40px" }}>Orange</span>
                </FlexContainer> */}
                {/* <br /> */}
                <FlexContainer>
                <CardWrapper>
                  {this.props.trackingOrderData.map((item) => { 
                      return(
                
               
                
                <CardElement>
                    <div className=" p-4  h-max w-56 flex-shrink-0 scale-90 hover:scale-100 ease-in  duration-500 hover:shadow-lg overflow-hidden rounded-md border border-gray-200 object-cover object-center  ">
                 <CardImage>
                                {item.imageId ? (
                                  <MultiAvatar
                                    imageId={item.imageId ? item.imageId : ""}
                                    // imgHeight={200}
                                    // imgWidth={200}
                                    // imgRadius={20}
                                  />
                                ) : (
                                  <WithOutImage>
                                    <div className="WhtImg">
                                      {" "}
                                      Image Not Available
                                    </div>
                                  </WithOutImage>
                                )}
                              </CardImage>
                              <CardDescription>    
                      <Header>{item.name}</Header>  
                      <Price>{item.quantity}</Price> 
                    {/* <SubHeader>200-250gm </SubHeader> */}
                    <div style={{display:"flex", placeContent:"space-between", width:"-webkit-fill-available"}}>
                    <Price2><CurrencySymbol currencyType={item.currencyName}/> {item.price}</Price2> 
                    <Price>
                    <CurrencySymbol currencyType={item.currencyName}/> {item.discountedPrice}
                              </Price>
                    </div>
                    <div style={{display:"flex", placeContent:"space-between",width:"-webkit-fill-available"}}>
                     <ColSiz>Color-{item.productColour}</ColSiz> 
                      <ColSiz>Size-{item.productSize}</ColSiz>
                    </div>
                    </CardDescription> 
                    </div>
                </CardElement>
                   )
                })}
                </CardWrapper>
                </FlexContainer>
                <hr />
                <p>Item Total<span style={{float:"right"}}><CurrencySymbol currencyType={this.props.trackingOrderData.length && this.props.trackingOrderData[0].currencyName}/> {this.props.trackingOrderData.length && this.props.trackingOrderData[0].total} </span></p>
               
                <Spacer marginTop= "1%" />
                <p> Delivery<span style={{float:"right"}}><CurrencySymbol currencyType={this.props.trackingOrderData.length && this.props.trackingOrderData[0].currencyName}/> {this.props.trackingOrderData.length && this.props.trackingOrderData[0].shippingCharges}</span></p>
                <Spacer marginTop= "1%"  />
                <p> Grand Total<span style={{float:"right"}}><CurrencySymbol currencyType={this.props.trackingOrderData.length && this.props.trackingOrderData[0].currencyName}/> {this.props.trackingOrderData.length && this.props.trackingOrderData[0].grandTotal}</span></p>
                <hr />
                <Spacer  marginTop="1%"  />
              
                <h2>Your Details</h2>
                <Spacer/>
               
                    <h4>Name<span style={{marginLeft:"39px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].fullName}</span></h4>
                     <Spacer/>
                    <h4>Mobile <span style={{marginLeft:"34px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].phNO}</span></h4> 
                     <Spacer/>
                     <h4>E-mail<span style={{marginLeft:"38px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].email}</span></h4>
                     <Spacer/>
                    <h4>Address<span style={{marginLeft:"28px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].address1}</span></h4>
                     <Spacer/>
                    <h4>City<span style={{marginLeft:"52px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].city}</span></h4>
                     <Spacer/>
                     <h4>Country<span style={{marginLeft:"30px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].countryName}</span></h4>
                     <Spacer/>
                     <h4>Zip Code<span style={{marginLeft:"26px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].pinCode}</span></h4>
                     <Spacer/>
                    <h4>Payment<span style={{marginLeft:"24px"}}>{this.props.trackingOrderData.length && this.props.trackingOrderData[0].mode}</span></h4>
                   
           </MainWrapper>  
           </FlexContainer>   
            </>
       
           
        );
        
        // })}
    }

}
const mapStateToProps = ({ customer }) => ({
    trackingOrderData: customer.trackingOrderData,
    cart: customer.cart,
    shopName:customer.shopName,
  });
  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        // getShopName
      },
      dispatch
    );


export default connect(mapStateToProps,mapDispatchToProps)(OrderMainContentDetails);

const CardWrapper = styled.div`
 display:flex;
 flex-wrap:wrap;
 width:100%
@media only screen and (max-width: 600px) {
    justify-content:center;
    flex-direction:column;
    align-content: center;
}

`
const CardElement = styled.div`
 width:20%;
 padding:0 20px;
 margin: 0% 3% 4% 0%;
@media only screen and (max-width: 600px) {
  width:100%;
}

`

const Header = styled.div`
 text-overflow: ellipsis;
text-align:center;
    white-space: nowrap;
    overflow: hidden;
    height:2em;
    @media only screen and (max-width: 600px) {
      text-overflow: ellipsis;
      text-align:center;
          white-space: nowrap;
          overflow: hidden;
          height:2em;
          width: -webkit-fill-available;
    }
`

const SubHeader = styled.div`

 height:2em;
`
const Price = styled.div`
 
  height:2em;
  font-weight:700;
  font-family:Poppins;
  font-size:1em;
`
const Price2 = styled.div`
 
  height:2em;
  font-weight:700;
  font-family:Poppins;
  font-size:1em;
  text-decoration-line: line-through;
`
const ColSiz = styled.div`
 
  height:2em;
  font-weight:700;
  font-family:Poppins;
  font-size:1em;
`
const CardImage = styled.div`
  width: 10em;
  height: 10em;
  margin: auto;
  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
const WithOutImage = styled.div`
  width: 10em;
  height: 10em;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction:column @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;
const CardDescription = styled.div`
  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;