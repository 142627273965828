import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { FlexContainer } from "../../Components/UI/Layout";
import { Button } from "antd";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import "./OrderTemplate.scss";

class OrderCancelTemplate extends React.Component  {
  render() {

    const shopName= this.props.shopName.name
    const str = shopName&&shopName.replace(/ +/g, "");

    return (
    <div className="oderContainer">
      <div className="box center">
        <CheckCircleFilled style={{ fontSize: "6.6875em", color: " #3066BE" }} />
        <h1>Cancellation Confirmed</h1>
        <p>
          Your &nbsp; {this.props.cancelOrder.orderId} has been Successfully
          Cancelled.
        </p>
        <FlexContainer justifyContent="center" style={{ width: "100%" }}>
       
          <Link to="/">
            <Button 
             className="btnShping"
            type="primary">Continue Shopping</Button>
            </Link>
         </FlexContainer>
      </div>
    </div>
  );
}
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
      {
        //  addPlaceOrder
      },
      dispatch
  );

  const mapStateToProps = ({ customer, auth }) => ({
    // customer: customer.customer,
  
    shopName:customer.shopName,
    cancelOrder:customer.cancelOrder
   
  });



export default connect(mapStateToProps, mapDispatchToProps)(OrderCancelTemplate);
