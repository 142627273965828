export const ADD_ORDER_DATEWISE_REQUEST = "ADD_ORDER_DATEWISE_REQUEST";
export const ADD_ORDER_DATEWISE_SUCCESS = "ADD_ORDER_DATEWISE_SUCCESS";
export const ADD_ORDER_DATEWISE_FAILURE = "ADD_ORDER_DATEWISE_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const GET_TEST_SHOP_REQUEST="GET_TEST_SHOP_REQUEST";
export const GET_TEST_SHOP_SUCCESS="GET_TEST_SHOP_SUCCESS";
export const GET_TEST_SHOP_FAILURE="GET_TEST_SHOP_FAILURE";

export const GET_PENDING_LIST_REQUEST="GET_PENDING_LIST_REQUEST";
export const GET_PENDING_LIST_SUCCESS="GET_PENDING_LIST_SUCCESS";
export const GET_PENDING_LIST_FAILURE="GET_PENDING_LIST_FAILURE";

export const GET_ACCEPTED_LIST_REQUEST="GET_ACCEPTED_LIST_REQUEST";
export const GET_ACCEPTED_LIST_SUCCESS="GET_ACCEPTED_LIST_SUCCESS";
export const GET_ACCEPTED_LIST_FAILURE="GET_ACCEPTED_LIST_FAILURE";

export const GET_SHIPPED_LIST_REQUEST="GET_SHIPPED_LIST_REQUEST";
export const GET_SHIPPED_LIST_SUCCESS="GET_SHIPPED_LIST_SUCCESS";
export const GET_SHIPPED_LIST_FAILURE="GET_SHIPPED_LIST_FAILURE";

export const GET_CANCELLED_LIST_REQUEST="GET_CANCELLED_LIST_REQUEST";
export const GET_CANCELLED_LIST_SUCCESS="GET_CANCELLED_LIST_SUCCESS";
export const GET_CANCELLED_LIST_FAILURE="GET_CANCELLED_LIST_FAILURE";

export const PUT_OPEN_IND_REQUEST="PUT_OPEN_IND_REQUEST";
export const PUT_OPEN_IND_SUCCESS="PUT_OPEN_IND_SUCCESS";
export const PUT_OPEN_IND_FAILURE="PUT_OPEN_IND_FAILURE";

export const GET_ALLPRODUCT_CATEGORY_REQUEST="GET_ALLPRODUCT_CATEGORY_REQUEST";
export const GET_ALLPRODUCT_CATEGORY_SUCCESS="GET_ALLPRODUCT_CATEGORY_SUCCESS";
export const GET_ALLPRODUCT_CATEGORY_FAILURE="GET_ALLPRODUCT_CATEGORY_FAILURE";

export const GET_PRODUCT_BY_CATEGORY_ID_REQUEST="GET_PRODUCT_BY_CATEGORY_ID_REQUEST";
export const GET_PRODUCT_BY_CATEGORY_ID_SUCCESS="GET_PRODUCT_BY_CATEGORY_ID_SUCCESS";
export const GET_PRODUCT_BY_CATEGORY_ID_FAILURE="GET_PRODUCT_BY_CATEGORY_ID_FAILURE";

export const HOME_ALL_PRODUCT_SEARCH_REQUEST="HOME_ALL_PRODUCT_SEARCH_REQUEST";
export const HOME_ALL_PRODUCT_SEARCH_SUCCESS="HOME_ALL_PRODUCT_SEARCH_SUCCESS";
export const HOME_ALL_PRODUCT_SEARCH_FAILURE="HOME_ALL_PRODUCT_SEARCH_FAILURE";

export const SIGN_UP_NEWS_AND_OFFER_REQUEST="SIGN_UP_NEWS_AND_OFFER_REQUEST";
export const SIGN_UP_NEWS_AND_OFFER_SUCCESS="SIGN_UP_NEWS_AND_OFFER_SUCCESS";
export const SIGN_UP_NEWS_AND_OFFER_FAILURE="SIGN_UP_NEWS_AND_OFFER_FAILURE";

export const SEARCH_CATEGORY_PRODUCTS_DETAILS_REQUEST="SEARCH_CATEGORY_PRODUCTS_DETAILS_REQUEST";
export const SEARCH_CATEGORY_PRODUCTS_DETAILS_SUCCESS="SEARCH_CATEGORY_PRODUCTS_DETAILS_SUCCESS";
export const SEARCH_CATEGORY_PRODUCTS_DETAILS_FAILURE="SEARCH_CATEGORY_PRODUCTS_DETAILS_FAILURE";

export const ADD_CONTACT_DETAILS_REQUEST = "ADD_CONTACT_DETAILS_REQUEST";
export const ADD_CONTACT_DETAILS_SUCCESS = "ADD_CONTACT_DETAILS_SUCCESS";
export const ADD_CONTACT_DETAILS_FAILURE = "ADD_CONTACT_DETAILS_FAILURE";

export const GET_MERCHANT_HISTORY_REQUEST="GET_MERCHANT_HISTORY_REQUEST";
export const GET_MERCHANT_HISTORY_SUCCESS="GET_MERCHANT_HISTORY_SUCCESS";
export const GET_MERCHANT_HISTORY_FAILURE="GET_MERCHANT_HISTORY_FAILURE";

export const HANDLE_AVAILABILITY_MODAL="HANDLE_AVAILABILITY_MODAL";
export const HANDLE_CALL_BACK_MODAL="HANDLE_CALL_BACK_MODAL";

export const GET_HOMEPAGE_REQUEST="GET_HOMEPAGE_REQUEST";
export const GET_HOMEPAGE_SUCCESS="GET_HOMEPAGE_SUCCESS";
export const GET_HOMEPAGE_FAILURE="GET_HOMEPAGE_FAILURE";
export const UPDATE_HOMEPAGE_REQUEST="UPDATE_HOMEPAGE_REQUEST";
export const UPDATE_HOMEPAGE_SUCCESS="UPDATE_HOMEPAGE_SUCCESS";
export const UPDATE_HOMEPAGE_FAILURE="UPDATE_HOMEPAGE_FAILURE";

