import React from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import MainHeader from "../../../Header/MainHeader";
import CustomerContentHeaderActionLeft from "../../../Header/CustomerContentHeaderActionLeft";
import { Radio, Input, Space, Button } from "antd";
import {
  FlexContainer,
  MainWrapper,
} from "../../../../../Components/UI/Elements";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {getCancelOrder} from "../../../CustomerAction";
import "../../../Customer.scss";
class CancellationMainContentForm extends React.Component {
  state = {
    value: "",
    currentOrderId:"",
    trackingOrderData:[],
    // deleteInd:false,
  };

  componentDidMount() {
   this.setState({currentOrderId:this.props.match.params.orderId});
    
  }

  handleCancelOrder = () => {
  
    this.props.getCancelOrder(this.state.currentOrderId,{orderId:this.state.currentOrderId,reason:this.state.value,});
  }
    
  onChange = (e) => {
  
    this.setState({
      value: e.target.value,
      checked: !this.state.checked,
    });
  };

  render() {
    const cartData=this.props.cart.storeCart;
    const cartSummaryData= cartData && cartData.cartSummary
    const finalSubTotalValue=cartSummaryData && cartSummaryData.subTotal;
    const finalgrandTotalValue=cartSummaryData && cartSummaryData.grandTotal;
    const { value } = this.state;
    return (
      <>
        <MainHeader />
        {/* <CustomerContentHeaderActionLeft /> */}
        <MainWrapper
          
            background= "#FFFFFF"
            boxShadow= "4px 4px 4px rgba(163, 171, 185, 0.5)"
            borderRadius= "20px"
            width= "70%"
            marginLeft= "173px"
            border= "none"
            marginTop= "33px"
        
        >
          <FlexContainer>
            <h1>
              Grand Total:
            
             
            </h1>

            {/* <FlexContainer justifyContent="flex-end"> */}
              <div>
                <h1> {this.props.trackingOrderData.length && this.props.trackingOrderData[0].grandTotal} {this.props.shopName.currencyName}</h1>

            
                
              </div>
            </FlexContainer>
          {/* </FlexContainer> */}
          <hr />
          <br />
          <h1 style={{ color: " rgba(0, 0, 0, 0.5)" }}>
            {" "}
            Reason for cancellation
          </h1>
          <Radio.Group onChange={this.onChange} value={value}>
            <Space direction="vertical">
              <Radio value={"I order it by mistake"}>I order it by mistake</Radio>
              <Radio value={"I want to change my delivery address"}>I want to change my delivery address</Radio>
              <Radio value={"Price for the product has decreased"}>Price for the product has decreased</Radio>
              <Radio value={"I have changed my mind"}>I have changed my mind</Radio>
              <Radio value={"Others"}>Others</Radio>

              <Link to="/shopName/ordercancelltemplate">
                <Button className="advBtn"
                // Loading={cancelingOrder}
                onClick={this.handleCancelOrder}
                disabled={!this.state.checked}
                >
                  
                  <label class="text-white font-bold">Cancel Order</label>
                  </Button>
              </Link>
            </Space>
          </Radio.Group>
          <br />
       
        </MainWrapper>
      </>
    );
  }
}
const mapStateToProps = ({ customer }) => ({
  fetchingCancelOrder:customer.fetchingCancelOrder,
  orderId: customer.trackingOrderData.orderId,
  trackingOrderData:customer.trackingOrderData,
  cart: customer.cart,
  shopName:customer.shopName,
  trackingOrderData: customer.trackingOrderData,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCancelOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CancellationMainContentForm);

