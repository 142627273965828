import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import { BundleLoader } from "./Components/Placeholder";
import PrivateRoute from "./Helpers/Auth/PrivateRoute";
import AppErrorBoundary from "./Helpers/ErrorBoundary/AppErrorBoundary";
import Cart from "./Containers/Customer/Cart/Cart";
import ProductsDetails from "./Containers/Products/ProductsDetails";
import OrderSuccessFullTemplate from "./Containers/Order/OrderSuccessFullTemplate";
import CancellationMainContentForm from "./Containers/Customer/Cart/Child/Cancellation/CancellationMainContentForm";
import OrderCancelTemplate from "./Containers/Order/OrderCancelTemplate";
import OrderMainContentDetails from "./Containers/Customer/Cart/Child/Order/OrderMainContentDetails";
import Payment from "./Containers/Customer/Cart/Child/Payment/Payment";
import Track from "./Containers/Customer/Cart/Child/Order/Track";
import TrackOrder from "./Containers/ShopprHome/TrackOrder";
import OrderTrackDetails from "./Containers/ShopprHome/OrderTrackDetails";
import StripeOrderSuccessFull from "./Containers/Order/StripeOrderSuccessFull";
import forgotPassword from "./Containers/Auth/forgotPassword";
import PaymentLoading from "./Containers/Customer/Cart/Child/Payment/PaymentLoading";
const Contact= lazy(() => import("./Containers/ShopprHome/Contact"))
const ShopperHome = lazy(()=> import ("./Containers/ShopprHome/ShopperHome"));
const MainApp = lazy(() => import("./Containers/Main/MainApp"));
const Login = lazy(() => import("./Containers/Auth/Login"));
const Signup = lazy(() => import("./Containers/Auth/SignUp"));
const CustomerMainApp = lazy(() => import("./Containers/Main/CustomerMainApp"));
const ShopprHomeProductsDetails = lazy(()=> import ("./Containers/ShopprHome/ShopprHomeProductsDetails"));
const ShopprHomeCustomerHelp=lazy(()=>import ("./Containers/ShopprHome/ShopprHomeCustomerHelp"));
const ShopprHomePrivacy=lazy(()=> import ("./Containers/ShopprHome/ShopprHomePrivacy"));
const TermsCondition=lazy(()=>import ("./Containers/Auth/TermsCondition"));
const FAQ=lazy(()=>import ("./Containers/ShopprHome/FAQ"));
const CustomerCart=lazy(()=>import("./Containers/Customer/CustomerCart"));
const SplashwithoutLogo=lazy(()=>import("./Containers/Main/SplashwithoutLogo"));
class App extends Component {
  render() {
    const { fetchingUserDetails } = this.props;
    return (
      <React.Fragment>
        <AppErrorBoundary>
          <Suspense fallback={<></>}>
            <Switch>
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/forgotPassword" component={forgotPassword} />
              <Route exact path="/:shopName/home" component={CustomerMainApp} />
              <Route exact path="/:shopName/cart" component={Cart} />
              <Route exact path="/:shopName/payment" component={Payment} />
              <Route exact path="/:shopName/track" component={Track} />
              <Route exact path="/:shopName/ordersucess" component={OrderSuccessFullTemplate} />
              <Route exact path="/:shopName/ordersucess/:orderId" component={StripeOrderSuccessFull} />
              <Route exact path="/:shopName/ordermaincontent/:orderId" component={OrderMainContentDetails} />
              <Route exact path="/:shopName/ordercancellationmaincontent/:orderId" component={CancellationMainContentForm} />
              <Route exact path="/:shopName/ordercancelltemplate" component={OrderCancelTemplate} />
              <Route exact path="/:shopName/productdetails" component={ProductsDetails} />
              <Route exact path="/:shopName/customercart/:cartId?" component={CustomerCart} />
              <Route exact path="/" component={ShopperHome} />
              <Route exact path="/trackOrder" component={TrackOrder} />
              <Route exact path="/:shopName?/orderTrackdetails/:orderId" component={OrderTrackDetails} />
              <Route exact path="/shopprHomeProductsDetails/:category" component={ShopprHomeProductsDetails} />
              <Route exact path="/customerHelp" component={ShopprHomeCustomerHelp} />
              <Route exact path="/privacy" component={ShopprHomePrivacy} />
              <Route exact path="/terms" component={TermsCondition}/>
              <Route exact path="/faq" component={FAQ}/>
              <Route exact path="/:shopName/loading/:stripePaymentId/:paymentId" component={PaymentLoading}/>
              {fetchingUserDetails ? (
                <BundleLoader />
              ) : (
                  <PrivateRoute path="/" component={MainApp} />
                )}
            </Switch>
          </Suspense>
        </AppErrorBoundary>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  fetchingUserDetails: auth.fetchingUserDetails,
});
export default connect(mapStateToProps)(App);
