export const ADD_ORDER_DATEWISE_REQUEST = "ADD_ORDER_DATEWISE_REQUEST";
export const ADD_ORDER_DATEWISE_SUCCESS = "ADD_ORDER_DATEWISE_SUCCESS";
export const ADD_ORDER_DATEWISE_FAILURE = "ADD_ORDER_DATEWISE_FAILURE";

export const CHANGE_SELECTED_TIME_INTERVAL_REPORT =
  "CHANGE_SELECTED_TIME_INTERVAL_REPORT";
export const SET_TIME_INTERVAL_REPORT = "SET_TIME_INTERVAL_REPORT";

export const ADD_PAYMENT_PLACE_ORDER_REQUEST="ADD_PAYMENT_PLACE_ORDER_REQUEST";
export const ADD_PAYMENT_PLACE_ORDER_SUCCESS="ADD_PAYMENT_PLACE_ORDER_SUCCESS";
export const ADD_PAYMENT_PLACE_ORDER_FAILURE="ADD_PAYMENT_PLACE_ORDER_FAILURE";

export const GET_PAY_COD_REQUEST="GET_PAY_COD_REQUEST";
export const GET_PAY_COD_SUCCESS="GET_PAY_COD_SUCCESS";
export const GET_PAY_COD_FAILURE="GET_PAY_COD_FAILURE";

export const POST_PAY_COD_REQUEST = "POST_PAY_COD_REQUEST";
export const POST_PAY_COD_SUCCESS = "POST_PAY_COD_SUCCESS";
export const POST_PAY_COD_FAILURE = "POST_PAY_COD_FAILURE";


