import React from 'react'
import PaymentMainContentHeader from '../../../Header/PaymentMainContentHeader'
import PaymentMainHeader from './PaymentMainHeader'
import PaymentMainContent from './PaymentMainContent'
import FWLogo from "../../../../../Assets/Images/logo-shopper.PNG";

function Payment() {
  return (
      <>
      <div className="showInMobileLogo">
          <img src={FWLogo} alt="img" className="shopprLogoPng" />
        </div>
    <PaymentMainHeader/>
          <div style={{backgroundColor: "#F6F7FB" , height: "100vh"}}>
          <PaymentMainContentHeader/>
          <PaymentMainContent/>
          </div>
          </>
  )
}

export default Payment